import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useMemo } from 'react';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
function Contrats(props) {
    const navigate = useNavigate();
    const [siteMembre, setSiteMembre] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const dt = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = siteMembre.slice(firstIndex, lastIndex);
    const npage = Math.ceil(siteMembre.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const usernameconnected=sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }

    const confirm2 = (id) => {
        confirmDialog({
            message: 'Voulez-vous supprimer ce site ?',
            header: 'Confirmation Suppression ',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => {
              
            },
            reject: () => {

            }
        });
    };
    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'index');
            if (response.data.status === 200) {
                setSiteMembre(response.data.data_membres_sites);

            }
        } catch (Error) {

        }
    };
    useEffect(() => {
        lister_contrat();
        if(usernameconnected===null || usernameconnected===""){
            navigate('/')
        }
    }, []);
    return (
        <div>
               <ConfirmDialog />
            <Toast ref={toast} />
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper ">
                    <div className='row mt'>
                        <div className='col col-md-12'>
                            <button className='btn btn-info' onClick={() => navigate('/addContrat')}>Ajouter</button>


                        </div>
                    </div>
                    <div class="row " style={{ padding: 10 }}>

                        <table ref={dt} class="table table-bordered table-striped table-condensed">
                            <thead>
                                <tr>
                                    <th><center>#</center></th>
                                    <th>NOM</th>
                                    <th>PRENOM</th>
                                    <th><center>SEXE</center></th>
                                    <th>EMAIL</th>
                                    <th>TELEPHONE</th>
                                    <th>PAYS</th>
                                    <th>VILLE</th>
                                    <th>SPECIALITE</th>
                                    <th>EXPERIENCE</th>
                                    <th colSpan={3}><center>ACTIONS</center></th>

                                </tr>
                            </thead>
                            <tbody>
                                {records
                                    .map((si, index) => (
                                        <tr key={si.id}>
                                            <td><center>{index + 1}</center></td>
                                            <td>{si.nom}</td>
                                            <td>{si.prenom}</td>
                                            <td><center>{si.sexe}</center></td>
                                            <td>{si.email}</td>
                                            <td>{si.telephone}</td>
                                            <td>{si.pays}</td>
                                            <td>{si.ville}</td>
                                            <td>{si.specialite}</td>
                                            <td>{si.experience}</td>
                                            <td><center><Button onClick={() => navigate(`/modifier_contrat/${si.id}`)} icon={"pi pi-user-edit"} style={{ backgroundColor: "orange" }} />
                                            </center></td>
                                            <td><center><Button onClick={() => confirm2(si.id)} icon="pi pi-trash" style={{ backgroundColor: "red" }} />
                                            </center></td>
                                            <td><center><Button onClick={() => confirm2(si.id)} icon="pi pi-download" style={{ backgroundColor: "#107385" }} />
                                            </center></td>
                                        </tr>
                                    ))}
                            </tbody>

                        </table>

                        <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={prevPage}
                                    >Prev</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href='#' className='page-link'
                                                onClick={() => changeCPage(n)}
                                            >{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href='#' className='page-link'
                                        onClick={nextPage}
                                    >Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default Contrats;