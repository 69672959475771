import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Chart } from 'primereact/chart';
import { URL_AXIOS, URL_FOLDER_API, URL_MAIN } from '../../URL/URL';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { format } from 'date-fns';
import JoditEditor from 'jodit-react';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { FaRegFilePdf } from "react-icons/fa";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import Loading from './Loading';
function Statistiques() {
  const editor = useRef(null);
  const {
    disableAvis, setDisableAvis,
    dataExamenStatistique, setDataExamenStatistique,
    periodeContext, setPeriodeContext,
    membreValueContext, setMembreValueContext
  } = useContext(ThemeContext);
  let totaleachRowGet = 0;
  let totalFact = 0;
  let totalFact2 = 0;
  let tva = 0.18;
  let ca = 0.05;
  let totalJAnvier = 0;
  let totalFevrier = 0;
  let totalMars = 0;
  let totalAvril = 0;
  let totalMai = 0;
  let totalJuin = 0;
  let totalJuillet = 0;
  let totalAout = 0;
  let totalSeptembre = 0;
  let totalOctobre = 0;
  let totalNovembre = 0;
  let totalDecembre = 0;
  let total_prix_uni_value = 0;
  let total_prix_uni_values = {};
  let total_net_a_payer_values = {};
  let total_general_hors_taxe_values = 0;
  const usernameconnected = sessionStorage.getItem("nom");
  const user_id = sessionStorage.getItem("user_id");
  const membre_id = sessionStorage.getItem("membre_id");
  const site_id = parseInt(sessionStorage.getItem("site_id"));
  const navigate = useNavigate();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [donnee, setDonnee] = useState([]);
  const [periode, setPeriode] = useState("");
  const [sites, setSites] = useState([]);
  const [root_stat_site, setRoot_stat_site] = useState([]);
  const [labels, setLabels] = useState([]);
  const [eachSiteData, setEachSiteData] = useState([]);
  const [eachSiteMontsData, setEachMontsSiteData] = useState([]);
  const [observation, setObservation] = useState("");
  const [janData, setJanData] = useState(0);
  const [fevData, setFevData] = useState(0);
  const [marData, setMarData] = useState(0);
  const [avrData, setAvrData] = useState(0);
  const [maiData, setMaiData] = useState(0);
  const [junData, setJunData] = useState(0);
  const [julData, setJulData] = useState(0);
  const [aouData, setAouData] = useState(0);
  const [sepData, setSepData] = useState(0);
  const [octData, setOctData] = useState(0);
  const [novData, setNovData] = useState(0);
  const [decData, setDecData] = useState(0);
  const [totaleachRow, setTotaleachRow] = useState([]);
  const [totaleachRowByMonth, setTotaleachRowByMonth] = useState([]);
  const [eachSiteDataSubTotal, setEachSiteDataSubTotal] = useState([]);
  const [activeIndex, setActiveIndex] = useState();
  const [membreData, setMembreData] = useState([]);
  const [membreValue, setMembreValue] = useState(0);
  const [membreDataSite, setMembreDataSite] = useState([]);
  const [statistiqueDataFacturation, setStatistiqueDataFacturation] = useState([]);
  const [facturationNbrExamen, setFacturationNbrExamen] = useState([]);
  const [totalFacturation, setTotalFacturation] = useState(0);
  const [datedebut, setDateDebut] = useState('');
  const [datefin, setDateFin] = useState('');
  const [boolBtn, setBoolBtn] = useState(true);
  const [statistiqueAllDataGroupMembre, setStatistiqueAllDataGroupMembre] = useState([]);
  const [data_FacturationByMembre, setData_FacturationByMembre] = useState([]);
  const [prix_total_sans_pourcent, setPrix_total_sans_pourcent] = useState([]);
  const [net_a_payer, setNet_a_payer] = useState([]);
  const [prix_hors_taxe, setPrix_hors_taxe] = useState([]);
  const [sommeGenerale, setSommeGenerale] = useState(0);
  const [loading, setLoading] = useState(true);


  function formatNumberWithSpaces(number) {
    // Convert the number to a string
    var numberString = number.toString();

    // Split the string into parts separated by three digits
    var parts = [];
    while (numberString.length > 3) {
      parts.unshift(numberString.slice(-3));
      numberString = numberString.slice(0, -3);
    }
    parts.unshift(numberString);
    // Join the parts with a space in between
    return parts.join(' ');
  }

  async function addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
  const generateToPdf = async () => {
    try {
      const fact = "E-METECH_Facture_du " + datedebut + " au " + datefin;
      const doc = new jsPDF();
      // const size_img = document.getElementById("entete_img");
      // const image = await addImageProcess("../../img/ent-em.PNG");

      // Ajouter l'image au début du document
      // doc.addImage(image, "PNG", 0, 0, 200, 50);

      let spaceAfterImage = 30;
      const text = "ETAT DES PRESTATIONS DES MEDECINS RADIOLOGUES";

      // Get the page width
      const pageWidth = doc.internal.pageSize.getWidth();
      
      // Get the width of the text
      const textWidth = doc.getTextWidth(text);
      
      // Calculate the x position to center the text
      const x = (pageWidth - textWidth) / 2;
      
      // Define the y position
      const y = 15;
      
      // Add the text to the document, centered
      doc.text(text, x, y);
      
      // Set the line width for the underline
      doc.setLineWidth(0.5);
      
      // Draw the underline
      doc.line(x, y + 1, x + textWidth, y + 1);

      // Parcourir les données de statistiques par membre
      statistiqueAllDataGroupMembre.forEach((membreData) => {
        const { membre_id } = membreData;

        // Générer le tableau pour les données de facturation du membre
        autoTable(doc, {
          html: '#table_facture_' + membre_id,
          startY: spaceAfterImage,
        });

        // Ajouter de l'espace après chaque tableau
        spaceAfterImage = doc.autoTable.previous.finalY + 5;
      });

      // Ajouter un autre tableau s'il y a lieu
      autoTable(doc, {
        html: '#table_facture2',
        startY: spaceAfterImage,
      });

      // Enregistrer le document PDF
      doc.save(fact);
    } catch (error) {
      console.error("Une erreur s'est produite lors de la génération du PDF :", error);
    }
  };


  function generate() {
    var doc = new jsPDF();

    doc.autoTable({
      html: '#table_facture',
      bodyStyles: { minCellHeight: 15 },
      didDrawCell: function (data) {
        if (data.column.index === 5 && data.cell.section === 'body') {
          var td = data.row;
          var img = td.getElementsByTagName('img')[0];
          var dim = data.cell.height - data.cell.padding('vertical');
          var textPos = data.cell.textPos;
          doc.addImage(img.src, textPos.x, textPos.y, dim, dim);
        }
      }
    });

    doc.save("table.pdf");
  }
  const rechercherByIntervalDateFacture = async () => {
    try {

      const response = await axios.post(URL_AXIOS + `rechercheFactureByDate/${datedebut}/${datefin}/${site_id}/${periode}`);
      if (response.data.status == 200) {
        setStatistiqueDataFacturation(response.data.statistiqueDataFacturation);
        setFacturationNbrExamen(response.data.totalForEachCategorieFacturation);
        setStatistiqueAllDataGroupMembre(response.data.statistiqueAllDataGroupMembre);
        setData_FacturationByMembre(response.data.totalForEachCategorieFacturationByMembre);
        setPrix_total_sans_pourcent(response.data.prix_total_sans_pourcent);
        setNet_a_payer(response.data.net_a_payer);
        setPrix_hors_taxe(response.data.prix_hors_taxe);
        setSommeGenerale(response.data.sommeGeneral);
        console.log("Somme general " + response.data.sommeGeneral);
        setBoolBtn(false);
      }

    } catch (error) {

    }

  }

  const cancelRecherche = () => {
    setBoolBtn(true);
    setDateDebut('');
    setDateFin('');
    LoadAllSiteByMemberAndSite(site_id, periode)

  }
  const liste_membres = async () => {
    try {
      const response = await axios.get(URL_AXIOS + 'membres');
      if (response.data.status === 200) {
        setMembreData(response.data.membres);
        //setMembre(response.data.membres.length);
      }
    } catch (Error) {

    }

  }
  const years = [
    {
      label: "2023",
      value: "2023"
    },
    {
      label: "2024",
      value: "2024"
    },
    {
      label: "2025",
      value: "2025"
    },
    {
      label: "2026",
      value: "2026"
    },
    {
      label: "2027",
      value: "2027"
    },
    {
      label: "2028",
      value: "2028"
    },
    {
      label: "2029",
      value: "2029"
    },
    {
      label: "2030",
      value: "2030"
    }
  ];

  const MonthDataCompar = ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"];
  const op = useRef(null);






  const LoadAllSiteByMemberAndSite = async (id, p) => {
    try {
      const response = await axios.get(URL_AXIOS + `allSiteByMemberBySite/${id}/${p}`);
      if (response.data.status === 200) {
        //setSites(response.data.all_site);
        setEachSiteData(response.data.examenBySite);
        setEachSiteDataSubTotal(response.data.marsArray);
        setEachMontsSiteData(response.data.data_stat_months);
        setTotaleachRow(response.data.data_stat_total);
        setDataExamenStatistique(response.data.statistiqueAllData);
        setTotaleachRowByMonth(response.data.data_stat_total_for_each_month);
        setStatistiqueDataFacturation(response.data.statistiqueDataFacturation);
        setFacturationNbrExamen(response.data.totalForEachCategorieFacturation);
        setStatistiqueAllDataGroupMembre(response.data.statistiqueAllDataGroupMembre);
        setData_FacturationByMembre(response.data.totalForEachCategorieFacturationByMembre);
        setPrix_total_sans_pourcent(response.data.prix_total_sans_pourcent);
        setNet_a_payer(response.data.net_a_payer);
        setPrix_hors_taxe(response.data.prix_hors_taxe);
        setSommeGenerale(response.data.sommeGeneral);

      } else {

      }
    } catch (error) {

    }
  }
  const onChangePeriode = async (e) => {

    try {
      const value = e.target.value;

      const data = {
        site_id: site_id,
        periode: value
      }
      if (value !== "") {

        setPeriode(value)
        //setMembreValueContext(value)
        //setMembreValue(value);
        //setPeriodeContext(periode);

        const response = await axios.post(URL_AXIOS + `create_statistiqueBySite/${site_id}/${value}`);
        if (response.data.status === 200) {
          setRoot_stat_site(response.data?.root_stat_site);
          setSites(response.data?.all_site);

          // const MonthOfYearLabels = [
          //   (response.data.jan) ? response.data.jan.mois_lettre : 'Jan',
          //   (response.data.fev) ? response.data.fev.mois_lettre : 'Fev',
          //   (response.data.mar) ? response.data.mar.mois_lettre : 'Mar',
          //   (response.data.avr) ? response.data.avr.mois_lettre : 'Avr',
          //   (response.data.mai) ? response.data.mai.mois_lettre : 'Mai',
          //   (response.data.jun) ? response.data.jun.mois_lettre : 'Jun',
          //   (response.data.jul) ? response.data.jul.mois_lettre : 'Jul',
          //   (response.data.aou) ? response.data.aou.mois_lettre : 'Aou',
          //   (response.data.sep) ? response.data.sep.mois_lettre : 'Sep',
          //   (response.data.oct) ? response.data.oct.mois_lettre : 'Oct',
          //   (response.data.nov) ? response.data.nov.mois_lettre : 'Nov',
          //   (response.data.dec) ? response.data.dec.mois_lettre : 'Dec'
          // ]
          // if (MonthOfYearLabels.length > 0) {
          //   setLabels(MonthOfYearLabels)
          // } else {
            setLabels(["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"])
          // }

          let sommeDataJan = response.data.jan.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataFev = response.data.fev.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataMar = response.data.mar.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataAvril = response.data.avr.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataMai = response.data.mai.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataJuin = response.data.jun.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataJul = response.data.jul.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataAou = response.data.aou.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataSep = response.data.sep.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataOct = response.data.oct.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataNov = response.data.nov.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);
          let sommeDataDec = response.data.dec.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);

          const DataMonthOfYear = [
            sommeDataJan,
            sommeDataFev,
            sommeDataMar,
            sommeDataAvril,
            sommeDataMai,
            sommeDataJuin,
            sommeDataJul,
            sommeDataAou,
            sommeDataSep,
            sommeDataOct,
            sommeDataNov,
            sommeDataDec
          ]
          console.log("Données du mois pour le graphique :", DataMonthOfYear);
          let sommeData = response.data.annee.reduce((acc, valeur) => acc + parseFloat(valeur.netapayer), 0);

          if (sommeData > 0) {
            setDonnee(DataMonthOfYear)
          } else {
            setDonnee([])
          }

        } else {

        }
      } else {
        setPeriodeContext("");
      }
    } catch (error) {

    }

  }

  const handleDownload = (e, value) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.download = 'Example-PDF-File';

    link.href = value;

    link.click();
  };

  const handleOpen = (e, value) => {
    e.preventDefault();
    const link = document.createElement('a');
    //link.download = 'Example-PDF-File';

    link.href = value;
    link.target = "_blank";

    link.click();
  };
  const chartLine = (periode) => {
    try {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
      const data = {
        labels: labels,
        datasets: [
          {
            type: 'line',
            label: periode,
            color: "white",
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: "#fff",
            borderWidth: 2,
            fill: true,
            tension: 0.4,
            data: donnee
          }

        ]
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.9,
        plugins: {
          legend: {
            labels: {
              color: "#fff",
              font: {
                weight: 'bold'
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: "#fff",
              font: {
                weight: 'bold', // set the font weight here
              },
            },

          },
          y: {
            ticks: {
              color: "#fff",
              font: {
                weight: 'bold', // set the font weight here
              },
            },

          }
        }
      };

      setChartData(data);
      setChartOptions(options);
    } catch (error) {

    }
  }

  const headerAccordionData = (societe, number) => {
    return (
      <div style={{ display: 'flex', width: "100%" }}>
        <p>{societe}</p>
        <p style={{ marginLeft: "15px", width: "20px", height: "20px", display: 'none', backgroundColor: 'red', borderRadius: "100%", padding: "5px", color: 'white' }}>{number}</p>
      </div>
    )
  }
  const config = {

    height: 'auto',
    allowResizeX: false,
    allowResizeY: false,
    autofocus: true,
    toolbarAdaptive: false,
    editorClassName: 'editorComponent_apercu2',
    readonly: true,
    toolbar: true,
    //activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    saveModeInCookie: true,
    spellcheck: true,
    editorCssClass: true,
    triggerChangeEvent: true,
    statusbar: false,
    width: '100%',
    zIndex: 0,
    toolbarSticky: false,
    direction: '',
    language: 'auto',
    debugLanguage: false,
    i18n: 'fr',
    tabIndex: -1,
    enter: "P",
    useSplitMode: false,
    colors: {
      greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
      palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
      full: [
        '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
        '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
        '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
        '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
        '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
        '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
      ]
    },
    colorPickerDefaultTab: 'background',
    removeButtons: [],
    disablePlugins: [],
    extraButtons: [],
    buttons: [

    ],
    /*buttonsXS: [
        'bold',
        'image', '|',
        'paragraph', '|',
        'align', '|',
        'undo', 'redo', '|',
        'eraser',
        'dots'
    ],*/
    events: {},
    textIcons: false,
  }
  useEffect(() => {
    // Simulate a loading process (e.g., data fetching)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout as needed

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);
  useEffect(() => {

    if (periode !== "") {
      LoadAllSiteByMemberAndSite(site_id, periode);
    }

    liste_membres();
    chartLine(periode);
    if (usernameconnected === null || usernameconnected === "") {
      navigate('/')
    }
  }, [donnee, periode]);
  useEffect(() => {
    setDisableAvis(true)

  }, [])

  const headerMainFirst = () => {
    return (
      <div>
        <h5>Nombre des examens interpétés par centre</h5>
      </div>
    )
  }
  const headerMainSecond = () => {
    return (
      <div>
        <h5>Examens interpétés</h5>
      </div>
    )
  }
  const headerPatient = (Patient, Examen, Centre, Fichier, Fichier_2, Bulletin, date) => {
    return (
      <div className='container-fluid'>


        <div className='row'>
          <div className='col col-md-3'>
            <p>{Patient}</p>
          </div>
          <div className='col col-md-3'>
            <p>{Examen}</p>
          </div>
          <div className='col col-md-2'>
            <p>{Centre}</p>
          </div>
          <div className='col col-md-2'>
            <div style={{ display: 'flex', marginTop: "8px", color: 'green' }}>

              {Fichier !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}><Link title={Fichier}
                to={'/statistiques'}
                onClick={(e) => handleDownload(e, URL_FOLDER_API + Fichier)}
              >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </p>}

              {Fichier_2 !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}>&nbsp;<Link
                title={Fichier_2}
                to={'/statistiques'}
                onClick={(e) => handleDownload(e, URL_FOLDER_API + Fichier_2)}
                target="_blank"
                rel="noopener noreferrer"
              >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></p>}

            </div>
          </div>
          <div className='col col-md-1' style={{ color: 'green' }}>
            {Bulletin !== null && <p style={{ fontWeight: 'normal', fontSize: "13px" }}><Link title={Bulletin} style={{ position: 'absolute' }} to={'/statistiques'} onClick={(e) => handleOpen(e, URL_FOLDER_API + Bulletin)}>Bulletin</Link></p>}
          </div>
          <div className='col col-md-1'>
            {format(date, 'dd/MM/yyyy')}
          </div>

        </div>
      </div>
    )
  }
  return (
    <div style={{ overflowX: 'none', overflowY: 'none', maxHeight: '730px' }}>
      <Header />
      <AsideBar username={usernameconnected} />
      {loading ? <Loading /> : <section id="main-content">
        <section class="wrapper">
          <div className='row'>
            <div className='card' style={{ width: "100%", height: "12vh" }}>
              <div className='row'>
                <div className='col col-md-1'>

                </div>
                <div className='col col-md-10'>
                  <div style={{ display: "flex", width: '100%', justifyContent: 'center' }}>
                    <label style={{ marginTop: 30, fontWeight: 600, color: 'black' }}>Période *:</label>
                    <select className='form-control'
                      onChange={(e) => onChangePeriode(e)}
                      style={{ marginTop: 20, width: "40%", marginLeft: 5, fontWeight: 600, color: 'black' }}>
                      <option value="">Veuillez sélectionner la période</option>
                      {years.map((y, index) => (
                        <option key={index} value={y.value}>{y.label}</option>
                      ))}
                    </select>
                  </div>

                </div>
                <div className='col col-md-1'>


                </div>
              </div>

            </div>

          </div>
          <div className='row'>
            <div className="card" style={{ height: "auto", width: "98%", marginLeft: "1%", backgroundColor: "#163356" }}>
              <Chart type="line" data={chartData} options={chartOptions} />
            </div>
          </div>
          <div className='row'>
            <div className="content" style={{ marginTop: "1%", width: "98%", marginLeft: "1%" }}>
              <form id="form{{ 0 }}" action="{{route('members.statistique')}}" method="GET">
                <div class="row">

                  {
                    sites?.map((site, index) => (


                      <div class="col-md-6" key={index}>
                        <div className="card card-chart" style={{ height: 'auto' }}>
                          <div class="card-header" style={{ padding: 10 }}>
                            <h5 class="card-category"></h5>
                            <p class="card-title" style={{ color: 'red', fontWeight: 'bold' }}>{site.nom + " " + site.prenom}</p>
                            <div class="dropdown">
                              {/* <button type="button" class="btn btn-round btn-outline-default dropdown-toggle btn-simple btn-icon no-caret"
                                style={{ float: 'right' }}
                                onClick={(e) => op.current.toggle(e)}>
                                <i class="fa fa-gear"></i>
                              </button> */}
                              <OverlayPanel ref={op} showCloseIcon closeOnEscape dismissable={false}>
                                <div className='container-fluid'>
                                  <div className='row'>
                                    <button type='button' className='btn btn-primary form-control'>Charger les données</button>
                                  </div>
                                  <br />
                                  <div className='row'>
                                    <button type="button" className="btn btn-info form-control">Liste des prix</button>
                                    <input type="hidden" name="_{{$site->site_id}}" value="{{$site->site_id}}" />
                                  </div>


                                </div>
                              </OverlayPanel>

                            </div>
                          </div>
                          <div class="card-body" style={{ paddingBottom: "50px", padding: 10 }}>
                            <div class="table-responsive-md">
                              <table class="table">
                                <thead class=" text-primary">
                                  <th>
                                    Période
                                  </th>
                                  <th>
                                    Exam.
                                  </th>
                                  <th>
                                    C.A.
                                  </th>
                                  <th>
                                    %
                                  </th>
                                  <th>
                                    Net
                                  </th>
                                  <th class="text-right"></th>
                                </thead>
                                <tbody>
                                  {

                                    root_stat_site[site_id][site.membre_id]?.map((ss, index) => (


                                      <tr key={index}>
                                        <td>
                                          {ss.periode}
                                        </td>
                                        <td>
                                          {ss.nbrExamen}
                                        </td>
                                        <td>

                                          {ss.gain !== 0 ? new Intl.NumberFormat().format(ss.gain) + "  XAF" : 0}
                                        </td>
                                        <td>
                                          {ss.pourcentage}
                                        </td>
                                        <td>
                                          { /* {{number_format($ss->netapayer,0,',',' ')}}{{__(' XAF')}}*/}
                                          {ss.netapayer !== 0 ? new Intl.NumberFormat().format(ss.netapayer) + "  XAF" : 0}
                                        </td>
                                        {/* <td class="text-right">
                                          <i class="fa fa-gear" style={{ fontSize: 20, border: "1px solid grey", padding: 10, borderRadius: "100%", cursor: "pointer" }}></i>
                                        </td> */}
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div class="col-md-12">
                    <div class="card  card-tasks" style={{ width: '100%', height: 'auto' }}>
                      <div class="card-header " style={{ padding: 10 }}>
                        <h3 class="card-category" style={{ textAlign: 'center' }}>Autres statistiques</h3>

                      </div>
                      <div class="card-body " style={{ padding: 10 }}>

                        {


                          <Accordion >
                            <AccordionTab header={"Examens interprétés"}>

                              <table className='table table-bordered table-striped table-condensed'>
                                <thead>
                                  <tr >

                                    <th>TITRE</th>
                                    <th style={{ textAlign: 'center' }}>Jan</th>
                                    <th style={{ textAlign: 'center' }}>Fevr</th>
                                    <th style={{ textAlign: 'center' }}>Mars</th>
                                    <th style={{ textAlign: 'center' }}>Avr</th>
                                    <th style={{ textAlign: 'center' }}>Mai</th>
                                    <th style={{ textAlign: 'center' }}>Juin</th>
                                    <th style={{ textAlign: 'center' }}>Juil</th>
                                    <th style={{ textAlign: 'center' }}>Août</th>
                                    <th style={{ textAlign: 'center' }}>Sept</th>
                                    <th style={{ textAlign: 'center' }}>Oct</th>
                                    <th style={{ textAlign: 'center' }}>Nov</th>
                                    <th style={{ textAlign: 'center' }}>Dec</th>

                                    <th style={{ textAlign: 'center' }}>TOTAL</th>
                                  </tr>
                                </thead>

                                <tbody>

                                  {

                                    eachSiteData?.map((val, index2) => {
                                      var Janvier = (eachSiteMontsData[val.titre]["01"] != null ? eachSiteMontsData[val.titre]["01"] : 0);
                                      var Fevrier = (eachSiteMontsData[val.titre]["02"] != null ? eachSiteMontsData[val.titre]["02"] : 0);
                                      var Mars = (eachSiteMontsData[val.titre]["03"] != null ? eachSiteMontsData[val.titre]["03"] : 0);
                                      var Avril = ((eachSiteMontsData[val.titre]["04"] != null && eachSiteMontsData[val.titre]["04"] !== "") ? eachSiteMontsData[val.titre]["04"] : 0);
                                      var Mai = (eachSiteMontsData[val.titre]["05"] != null ? eachSiteMontsData[val.titre]["05"] : 0);
                                      var Juin = (eachSiteMontsData[val.titre]["06"] != null ? eachSiteMontsData[val.titre]["06"] : 0);
                                      var Juillet = (eachSiteMontsData[val.titre]["07"] != null ? eachSiteMontsData[val.titre]["07"] : 0);
                                      var Aout = (eachSiteMontsData[val.titre]["08"] != null ? eachSiteMontsData[val.titre]["08"] : 0);
                                      var Septembre = (eachSiteMontsData[val.titre]["09"] != null ? eachSiteMontsData[val.titre]["09"] : 0);
                                      var Octobre = (eachSiteMontsData[val.titre]["10"] != null ? eachSiteMontsData[val.titre]["10"] : 0);
                                      var Novembre = (eachSiteMontsData[val.titre]["11"] != null ? eachSiteMontsData[val.titre]["11"] : 0);
                                      var Decembre = (eachSiteMontsData[val.titre]["12"] != null ? eachSiteMontsData[val.titre]["12"] : 0);
                                      const rowTotal = (parseInt(Janvier) + parseInt(Fevrier) + parseInt(Mars) + parseInt(Avril) + parseInt(Mai) + parseInt(Juin) + parseInt(Juillet) + parseInt(Aout) + parseInt(Septembre) + parseInt(Octobre) + parseInt(Novembre) + parseInt(Decembre));


                                      totalJAnvier = parseInt((totaleachRowByMonth["01"] != null ? totaleachRowByMonth["01"] : 0));
                                      totalFevrier = parseInt((totaleachRowByMonth["02"] != null ? totaleachRowByMonth["02"] : 0));
                                      totalMars = parseInt((totaleachRowByMonth["03"] != null ? totaleachRowByMonth["03"] : 0));
                                      totalAvril = parseInt((totaleachRowByMonth["04"] != null ? totaleachRowByMonth["04"] : 0));
                                      totalMai = parseInt((totaleachRowByMonth["05"] != null ? totaleachRowByMonth["05"] : 0));
                                      totalJuin = parseInt((totaleachRowByMonth["06"] != null ? totaleachRowByMonth["06"] : 0));
                                      totalJuillet = parseInt((totaleachRowByMonth["07"] != null ? totaleachRowByMonth["07"] : 0));
                                      totalAout = parseInt((totaleachRowByMonth["08"] != null ? totaleachRowByMonth["08"] : 0));
                                      totalSeptembre = parseInt((totaleachRowByMonth["09"] != null ? totaleachRowByMonth["09"] : 0));
                                      totalOctobre = parseInt((totaleachRowByMonth["10"] != null ? totaleachRowByMonth["10"] : 0));
                                      totalNovembre = parseInt((totaleachRowByMonth["11"] != null ? totaleachRowByMonth["11"] : 0));
                                      totalDecembre = parseInt((totaleachRowByMonth["12"] != null ? totaleachRowByMonth["12"] : 0));
                                      totaleachRowGet = (totaleachRow != null ? totaleachRow : 0);


                                      return (
                                        <tr style={{ textAlign: 'center' }} key={index2}>

                                          <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{val.titre}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Janvier}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Fevrier}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Mars}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Avril}
                                          </td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Mai}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Juin}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Juillet}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Aout}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Septembre}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Octobre}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Novembre}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{Decembre}</td>
                                          <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{rowTotal}</td>
                                        </tr>

                                      )
                                    })}

                                </tbody>
                                <tfoot>
                                  <tr >
                                    <td>

                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalJAnvier}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalFevrier}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalMars}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalAvril}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalMai}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalJuin}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalJuillet}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalAout}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalSeptembre}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalOctobre}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalNovembre}
                                    </td>
                                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                      {totalDecembre}
                                    </td>

                                    <td style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>{totaleachRowGet}</td>


                                  </tr>

                                </tfoot>


                              </table>

                            </AccordionTab>

                          </Accordion>



                        }
                        <Card title="FACTURATION">

                          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                            <label style={{ marginTop: 10, fontWeight: 'bold', fontSize: 12 }}>Date début * :</label>
                            <input type='date'
                              className='form-control'
                              value={datedebut}
                              onChange={(e) => setDateDebut(e.target.value)}
                              style={{ width: '20%', marginLeft: 10 }}
                            />
                            <label style={{ marginLeft: 10, marginTop: 10, fontWeight: 'bold', fontSize: 12 }}>Date fin * :</label>
                            <input type='date'
                              className='form-control'
                              value={datefin}
                              onChange={(e) => setDateFin(e.target.value)}
                              placeholder='Date fin' showIcon style={{ marginLeft: 10, width: '20%' }}

                            />
                            <button
                              type='button'
                              className={boolBtn == true ? 'btn btn-primary' : 'btn btn-danger'}
                              onClick={boolBtn == true ? () => rechercherByIntervalDateFacture() : () => cancelRecherche()}
                              style={{ marginLeft: 10 }}>
                              <i className={boolBtn == true ? 'fa fa-search' : 'fa fa-remove'}></i>
                            </button>
                            <button
                              type='button'
                              className='btn btn-danger'
                              onClick={() => generateToPdf()}
                              style={{ marginLeft: 10 }}>
                              <FaRegFilePdf />
                            </button>
                          </div>
                          <Divider />
                          {statistiqueAllDataGroupMembre?.map((d, index) => (

                            <div key={index}>

                              <table id={'table_facture_' + d.membre_id} className='table table-bordered table-striped table-condensed' style={{ marginTop: 10 }}>
                                <thead>
                                  <tr >
                                    <th colSpan={6} style={{ fontSize: 15, color: 'red' }}>{d.nom + " " + d.prenom + " "} </th>
                                  </tr>
                                  <tr >
                                    <th>Examens </th>
                                    <th style={{ textAlign: 'center', fontWeight: 'bold' }}>Nbre</th>
                                    <th style={{ textAlign: 'center', fontWeight: 'bold' }}>P.U</th>
                                    <th style={{ textAlign: 'center', fontWeight: 'bold' }}>P.T</th>
                                    <th style={{ textAlign: 'center', fontWeight: 'bold' }}>%</th>
                                    <th style={{ textAlign: 'center', fontWeight: 'bold' }}>Net à payer</th>
                                  </tr>
                                </thead>

                                <tbody>


                                  {statistiqueDataFacturation[d.membre_id]?.map((f, index) => {
                                    totalFact += data_FacturationByMembre[f.membre_id][f.titre] != null ? (parseInt(((data_FacturationByMembre[f.membre_id][f.titre]) * f.gain * f.pourcentage) / 100)) : 0;
                                    total_prix_uni_value += (data_FacturationByMembre[f.membre_id][f.titre] != null ? (parseInt((data_FacturationByMembre[f.membre_id][f.titre]) * f.gain)) : 0);
                                    total_prix_uni_values[d.membre_id] = (total_prix_uni_values[d.membre_id] || 0) + (data_FacturationByMembre[f.membre_id][f.titre] != null ? (parseInt((data_FacturationByMembre[f.membre_id][f.titre]) * f.gain)) : 0);
                                    total_net_a_payer_values[d.membre_id] = (total_net_a_payer_values[d.membre_id] || 0) + (data_FacturationByMembre[f.membre_id][f.titre] != null ? (parseInt(((data_FacturationByMembre[f.membre_id][f.titre]) * f.gain * f.pourcentage) / 100)) : 0);
                                    total_general_hors_taxe_values = total_general_hors_taxe_values + (data_FacturationByMembre[f.membre_id][f.titre] != null ? (parseInt(((data_FacturationByMembre[f.membre_id][f.titre]) * f.gain * f.pourcentage) / 100)) : 0);
                                    return (
                                      <tr key={index}>

                                        <td style={{ fontWeight: 'bold', color: 'black' }}>{f.titre}</td>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold', width: '5%', color: 'black' }}>{formatNumberWithSpaces(data_FacturationByMembre[f.membre_id][f.titre] != null ? (data_FacturationByMembre[f.membre_id][f.titre]) : 0)}</td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold', width: '8%', color: 'black' }}>{formatNumberWithSpaces(f.gain)}</td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold', width: '8%', color: 'black' }}>{formatNumberWithSpaces((data_FacturationByMembre[f.membre_id][f.titre] != null ? (parseInt((data_FacturationByMembre[f.membre_id][f.titre]) * f.gain)) : 0))}</td>
                                        <th style={{ textAlign: 'center', fontWeight: 'bold', width: '5%', color: 'black' }}>{f.pourcentage}</th>
                                        <th style={{ textAlign: 'right', fontWeight: 'bold', width: '10%', color: 'black' }}>{formatNumberWithSpaces((data_FacturationByMembre[f.membre_id][f.titre] != null ? (parseInt(((data_FacturationByMembre[f.membre_id][f.titre]) * f.gain * f.pourcentage) / 100)) : 0))}</th>

                                      </tr>

                                    )

                                  })}
                                </tbody>
                                <tfoot>
                                  <tr >
                                    <td colSpan={1} style={{ fontWeight: 'bold', color: 'black' }}></td>
                                    <td colSpan={2} style={{ fontWeight: 'bold', color: 'black' }}>Total GENERAL HT</td>
                                    <td style={{ backgroundColor: 'grey', color: 'white', fontWeight: 'bold', textAlign: 'right' }}>{formatNumberWithSpaces(parseInt(total_prix_uni_values[d.membre_id]).toFixed(0) || 0)}</td>
                                    <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{d.pourcentage}</td>
                                    <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', fontWeight: 'bold' }}>{formatNumberWithSpaces(parseInt(total_net_a_payer_values[d.membre_id]).toFixed(0) || 0)}</td>
                                  </tr>
                                  {/* <tr >

                                    <td style={{ fontWeight: 'bold', color: 'black' }}>Total GENERAL HT</td>
                                    <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces(parseInt(total_net_a_payer_values[d.membre_id]).toFixed(0) || 0)}</td>
                                  </tr> */}
                                </tfoot>


                              </table>

                            </div>
                          ))}
                          {/* <table id='table_facture2' className='table table-bordered table-striped table-condensed'>
                            <thead>

                            </thead>
                            <tfoot>
                              <tr >

                                <td style={{ fontWeight: 'bold', color: 'black' }}>Total GENERAL HT</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces(parseInt(total_general_hors_taxe_values).toFixed(0))}</td>
                              </tr>
                              <tr>
                                <td style={{ fontWeight: 'bold', color: 'black' }}>TVA(18%)</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces(parseInt(total_general_hors_taxe_values * tva).toFixed(0))}</td>
                              </tr>
                              <tr >

                                <td style={{ fontWeight: 'bold', color: 'black' }}>CA(5%)</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces((parseInt(total_general_hors_taxe_values * tva) * ca).toFixed(0))}</td>
                              </tr>
                              <tr >

                                <td style={{ fontWeight: 'bold', color: 'black' }}>TTC</td>
                                <td style={{ backgroundColor: 'grey', color: 'white', textAlign: 'right', width: '15%', fontWeight: 'bold' }}>{formatNumberWithSpaces(parseInt((total_general_hors_taxe_values * tva) + ((total_general_hors_taxe_values * tva) * ca) + (total_general_hors_taxe_values)).toFixed(0))}</td>
                              </tr>
                            </tfoot>
                          </table> */}
                        </Card>



                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <br />
        </section>
      </section>}
      <br /><br /><br />
      <Footer />
    </div>
  );
}

export default Statistiques;