import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { Card } from 'primereact/card';
import axios from 'axios';
import { URL_AXIOS, URL_FOLDER_API } from '../../URL/URL';
import { Link, useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge'
import { ThemeContext } from '../../context/ContextHeader';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2/dist/sweetalert2';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loading from './Loading';

function CardResultat() {

    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const site_id = sessionStorage.getItem("site_id");
    const [isChecked, setIsChecked] = useState([]);

    //const [dossiers, setDossiers] = useState([]);
    //const [dossiersEn, setDossiersEn] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [sites, setSites] = useState([]);
    const [statusResultat, setStatusResultat] = useState(false);
    const useRefInput = useRef(null);


    const {
        text, setText,
        datedebut, dossiersEn,
        setDossiersEn, dossiers,
        setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis,
        medecinID, setMedecinID,
        DossiersRecu, setDossiersRecu,
        setDossiersArchives,

    } = useContext(ThemeContext);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const dt = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = DossiersRecu.slice(firstIndex, lastIndex);
    const npage = Math.ceil(DossiersRecu.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [boolBtn, setBoolBtn] = useState(false);
    const [loading, setLoading] = useState(true);
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    const liste_dossiers_by_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_resultatBySite/${parseInt(site_id)}`);
            if (response.data.status == 200) {
                setDossiersRecu(response.data.vue_dossier_interpreter);
                setLoading(false)

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }
    const ArchiverDossierRecuTraitement = async () => {
        try {
            for (const val of isChecked) {

                const response = await axios.post(URL_AXIOS + `ArchiverDossierRecu/${val}`);
                if (response.data.status == 200) {
                    liste_dossiers_by_site();
                    liste_dossiersArchive_by_site(site_id);
                }

            }
        } catch (error) {

        }
    }
    const liste_dossiersArchive_by_site = async (site_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_resultatArchiveBySite/${site_id}`);
            if (response.data.status == 200) {
                setDossiersArchives(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const ArchiverDossierRecu = async () => {
        try {
            Swal.fire({
                title: "Questions ? ",
                text: "Voulez-vous archiver ce(s) dossier(s) !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {
                    ArchiverDossierRecuTraitement();
                    liste_dossiers_by_site();
                    liste_dossiersArchive_by_site(site_id);

                }
            });
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const handleCheckedInput = (e) => {
        const value = e.target.value; // Get the value of the checkbox
        const checked = e.target.checked;
        let updatedArray;
        if (checked) {
            updatedArray = [...isChecked, value];
        } else {
            updatedArray = isChecked.filter((item) => item !== value);
        }
        setIsChecked(updatedArray);

        if (updatedArray.length === 0) {
            setBoolBtn(false);
        } else {
            setBoolBtn(true);
        }

    };
    const handleDownload = async (e, value, value2) => {
        e.preventDefault();

        const downloadFile = (fileName, url) => {
            return new Promise((resolve) => {
                const link = document.createElement('a');
                link.download = fileName;
                link.href = url;
                document.body.appendChild(link); // Append link to the body
                link.click();
                document.body.removeChild(link); // Remove link from the body
                resolve();
            });
        };

        try {
            if (value !== null) {
                await downloadFile('Example-PDF-File', URL_FOLDER_API + value);
            }
            if (value2 !== null) {
                // Increase delay to ensure the browser has time to handle the first download
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await downloadFile('Example-PDF-File2', URL_FOLDER_API + value2);
            }
        } catch (error) {
            console.error('Error during download:', error);
        }
    };
    // const handleDownload = (e, value, value2) => {
    //     e.preventDefault();
    //     const link = document.createElement('a');
    //     if (value !== null) {

    //         link.download = 'Example-PDF-File';
    //         link.href = value;
    //         link.click();
    //     }
    //     if (value2 !== null) {
    //         // Delay the creation and click event of the second anchor
    //         setTimeout(() => {
    //             const link2 = document.createElement('a');
    //             link2.download = 'Example-PDF-File2';
    //             link2.href = value2;
    //             link2.click();
    //         }, 100); // Adjust the delay time as needed
    //     }
    // };
    // const handleDownload = (e, value) => {
    //     e.preventDefault();
    //     const link = document.createElement('a');
    //     link.download = 'Example-PDF-File';

    //     link.href = value;

    //     link.click();
    // };
    const handleApercu = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        // link.download = 'Example-PDF-File';
        link.href = `/apercu/${value}`;
        link.target = "_blank";
        link.click();
    };

    const handleOpen = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';

        link.href = value;
        link.target = "_blank";

        link.click();
    };

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>

                <Checkbox value={data.id} name={`check_${data.id}`} onChange={(e) => handleCheckedInput(e)} checked={isChecked.includes(data.id)} />
                <i title='Impression' className='fa fa-print' style={{ fontSize: "15px", marginLeft: 10, marginTop: 5, cursor: 'pointer', color: 'green' }} onClick={(e) => handleApercu(e, data.id)}></i>
                {/* <i className='fa fa-download'
                    onClick={(e) => handleDownload(e, (URL_FOLDER_API + data.chemin_fichier),(URL_FOLDER_API + data.chemin_fichier_2))}
                    style={{
                        color: 'green',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14,
                        marginTop: 5
                    }}
                    title='Télécharger examen'
                >
                </i> */}

                {/* {data.nom_fichier !== null || data.nom_fichier_2 !== null ? <i className='fa fa-download'
                    onClick={(e) => handleDownload(e, (data.chemin_fichier), (data.chemin_fichier_2))}
                    style={{
                        color: 'green',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14
                    }}
                    title='Télécharger examen'
                >
                </i> : ""} */}

            </div>
        )
    }

    useEffect(() => {
        // liste_dossiers_by_site(14,)

        setDisable(true)
        setDisableAvis(true);
        liste_dossiers_by_site();

        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper">
                    <div className='row' style={{ marginTop: 10 }}>
                        <div className='col col-md-8'>

                        </div>
                        <div className='col col-md-4'>
                            <button type='button'
                                className='btn btn-warning'
                                style={{ fontWeight: 'bold', float: 'right' }}
                                onClick={() => ArchiverDossierRecu()}
                                disabled={boolBtn === false ? true : false}
                                title='Archiver les dossiers sélectionnés'
                            >
                             <i className='fa fa-archive'></i>
                            </button>
                        </div>
                    </div>
                    <DataTable value={DossiersRecu} emptyMessage="Aucun dossier disponible!" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className='custom-datatable'>
                        <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} field='id' style={{ width: '5%', height: '40px' }}></Column>
                        <Column field='nom_pat' header="PATIENTS" style={{ width: '25%', height: '40px' }}></Column>
                        <Column field={'categorie_fichier'} header="TYPE EXAMEN" style={{ width: '30%', height: '40px' }}></Column>
                        <Column field={(data) => { return data.nom_medecin + ' ' + data.prenom_medecin }} header="RADIOLOGUE" style={{ width: '24%', height: '40px' }}></Column>
                        <Column field='created_at' header="ENVOYE LE" style={{ width: '10%', height: '40px' }}></Column>
                        <Column field='updated_at' header="RECU LE" style={{ width: '10%', height: '40px' }}></Column>
                        <Column header="ACTIONS" body={ActionBody} style={{ width: '8%', height: '40px' }}></Column>
                    </DataTable>



                    {/* {DossiersRecu.length > 0 && <div class="row" style={{ padding: 10 }}>

                            <table ref={dt} class="table table-bordered table-striped table-condensed">
                                <thead>
                                    <tr style={{ backgroundColor: "white", color: 'red' }}>
                                        <th><center>#</center></th>
                                        <th>PATIENTS</th>
                                        <th>TYPE EXAMEN</th>
                                        <th>RADIOLOGUE</th>
                                        <th>ENVOYER LE</th>
                                        <th>RECU LE</th>
                                        <th></th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {records
                                        .map((si, index) => (
                                            <tr key={si.id}>
                                                <td><center>{index + 1}</center></td>
                                                <td>{si.nom_pat}</td>
                                                <td>{si.categorie_fichier}</td>
                                                <td>{si.nom_medecin + ' ' + si.prenom_medecin}</td>
                                                <td>{si.created_at}</td>
                                                <td>{si.updated_at}</td>
                                                <td>
                                                    <center>
                                                        <Checkbox value={si.id} name={`check_${si.id}`} onChange={(e) => handleCheckedInput(e)} checked={isChecked.includes(si.id)} />
                                                        {/*<input type="checkbox" value={si.id} onChange={(e) => handleCheckedInput(e)} />*/}
                    {/* </center>
                                                </td>
                                                <td>
                                                    <center>
                                                        <button type='button' className='btn btn-success'
                                                            onClick={(e) => handleApercu(e, si.id)}
                                                        >
                                                            <i className='fa fa-print'></i>
                                                        </button>
                                                    </center>

                                                </td>

                                            </tr>
                                        ))}
                                </tbody>

                            </table>

                            <nav>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={prevPage}
                                        >Prev</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href='#' className='page-link'
                                                    onClick={() => changeCPage(n)}
                                                >{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={nextPage}
                                        >Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>}
                        {DossiersRecu.length <= 0 && <div style={{ display: 'flex' }}><img src='../../img/folder_empty.png' /> <h3 style={{ color: 'red', marginTop: '110px' }}>Aucun dossier trouvé</h3></div>}
                    //  */}





                    <br /><br /><br /><br />
                </section>
            </section>}
            <Footer />
        </div>
    );
}

export default CardResultat;