import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { AutoComplete } from 'antd';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import Footer from '../Footer';
import ListeDossiersComponent from './ListDossiers';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import { RadioButton } from "primereact/radiobutton";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ContextMenu } from 'primereact/contextmenu';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../context/ContextHeader';
function ListeArchivesDossiersTransfere(props) {
    const navigate = useNavigate();
    const { DossiersTransfereRetire, setDossiersTransfereRetire}=useContext(ThemeContext);
    const [visible, setVisible] = useState(false);
    const [sites, setSites] = useState([]);
    const [dossiers, setDossiers] = useState([]);
    const [site, setSite] = useState('');
    const [allcheck, setAllcheck] = useState(true);
    const [query, setQuery] = useState("");
    const [selectedMembres, setSelectedMembres] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [medecinradio, setMedecinradio] = useState(0);
    const [medecinradioSetValue, setMedecinradioSetValue] = useState(0);
    const recordsPerPage = 30;
    const dt = useRef(null);
    const cm = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = DossiersTransfereRetire.slice(firstIndex, lastIndex);
    const npage = Math.ceil(DossiersTransfereRetire.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [isChecked, setIsChecked] = useState([]);
    const [queryPatient, setQueryPatient] = useState("");
    const [queryExamen, setQueryExamen] = useState("");
    const [dataPatient, setDataPatient] = useState([]);
    const [dataExamen, setDataExamen] = useState([]);
    const [membres, setMembres] = useState([]);
    const [dateDebut, setDateDebut] = useState(new Date());
    const [dateFin, setDateFin] = useState(new Date());
    const [bool, setBool] = useState(true);
    const [boolD, setBoolD] = useState(false);
    const [statusM, setStatusM] = useState(false);
    

    const items = [
        { label: 'Affectation', icon: 'pi pi-fw pi-users', command: () => confirm1() }
    ];
    const accept = () => {
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        affectationMedecin();
        setVisible(false);
        setMedecinradio('');


    }

    const reject = () => {
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        //setMedecinradio('')
    }
    const confirm1 = () => {
        if (medecinradio !== 0) {
            confirmDialog({
                message: "Vous voulez-vous effectuer l'affectation médecin ?",
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept,
                reject
            });

        } else {
            alert("Veuillez cocher le médecin")
        }

    };
    const confirm2 = () => {
        if (isChecked.length > 0) {
            confirmDialog({
                message: "Vous voulez-vous supprimer ce dossier ?",
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
               
                        retirer_dossier();
                        setVisible(false);
                        setStatusM(false);
                  
                  
                },
                reject: () => {
                    setVisible(false);
                }
            });

        } else {
            setStatusM(true);
        }

    };
    function formatDate(date) {
        const datecreated = new Date(date)
        const year = datecreated.getFullYear();
        const month = String(datecreated.getMonth() + 1).padStart(2, '0');
        const day = String(datecreated.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const cancel_research = () => {
        setDateDebut('');
        setDateFin('');
        setBool(true);
        liste_dossiers();
        setBoolD(false);
    }
   /* const rechercher_dossier = async () => {

        try {
            const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl/${dateDebut}/${dateFin}`);
            if (response.data.status === 200) {
                setDossiers(response.data.vue_dossier_send_pl);
                setBool(false);
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }*/
    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }

    const handleCheckboxChange = () => {
        setAllcheck(!allcheck);
    };
    const handleQueryText = async (e) => {
        try {
            const query_name = e.target.value;
            setQuery(query_name);
            setCurrentPage(1);
            if (query_name.length > 0) {
                // Call the helper function to filter dossiers

                const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_by_input/${query_name}`);
                if (response.data.status === 200) {
                    setDossiers(response.data.vue_dossier_send_pl_input);

                }
            } else {
                liste_dossiers();
            }


        } catch (error) {
        }
    };

    const liste_site = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
            }
        } catch (Error) {

        }


    }
    const liste_dossiers = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_dossiers_transfere_retire');
            if (response.data.status == 200) {
                setDossiersTransfereRetire(response.data.dossier_send);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + 'index');
            if (response.data.status === 200) {
                setMembres(response.data.data_membres_sites);

            }
        } catch (Error) {

        }
    };

    /* const handleCheckedInput = (e) => {
         const value = e.target.value; // Get the value of the checkbox
         const checked = e.target.checked;
 
         if (checked) {
             setIsChecked([...isChecked, value]);
             arrayList.push(value);
         } else {
             setIsChecked(isChecked.filter((e) => e !== value));
         }
 
     };*/

    const handleCheckedInput = (e) => {
        const value = e.target.value; // Get the value of the checkbox
        const checked = e.target.checked;

        if (checked) {
            // Update isChecked state using the spread operator
            setIsChecked((prevChecked) => [...prevChecked, value]);

            // Update arrayList state using the spread operator

        } else {
            // Update isChecked state by filtering out the value
            setIsChecked((prevChecked) => prevChecked.filter((e) => e !== value));

            // Update arrayList state by filtering out the value

        }
    };

    const handleAffectation = () => {
        if (isChecked.length > 0) {
            setVisible(true);
        } else {
            alert("Veuillez cocher la ligne");
        }
    }
    const handleRadio = (e) => {
        const value = e.target.value;

        setMedecinradio(value);
        setMedecinradioSetValue(value);
        //confirm1();
    }

    const affectationMedecin = async () => {
        try {

            for (const val of isChecked) {
                const response = await axios.post(URL_AXIOS + `affecationMedecin/${val}`, {
                    membre_id: medecinradio
                });
                if (response.data.status == 200) {
                    liste_dossiers();
                }

            }

        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const retirer_dossier = async () => {
        try {

            for (const val of isChecked) {
                const response = await axios.post(URL_AXIOS + `retirer_dossier/${val}`);
                if (response.data.status == 200) {
                    liste_dossiers();

                }

            }
            setIsChecked([]);

        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const handleEdition = () => {
        if (isChecked.length > 0) {

            const data = [isChecked];
            navigate(`/editionDossiers/`, {
                state: { data: isChecked }
            })
        } else {
            alert('Veuillez cocher les dossiers à modifier')
        }

    }
    useEffect(() => {

        liste_site();
        liste_dossiers();
        lister_contrat();
    }, []);
    return (
        <div>
            <Header />
            <AsideBar />
            <Toast ref={toast} />
       


            <section id="main-content">
                <section class="wrapper ">
                    <div className='container-fluid mt card'>

                        <div className='row'>
                            <div className='col col-md-11'>
                                <h4 style={{ marginLeft: 10, marginTop: 20 }}>Liste des archives Dossiers  transférés</h4>
                            </div>
                            <div className='col col-md-1'>
                                <button type='button' className='btn btn-success' 
                               
                                >
                                    <i className='fa fa-refresh'></i>
                                </button>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col col-md-12'>
                                <hr style={{ width: '100%', height: 1, backgroundColor: 'red' }} />
                            </div>
                        </div>
                    
                        <div className='row'>
                            <div class="content-panel" style={{ height: '540px', position: 'relative', overflow: 'auto' }} >

                                <section id="unseen" >
                                <table class="table table-bordered table-striped table-condensed" >
                                        <thead>
                                            <tr>

                                                <th style={{ color: 'red' }}><center>#</center></th>
                                                <th style={{ color: 'red' }}>PATIENTS</th>
                                                <th style={{ color: 'red' }}>EXAMENS</th>
                                                <th style={{ color: 'red' }}>ENVOYE LE</th>
                                                <th style={{ color: 'red' }}>RADIOLOGUE</th>
                                                <th style={{ color: 'red', width: '50px' }}><center>F</center></th>
                                                <th style={{ color: 'red' }}>R</th>
                                                <th colSpan={2}></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.map((dossier, index) => (

                                                <tr key={index}>

                                                    <td><center>{index + 1}</center></td>
                                                    <td>{dossier.nom_pat}</td>
                                                    <td>{dossier.categorie_fichier}</td>
                                                    <td>{dossier.created_at}</td>
                                                    <td>{dossier.nom_medecin + " " + dossier.prenom_medecin}</td>

                                                    <td >

                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: '-15px' }}>

                                                            <span style={{ backgroundColor: dossier.nom_fichier !== null ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>

                                                            </span>
                                                            <span style={{ backgroundColor: dossier.nom_fichier_2 !==null ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute', marginLeft: '30px' }}>
                                                                
                                                            </span>
                                                        </div>


                                                    </td>
                                                    <td>

                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                                                            <span style={{ backgroundColor: 'green', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>

                                                            </span>
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <center>
                                                            <input type="checkbox" value={dossier.id} onChange={(e) => handleCheckedInput(e)} />
                                                        </center>
                                                    </td>
                                                    <td>
                                                        <center>
                                                            <i className='fa fa-hand-o-up' style={{ color: 'red' }}>
                                                            </i>
                                                        </center>
                                                    </td>

                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                    <nav style={{ marginLeft: 10 }}>
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a href='#' className='page-link'
                                                    onClick={prevPage}
                                                >Prev</a>
                                            </li>
                                            {
                                                numbers.map((n, i) => (
                                                    <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                        <a href='#' className='page-link'
                                                            onClick={() => changeCPage(n)}
                                                        >{n}</a>
                                                    </li>
                                                ))
                                            }
                                            <li className="page-item">
                                                <a href='#' className='page-link'
                                                    onClick={nextPage}
                                                >Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </section>

                            </div>

                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default ListeArchivesDossiersTransfere;