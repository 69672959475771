import React from 'react'

function WhatssapSmsTwo(props) {
    return (
        <div style={{ display: "flex", flexDirection: "column",marginLeft:"10px" }}>
            <h5>[E-METECH]- <span style={{ color: 'red', fontWeight: "bold" }}>(Urgent)</span></h5>
            <p style={{fontWeight:"bold"}}> 
            Bonjour Docteur, les examens de {props.societe} sont disponibles sur la plateforme .
            </p>
        </div>
    )
}

export default WhatssapSmsTwo
