import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { AutoComplete, Divider } from 'antd';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import Webcam from "react-webcam";
import axios from 'axios';
import { Checkbox } from "primereact/checkbox";
import { URL_AXIOS, urlBulettin } from '../../URL/URL';
import { Dialog } from 'primereact/dialog';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ThemeContext } from '../../context/ContextHeader';
import { MdAttachment } from "react-icons/md";
import Loading from './Loading';
function EditionDossiers() {
    const navigate = useNavigate();
    const location = useLocation();
    const dat = location.state?.data || [];
    const { id } = useParams();
    const { messageBeforeExpire, jourRestant } = useContext(ThemeContext);
    const [selectedSexe, setSelectedSexe] = useState(null);
    const [examens, setExamens] = useState([]);
    const [examensTwo, setExamensTwo] = useState([]);
    const [examensThree, setExamensThree] = useState([]);
    const [examensFour, setExamensFour] = useState([]);
    const [urgence, setUrgence] = useState('');
    const [ingredient, setIngredient] = useState('');
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [inc, setInc] = useState(0);
    const [increment, setIncrement] = useState([inc]);
    const [webcamCaptures, setWebcamCaptures] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleCapture, setVisibleCapture] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [siteid, setSiteid] = useState(1);
    const [membreid, setMembreid] = useState(1);
    const [saveStatus, setSaveStatus] = useState(false);
    const [siteMembre, setSiteMembre] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [medecinradio, setMedecinradio] = useState('');
    const [message_nomcomplet, setMessage_nomcomplet] = useState("");
    const [message_examen, setMessage_examen] = useState("");
    const [message_prescripteur, setMessage_prescripteur] = useState("");
    const [message_bulettin, setMessage_bulettin] = useState("");
    const [message_upload, setMessage_upload] = useState("");
    const [message_medecin, setMessage_medecin] = useState("");
    const [checkFields, setCheckFields] = useState(true);
    const [selected, setSelected] = useState([]);
    const [membres, setMembres] = useState([]);
    const [dataGet, setDataGet] = useState([]);
    const [checkStatusOfFields, setCheckStatusOfFields] = useState(false);
    const [visibleFile, setVisibleFile] = useState(false);
    const useRefInputImage = useRef(null);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const site_id = parseInt(sessionStorage.getItem("site_id"));
    // const membre_id = sessionStorage.getItem("membre_id");
    const [visibleExamen, setVisibleExamen] = useState(false);
    const fileInputRef = useRef(null);
    const [visibleProgressBar, setVisibleProgressBar] = useState(false);
    const [valueProgressBar, setValueProgressBar] = useState(0);
    const [fileNameOne, setFileNameOne] = useState("");
    const [fileNameTwo, setFileNameTwo] = useState("");
    const [FileImage, setFileImage] = useState("");
    const [membre_id, setMembre_id] = useState("");
    const [nomPatient, setNomPatient] = useState("");

    const [societe, setSociete] = useState('');
    const [selectedMessage, setSelectedMessage] = useState("");
    const [visibleMessage, setVisibleMessage] = useState(false);
    const [message_one, setMessage_one] = useState("[E-METECH]: Bonjour Docteur, un complément d'examen a été effectué sur le dossier de " + nomPatient + "(" + societe + ")");
    const [ingredientMsgOne, setIngredientMsgOne] = useState('');
    const [ingredientMsgTwo, setIngredientMsgTwo] = useState('');
    const [ingredientMsgThree, setIngredientMsgThree] = useState('');
    const [numberDoc, setNumberDoc] = useState("");
    const [boolCheck, setBoolCheck] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pathnameFile_1,setPathnameFile1]=useState("");
    const [pathnameFile_2,setPathnameFile2]=useState("");
    const [pathnameBulletin,setPathnameBulletin]=useState("");
    let tabNamePatient = [];
    const [gains, setGains] = useState([]);
    const liste_gains = async (site_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_gainsBySite/${site_id}`);
            if (response.data.status === 200) {
                setGains(response.data.gains);
                
            }
        } catch (Error) {

        }

    }


    // const liste_site_contrat_get_numberMedecin = async (id, contratid) => {
    //     try {
    //         const response = await axios.get(URL_AXIOS + `get_sites_membres_by_site_id_and_me/${id}/${contratid}`);

    //         if (response.data.status == 200) {
    //             setSociete(response.data.vue_sites_membres.societe);
    //             setNumberDoc(response.data.vue_sites_membres.telephone_membres);
    //             setMessage_one("Bonjour Docteur, un complément d'examen a été effectué sur le dossier de "+nomPatient+"("+response.data.vue_sites_membres.societe+")")

    //         }
    //     } catch (Error) {

    //     }
    // }

    const onFileCheckBoxChange = (e, i) => {

        const onChangeVal = [...dataDiv]; // Create a shallow copy of the state array
        let _FileCheckBox = onChangeVal[i].chemin_fichier_1_get; // Create a shallow copy of the specific array you want to update

        if (e.checked) {
            // onChangeVal[i].isChecked_one = true;
            _FileCheckBox = onChangeVal[i].nom_fichier;
            onChangeVal[i].statutOne = "one_file";

        } else {
            // onChangeVal[i].isChecked_one = false;
            _FileCheckBox = "";
            onChangeVal[i].statutOne = "";
        }
        onChangeVal[i].isChecked_one = e.target.checked;
        onChangeVal[i].chemin_fichier_1_get = _FileCheckBox;
        setDataDiv(onChangeVal);
    }


    const onFileCheckBoxChange_2 = (e, i) => {
        const onChangeVal = [...dataDiv]; // Create a shallow copy of the state array
        let _FileCheckBox = onChangeVal[i].chemin_fichier_2_get; // Create a shallow copy of the specific array you want to update

        if (e.checked) {
            // onChangeVal[i].isChecked_two = true;
            _FileCheckBox = onChangeVal[i].nom_fichier_2;
            onChangeVal[i].statutTwo = "two_files";

        } else {
            // onChangeVal[i].isChecked_two = false;
            _FileCheckBox = "";
            onChangeVal[i].statutTwo = "";
        }
        onChangeVal[i].isChecked_two = e.target.checked;
        onChangeVal[i].chemin_fichier_2_get = _FileCheckBox;
        setDataDiv(onChangeVal);
    }

    const onFileAdd = (e, i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].isChecked_add = e.target.checked;
        onChangeVal[i].isChecked_two = e.target.checked;
        onChangeVal[i].statutTwo = "two_files";
        setDataDiv(onChangeVal);
    }

    const onFileAddBoth = (e, i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].isChecked_add_both = e.target.checked;
        onChangeVal[i].statutAddBoth = "both_files";
        setDataDiv(onChangeVal);
    }
    const fetchMembres = async (site_id) => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeManipulateurBySite/${site_id}`);
            if (response.data.status === 200) {
                const mappedMembres = response.data.vue_manipulateurs_site.map((item) => ({
                    label: item.nom + ' ' + (item.prenom ? item.prenom:""),
                    value: item.id_mani_site
                }));
                setMembres(mappedMembres);
            }
        } catch (error) {
            console.error("Error fetching membres:", error);
        }
    };
    const openFolderPickFileImage = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].imgScr_cap = "";
        setDataDiv(onChangeVal);
        useRefInputImage.current.click();
    }
    const lister_load_data_dossiersSimple = async (id) => {
        try {
            const newDataDiv = []; // Create a new array to store updated data

            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_dossiersId/${id}`);
            if (response.data.status === 200) {

                const element = response.data.dossier_send;

                setLoading(false);
                let total = 0;
                let dataFile = urlBulettin + element.chemin_fichier;
                setFileNameOne(element.nom_fichier);
                setFileNameTwo(element.nom_fichier_2);
                setFileImage(urlBulettin + element.chemin_bulletin);
                setMembre_id(element.membre_id);
                setNomPatient(element.nom_pat);
                setSociete(element.societe);
                setNumberDoc(element.tel_medecin);
                setMessage_one("[E-METECH]: Bonjour Docteur, un complément d'examen a été effectué sur le dossier de " + element.nom_pat + "(" + element.societe + ")")
                setPathnameFile1(element.chemin_fichier);
                setPathnameFile2(element.chemin_fichier_2);
                setPathnameBulletin(element.chemin_bulletin);

                tabNamePatient.push(element.nom_pat);
                const updatedData = {
                    id: element.id,
                    nom_patient: element.nom_pat,
                    sexe: element.sexe_pat,
                    libelle_age: element.libelle_age_pat,
                    observation: element.observation,
                    categorie_fichier: element.categorie_fichier,
                    prescripteur: element.prescripteur,
                    etat: parseInt(element.etat),
                    imgSrc: urlBulettin + element.chemin_bulletin,
                    uploadedFile: [],
                    bool: false,
                    bool_obs: false,
                    age: element.age_pat,
                    message_nomcomplet: '',
                    bool_msg_nomcomplet: false,
                    message_categorie_fichier: '',
                    bool_msg_categorie_fichier: false,
                    message_prescripteur: '',
                    bool_msg_prescripteur: false,
                    message_imgSrc: '',
                    bool_msg_imgSrc: false,
                    message_uploadedFile: '',
                    bool_msg_uploadedFile: false,
                    totalSize: total,
                    selected: [],
                    message_mani: '',
                    bool_mani: false,
                    statutImg: 0,
                    checkFields: '',
                    societe: element.societe,
                    filegetName: urlBulettin + element.chemin_bulletin,
                    chemin_bulletin: element.chemin_bulletin,
                    bulletin_examen: element.bulletin_examen,
                    nom_fichier_1: element.nom_fichier,
                    nom_fichier_2: element.nom_fichier_2,
                    chemin_fichier_1: element.chemin_fichier,
                    chemin_fichier_2: element.chemin_fichier_2,
                    nom_manipulateur: element.nom_manipulateur,
                    chemin_fichier_1_get: element.chemin_fichier,
                    chemin_fichier_2_get: element.chemin_fichier_2,
                    chemin_bulletin_examen_get: element.chemin_bulletin,
                    isChecked_one: false,
                    isChecked_two: false,
                    isChecked_add: false,
                    isChecked_add_both: false,
                    statutOne: '',
                    statutTwo: '',
                    statutAddBoth: '',
                    length_files: 0,
                };
                // Process uploaded files and calculate total size


                newDataDiv.push(updatedData);
            }
            setDataDiv(newDataDiv);

        } catch (error) {
            alert("erreur frontend: " + error.message);
        }
    };
    const lister_load_data_dossiers = async () => {
        try {
            const newDataDiv = []; // Create a new array to store updated data
            const formDataObject = {};
            for (let index = 0; index < dat.length; index++) {

                formDataObject[`id_${index}`] = dat[index];
            }
            const response = await axios.get(URL_AXIOS + 'liste_dossiers_load', {
                params: { dat: formDataObject }
            });
            if (response.data.status === 200) {

                const data = response.data.dossiersToSend;

                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    let total = 0;
                    let dataFile = urlBulettin + element.chemin_fichier;
                    setFileNameOne(element.nom_fichier);
                    setFileNameTwo(element.nom_fichier_2);
                    setFileImage(urlBulettin + element.chemin_bulletin);
                    setMembre_id(element.membre_id);
                    setNomPatient(element.nom_pat)
                    tabNamePatient.push(element.nom_pat);
                    const updatedData = {
                        id: element.id,
                        nom_patient: element.nom_pat,
                        sexe: element.sexe_pat,
                        libelle_age: element.libelle_age_pat,
                        observation: element.observation,
                        categorie_fichier: element.categorie_fichier,
                        prescripteur: element.prescripteur,
                        etat: parseInt(element.etat),
                        imgSrc: urlBulettin + element.chemin_bulletin,
                        uploadedFile: [],
                        bool: false,
                        bool_obs: false,
                        age: element.age_pat,
                        message_nomcomplet: '',
                        bool_msg_nomcomplet: false,
                        message_categorie_fichier: '',
                        bool_msg_categorie_fichier: false,
                        message_prescripteur: '',
                        bool_msg_prescripteur: false,
                        message_imgSrc: '',
                        bool_msg_imgSrc: false,
                        message_uploadedFile: '',
                        bool_msg_uploadedFile: false,
                        totalSize: total,
                        selected: [],
                        message_mani: '',
                        bool_mani: false,
                        statutImg: 0,
                        checkFields: '',
                        societe: element.societe,
                        filegetName: urlBulettin + element.chemin_bulletin,
                        chemin_bulletin: element.chemin_bulletin,
                        bulletin_examen: element.bulletin_examen,
                        nom_fichier_1: element.nom_fichier,
                        nom_fichier_2: element.nom_fichier_2,
                        chemin_fichier_1: element.chemin_fichier,
                        chemin_fichier_2: element.chemin_fichier_2,
                        nom_manipulateur: element.nom_manipulateur,
                        chemin_fichier_1_get: element.chemin_fichier,
                        chemin_fichier_2_get: element.chemin_fichier_2,
                        isChecked_one: false,
                        isChecked_two: false,
                        isChecked_add: false,
                        isChecked_add_both: false,
                        statutOne: '',
                        statutTwo: '',
                        statutAddBoth: '',
                        length_files: 0,
                    };
                    // Process uploaded files and calculate total size


                    newDataDiv.push(updatedData);
                }
                setDataDiv(newDataDiv);
            }
        } catch (error) {
            alert("erreur frontend: " + error.message);
        }
    };
    const [dataDiv, setDataDiv] = useState([
        {
            nom_patient: '',
            sexe: '',
            libelle_age: 'an(s)',
            observation: '',
            categorie_fichier: '',
            prescripteur: '',
            etat: 0,
            imgSrc: '',
            imgScr_cap: '',
            uploadedFile: [],
            bool: false,
            bool_obs: false,
            age: '',
            message_age: '',
            bool_msg_age: false,
            message_sexe: '',
            bool_msg_sexe: false,
            message_nomcomplet: '',
            bool_msg_nomcomplet: false,
            message_categorie_fichier: '',
            bool_msg_categorie_fichier: false,
            message_prescripteur: '',
            bool_msg_prescripteur: false,
            message_imgSrc: '',
            bool_msg_imgSrc: false,
            message_uploadedFile: '',
            bool_msg_uploadedFile: false,
            totalSize: 0,
            selected: [],
            message_mani: '',
            bool_mani: false,
            filehelp: '',
            bool_examen: false,
            examen_modalite: '',
            examen_one: '',
            examen_two: '',
            examen_three: '',
            examen_four: '',
            examen_one_bool: true,
            examen_two_bool: true,
            examen_three_bool: true,
            examen_four_bool: true,
            categorie_fichier_one: '',
            message_error: "",
            site_id: site_id,
            tabExamen: [],
            categorie_fichier_save: '',
            bool_large: false,
            msg_examen: "",
            length_files: 0,
            isChecked_one: false,
            isChecked_two: false,
            isChecked_add: false,
            isChecked_add_both: false,
            statutOne: '',
            statutTwo: '',
            statutAddBoth: '',
            chemin_fichier_1_get:"",
            chemin_fichier_2_get:"",
            chemin_bulletin_examen_get:"",

        }
    ]);



    const handleIncrement = () => {
        setDataDiv([...dataDiv, {
            nom_patient: '',
            sexe: '',
            libelle_age: '',
            observation: '',
            categorie_fichier: '',
            prescripteur: '',
            etat: 0,
            imgSrc: '',
            imgScr_cap: '',
            uploadedFile: [],
            bool: false,
            bool_obs: false,
            age: '',
            message_age: '',
            bool_msg_age: false,
            message_sexe: '',
            bool_msg_sexe: false,
            message_nomcomplet: '',
            bool_msg_nomcomplet: false,
            message_categorie_fichier: '',
            bool_msg_categorie_fichier: false,
            message_prescripteur: '',
            bool_msg_prescripteur: false,
            message_imgSrc: '',
            bool_msg_imgSrc: false,
            message_uploadedFile: '',
            bool_msg_uploadedFile: false,
            totalSize: 0,
            selected: [],
            message_mani: '',
            bool_mani: false,
            filehelp: '',
            bool_examen: false,
            examen_modalite: '',
            examen_one: '',
            examen_two: '',
            examen_three: '',
            examen_four: '',
            examen_one_bool: false,
            examen_two_bool: false,
            examen_three_bool: true,
            examen_four_bool: true,
            categorie_fichier_one: '',
            message_error: "",
            site_id: site_id,
            tabExamen: [],
            categorie_fichier_save: '',
            bool_large: false,
            msg_examen: "",
            length_files: 0,
            statutOne: '',
            statutTwo: '',
            statutAddBoth: '',
        }]);


    }

    const captureindex = (i) => {
        try {
            const imageSrc = webcamRef.current.getScreenshot();
            const newDataDiv = [...dataDiv];
            newDataDiv[i].imgSrc = imageSrc;
            newDataDiv[i].bool_large = false;
            newDataDiv[i].imgScr_cap = imageSrc;
            newDataDiv[i].statutImg = 1;
            if (newDataDiv[i].imgSrc.length === 0) {
                newDataDiv[i].message_imgSrc = "Veuillez capturer une photo ";
                newDataDiv[i].bool_msg_imgSrc = true;
            } else if (newDataDiv[i].imgSrc.length > 0) {
                newDataDiv[i].message_imgSrc = '';
                newDataDiv[i].bool_msg_imgSrc = false;
            }
            setDataDiv(newDataDiv);
        } catch (error) {

        }

    };
    const deleteDiv = (i) => {
        try {
            const deletedata_div = [...dataDiv];
            deletedata_div.splice(i, 1);
            setDataDiv(deletedata_div);
        } catch (error) {

        }

    }

    const handleChange = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i][name] = value;
        setDataDiv(onChangeVal);
    }

    const handleChangeNomcomplet = (e, i) => {
        const { value } = e.target;
        const onChangeVal = [...dataDiv];
        setNomPatient(value);
        onChangeVal[i].nom_patient = value;
        if (onChangeVal[i].nom_patient === "") {
            onChangeVal[i].message_nomcomplet = "Veuillez saisir le nom complet svp";
            onChangeVal[i].bool_msg_nomcomplet = true;
        } else if (onChangeVal[i].nom_patient.length > 0) {
            onChangeVal[i].message_nomcomplet = '';
            onChangeVal[i].bool_msg_nomcomplet = false;
        }

        setDataDiv(onChangeVal);
    }

    const handleChangePrescripteur = (e, i) => {
        const { value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].prescripteur = value;
        setDataDiv(onChangeVal);
    }
    const handleChangeHelpFile = (e, i) => {
        const file = e.target.files[0];
        //setSelectedImage(URL.createObjectURL(file));
        const onChangeVal = [...dataDiv];
        if (onChangeVal[i].imgSrc !== null || onChangeVal[i].imgSrc !== "") {
            onChangeVal[i].statutImg = 1;
            onChangeVal[i].imgSrc = URL.createObjectURL(file);
            setDataDiv(onChangeVal);
            setVisibleFile(false)
        }



    }
    const handleChangeImgSrc = (e, i) => {
        const { value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].imgSrc = value;


        if (onChangeVal[i].imgSrc.length === 0) {
            onChangeVal[i].message_imgSrc = "Veuillez capturer une photo ";
            onChangeVal[i].bool_msg_imgSrc = true;
        } else if (onChangeVal[i].imgSrc.length > 0) {
            onChangeVal[i].message_imgSrc = '';
            onChangeVal[i].bool_msg_imgSrc = false;
        }

        setDataDiv(onChangeVal);
    }




    const handleChangeCatExa = (value, i) => {
        //const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].categorie_fichier = value;
        onChangeVal[i].categorie_fichier_save = onChangeVal[i].categorie_fichier;
        if (onChangeVal[i].categorie_fichier === "") {
            onChangeVal[i].message_categorie_fichier = "Veuillez saisir l'examen";
            onChangeVal[i].bool_msg_categorie_fichier = true;
        } else if (onChangeVal[i].categorie_fichier.length > 0) {
            onChangeVal[i].message_categorie_fichier = '';
            onChangeVal[i].categorie_fichier_one = "";
            onChangeVal[i].categorie_fichier_save = onChangeVal[i].categorie_fichier;
            onChangeVal[i].bool_msg_categorie_fichier = false;
        }
        setDataDiv(onChangeVal);
    }
    const handleVisible = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool = true;
        setDataDiv(onChangeVal);
    }
    const handleVisibleCapu = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_large = true;
        setDataDiv(onChangeVal);
    }
    const handleVisibleCapuClose = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_large = false;
        setDataDiv(onChangeVal);
    }
    const handleVisible_examen = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_examen = true;
        setDataDiv(onChangeVal);
    }
    const handleVisible_obs = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_obs = true;
        setDataDiv(onChangeVal);
    }
    const handleClose = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool = false;
        setDataDiv(onChangeVal);
    }
    const handleClose_obs = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_obs = false;
        setDataDiv(onChangeVal);
    }

    const handleClose_examen = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_examen = false;
        setDataDiv(onChangeVal);
    }

    const add_Observation = (value, i) => {

        const onChangeVal = [...dataDiv];
        onChangeVal[i].observation = value;
        onChangeVal[i].bool_obs = false;
        setDataDiv(onChangeVal);

    }

    const handleZoomIn = (e) => {
        e.preventDefault();
        setZoomLevel(prevZoom => prevZoom + 0.1);
    };


    const handleZoomOut = (e) => {
        e.preventDefault();
        setZoomLevel(prevZoom => (prevZoom - 0.1 <= 0 ? 0.1 : prevZoom - 0.1));
    };

    const data = examens.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));
    const data_two = examensTwo.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));
    const data_three = examensThree.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));

    const data_four = examensFour.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));


    //FIN DELARATION


    //WebCan 
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [mirrored, setMirrored] = useState(false);
    const retake = (e, index) => {
        e.preventDefault();
        const newDataDiv = [...dataDiv];
        newDataDiv[index].imgSrc = '';
        newDataDiv[index].imgScr_cap = '';
        setDataDiv(newDataDiv);
    };

    const capture = useCallback((e, index) => {
        e.preventDefault();
        const imageSrc = webcamRef.current.getScreenshot();
        const newDataDiv = [...dataDiv];
        newDataDiv[index].imgSrc = imageSrc;
        setDataDiv(newDataDiv);
    }, [webcamRef]);


    const onSelect = async (e, index) => {
        try {
            let _totalSize = 0;
            const select_files = e.files;
            const newDataDiv = [...dataDiv];

            if (select_files.length === 1) {
                newDataDiv[index].length_files = 1;
            } else if (select_files.length === 2) {
                newDataDiv[index].length_files = 2;
            }
            if (select_files.length > 2) {
                fileUploadRef.current.clear();
                newDataDiv[index].totalSize = _totalSize;
                newDataDiv[index].message_uploadedFile = "Veuillez saisir sélectionner un ou deux examens seulement !";
                newDataDiv[index].bool_msg_uploadedFile = true;
            } else {
                for (const data_v of select_files) {
                    newDataDiv[index].uploadedFile.push(data_v);
                    _totalSize += data_v.size;

                }

                newDataDiv[index].totalSize = _totalSize;
                newDataDiv[index].message_uploadedFile = "";
                newDataDiv[index].bool_msg_uploadedFile = false;

            }
            // if (newDataDiv[index].totalSize === 0) {

            // } else if (newDataDiv[index].totalSize > 0) {


            // }

            setDataDiv(newDataDiv);

        } catch (error) {
            // Handle errors
            //console.error("Error:", error);
        }
    };

    const resizeImageToA4 = async (imageUrl) => {
        // Load the image
        const img = new Image();
        img.src = imageUrl;

        // Create a canvas element
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Wait for the image to load
        await new Promise((resolve) => {
            img.onload = resolve;
        });

        // Set canvas dimensions to A4 size in pixels (assuming 96 DPI)
        canvas.width = 794; // 210mm converted to pixels (210 * 96 / 25.4)
        canvas.height = 1123; // 297mm converted to pixels (297 * 96 / 25.4)

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert canvas content to a Blob
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg');
        });
    };

    const uploadFiles = async (formDataObject) => {
        try {
            const response = await axios.post(URL_AXIOS + 'update_upload_files', formDataObject, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: ({ loaded, total }) => {
                    const value_loaded = Math.floor((loaded / total) * 100);
                    setValueProgressBar(value_loaded);
                    setVisibleProgressBar(true);
                    if (loaded === total) {
                        setVisibleProgressBar(false);
                    }
                }
            });

            if (response.status === 200) {
                Swal.fire({
                    title: "Réussi!",
                    text: "Les dossiers ont été modifiés avec succès !",
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        QuestionNotification();
                    }
                });
                // Optionally navigate to another page
                // navigate('/resultat_transfere');
            } else {
                Swal.fire({
                    title: "Erreur",
                    text: "Il y a eu un problème lors de la modification des dossiers.",
                    icon: "error"
                });
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi des fichiers:", error);
            Swal.fire({
                title: "Erreur",
                text: "Il y a eu un problème lors de l'envoi des fichiers.",
                icon: "error"
            });
        }
    };

    
    const add_folder = async (e) => {
        e.preventDefault();
        try {

            const formDataObject = {};

            for (const [index, dataItem] of dataDiv.entries()) {
                formDataObject[`nom_patient_${index}`] = dataItem.nom_patient;
                formDataObject[`categorie_fichier_${index}`] = dataItem.categorie_fichier;
                formDataObject[`statutImg_${index}`] = dataItem.statutImg;

                if (dataItem.statutImg === 1) {
                    const blob = await resizeImageToA4(dataItem.imgSrc);
                    // const blob = await fetch(dataItem.imgSrc).then((res) => res.blob());
                    const imageFile = new File([blob], `image_${index}.jpg`, { type: 'image/jpeg' });

                    formDataObject[`file_${index}`] = imageFile;
                }

                formDataObject[`id_${index}`] = dataItem.id;
                formDataObject[`sexe_${index}`] = dataItem.sexe;
                formDataObject[`age_${index}`] = dataItem.age;
                formDataObject[`etat_${index}`] = dataItem.etat;
                formDataObject[`libelle_age_${index}`] = dataItem.libelle_age;
                formDataObject[`prescripteur_${index}`] = dataItem.prescripteur;
                formDataObject[`observation_${index}`] = dataItem.observation;
                formDataObject[`societe_${index}`] = dataItem.societe;
                formDataObject[`bulletin_examen_${index}`] = dataItem.bulletin_examen;
                formDataObject[`chemin_bulletin_${index}`] = dataItem.chemin_bulletin;
                formDataObject[`file_zip_${index}`] = dataItem.uploadedFile;
                formDataObject[`statutFile_${index}`] = dataItem.checkFields;
                formDataObject[`nom_fichier_${index}`] = dataItem.nom_fichier_1;
                formDataObject[`nom_fichier_2_${index}`] = dataItem.nom_fichier_2;
                formDataObject[`chemin_fichier_${index}`] = dataItem.chemin_fichier;
                formDataObject[`chemin_fichier_2_${index}`] = dataItem.chemin_fichier_2_get;
                formDataObject[`manipulateur_id_${index}`] = dataItem.selected;
                formDataObject[`isChecked_one_${index}`] = dataItem.isChecked_one;
                formDataObject[`isChecked_two_${index}`] = dataItem.isChecked_two;
                formDataObject[`isChecked_add_both_${index}`] = dataItem.isChecked_add_both;
                formDataObject[`chemin_fichier_1_get_${index}`] = pathnameFile_1;
                formDataObject[`chemin_fichier_2_get_${index}`] = pathnameFile_2;
                formDataObject[`chemin_bulletin_examen_get_${index}`]=pathnameBulletin;
                formDataObject[`length_files_${index}`] = dataItem.length_files;
                formDataObject[`statutOne_${index}`] = dataItem.statutOne;
                formDataObject[`statutTwo_${index}`] = dataItem.statutTwo;
                formDataObject[`statutAddBoth_${index}`] = dataItem.statutAddBoth;
                if ((dataItem.isChecked_one || dataItem.isChecked_two || dataItem.isChecked_add_both) && dataItem.uploadedFile.length === 0) {


                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Veuillez décocher l'option ajout fichier si vous n'avez pas de fichiers à envoyer",
                        showConfirmButton: false,
                        timer: 2500
                    });
                    setVisibleProgressBar(false);
                } else if ((dataItem.isChecked_one || dataItem.isChecked_two || dataItem.isChecked_add_both) && dataItem.uploadedFile.length > 0) {
                    setBoolCheck(false);
                    await uploadFiles(formDataObject);

                } else if ((!dataItem.isChecked_one && !dataItem.isChecked_two && !dataItem.isChecked_add_both) && dataItem.uploadedFile.length === 0) {
                    setBoolCheck(false);
                    await uploadFiles(formDataObject);
                }


            }



        } catch (error) {
            console.error("Erreur ajouter", error);

        }
    };


    const QuestionNotification = () => {
        try {
            Swal.fire({
                title: "Question",
                text: "Voulez-vous notifier le médécin ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {

                    setVisibleMessage(true)
                } else {
                    setVisibleMessage(false)
                    navigate('/resultat_transfere')
                }

            });
        } catch (error) {

        }
    }

    const confirmCreateFolder = (e) => {
        try {
            let isValid = true;
            Swal.fire({
                title: "Question",
                text: "Êtes-vous sûr de modifier ce(s) dossier(s) ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {
                    for (const [index, dataItem] of dataDiv.entries()) {
                        if (!dataItem.nom_patient) {
                            dataItem.message_nomcomplet = "Veuillez saisir le nom complet svp";
                            dataItem.bool_msg_nomcomplet = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_nomcomplet = false;
                            dataItem.message_nomcomplet = "";
                        }
                        if (!dataItem.categorie_fichier && !dataItem.categorie_fichier_one) {
                            dataItem.message_categorie_fichier = "Veuillez saisir l'examen";
                            dataItem.bool_msg_categorie_fichier = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_categorie_fichier = false;
                            dataItem.message_categorie_fichier = "";
                        }
                        if (!dataItem.age) {
                            dataItem.message_age = "Veuillez saisir l'âge";
                            dataItem.bool_msg_age = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_age = false;
                            dataItem.message_age = "";
                        }
                        // if (dataItem.selected.length === 0) {
                        //     dataItem.message_mani = "Veuillez sélectionner les manipulateurs";
                        //     dataItem.bool_mani = true;
                        //     isValid = false;
                        // } else {
                        //     dataItem.bool_mani = false;
                        //     dataItem.message_mani = "";
                        // }
                        if (!dataItem.imgSrc && !dataItem.filehelp) {
                            dataItem.message_imgSrc = "Veuillez capturer une photo";
                            dataItem.bool_msg_imgSrc = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_imgSrc = false;
                            dataItem.message_imgSrc = "";
                        }

                        if (!dataItem.sexe) {
                            dataItem.message_sexe = "Veuillez saisir sexe";
                            dataItem.bool_msg_sexe = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_sexe = false;
                            dataItem.message_sexe = "";
                        }
                    }
                    if (isValid) {
                        setVisibleProgressBar(true);
                        add_folder(e);
                    } else {

                    }
                    //handleEmptyFields()

                }

            });
        } catch (error) {

        }
    }


    const onTemplateUpload = (e, i) => {
        const newDataDiv = [...dataDiv];
        let _totalSize = 0;
        newDataDiv[i].uploadedFile.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };
    const onTemplateRemove = (file, callback, index) => {
        const newDataDiv = [...dataDiv];

        let totalSize_data = 0;
        let data_value = 0;
        let result_data = 0;
        totalSize_data = newDataDiv[index].totalSize;
        result_data = totalSize_data - file.size;
        newDataDiv[index].totalSize = result_data;

        const newDataDivUpload = [...newDataDiv[index].uploadedFile];
        for (let i = 0; i < newDataDivUpload.length; i++) {
            const element = newDataDivUpload[i];
            newDataDivUpload.splice(i, 1); // Remove the element at index i
            break;
        }
        newDataDiv[index].uploadedFile = newDataDivUpload; // Move this line outside the loop
        setDataDiv(newDataDiv);
        callback();
    };

    const onTemplateClear = (index) => {

        const newDataDiv = [...dataDiv];
        newDataDiv[index].totalSize = 0;
        newDataDiv[index].uploadedFile = [];
        setDataDiv(newDataDiv);
    };
    const headerTemplate = (options, index) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const newDataDiv = [...dataDiv];
        const value = newDataDiv[index].totalSize / 500000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(value) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'left' }}>
                {/* {(newDataDiv[index].checkFields !== "A" && newDataDiv[index].checkFields !== "R") ? '' : chooseButton}
                {(newDataDiv[index].checkFields !== "A" && newDataDiv[index].checkFields !== "R") ? '' : cancelButton} */}

                {(newDataDiv[index].isChecked_one == true || newDataDiv[index].isChecked_two == true || newDataDiv[index].isChecked_add == true || newDataDiv[index].isChecked_add_both == true) ? chooseButton : ''}
                {(newDataDiv[index].isChecked_one == true || newDataDiv[index].isChecked_two == true || newDataDiv[index].isChecked_add == true || newDataDiv[index].isChecked_add_both == true) ? cancelButton : ''}
                <div className="flex align-items-center gap-3 ml-auto">

                    <span>{formatFileSize(newDataDiv[index].totalSize)} / 5 GB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };



    const itemTemplate = (file, props, index) => {

        return (
            <div className="flex align-items-center flex-wrap" >

                <div className="flex align-items-center" style={{ width: '40%', height: '10px' }}>

                    <i className='fa fa-file' style={{ fontSize: 20 }}>&nbsp;</i>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}

                        &nbsp;&nbsp;<small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove, index)} />

            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{ height: "108px" }}>
                <i className="pi pi-file mt-3 p-5" style={{ fontSize: '2em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Veuillez déposer vos fichiers ici (Deux maximum)
                </span>
            </div>
        );
    };
    const chooseOptions = {
        icon: 'pi pi-fw pi-paperclip',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined',
    };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    const formatFileSize = (bytes, decimalPoint) => {
        if (bytes === 0) return '0 Bytes';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }





    const liste_examens = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_examen');
            if (response.data.status == 200) {
                setExamens(response.data.examens);
            }
        } catch (Error) {

        }
    }
    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + `ListeMembreBySite/${site_id}`);
            if (response.data.status === 200) {
                setSiteMembre(response.data.data_membres_sites);

            }
        } catch (Error) {

        }
    };
    const handleChangeAge = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i][name] = value;
        onChangeVal[i].message_age = '';
        onChangeVal[i].bool_msg_age = false;
        setDataDiv(onChangeVal);
    }

    const handleChangeSexe = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i][name] = value;
        onChangeVal[i].message_sexe = '';
        onChangeVal[i].bool_msg_sexe = false;
        setDataDiv(onChangeVal);
    }
    const handleMultiSelectChange = (selected, index) => {

        const newDataDiv = [...dataDiv];
        newDataDiv[index].selected = selected;
        console.log(selected)
        if (newDataDiv[index].selected.length === 0) {
            newDataDiv[index].message_mani = "Veuillez sélectionner le manipulateur";
            newDataDiv[index].bool_mani = true;
        } else if (newDataDiv[index].selected.length > 0) {
            newDataDiv[index].message_mani = '';
            newDataDiv[index].bool_mani = false;
        }
        setDataDiv(newDataDiv);
    };
    const ListeMedecinsBySite = () => {
        return (
            <div style={{ height: 'auto', backgroundColor: '#057FB2', width: 'auto', position: 'absolute' }}>

                {siteMembre.map((sitemembre) => {
                    return (
                        <ul className='navbar navbar-nav' style={{ padding: 5 }}>
                            <div className="flex align-items-center" key={sitemembre.id}>
                                <RadioButton inputId={`sitemembre_${sitemembre.id}`} name={sitemembre.id} value={sitemembre.id} onChange={(e) => setMedecinradio(e.value)} checked={medecinradio === sitemembre.id} />
                                <label htmlFor={`sitemembre_${sitemembre.id}`} style={{ color: '#fff', fontWeight: 'bolder', marginLeft: 10 }} className="ml-2">{sitemembre.nom + " " + sitemembre.prenom}</label>

                            </div>

                        </ul>

                    );
                })}

            </div>

        )
    }

    const countryTemplate = (option) => {
        return (
            <div className="flex">
                <h3 style={{ color: 'black', backgroundColor: 'red' }}>{option.name}</h3>
            </div>
        );
    };

    const WhatssappButton = () => {

        const phoneNumber = numberDoc;
        let defaultMessage = 'Salut! Comment pouvons-nous vous aider?';
        let messageDef = "Bonjour Docteur, un complément d'examen a été effectué sur le dossier de " + nomPatient + "(" + societe + ")";
        if (selectedMessage !== "") {
            defaultMessage = selectedMessage;
        } else {

        }

        const encodedMessage = encodeURIComponent(messageDef);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        return (
            <a href={whatsappURL} className='btn btn-info form-control' onClick={() => navigate("/resultat_transfere")} target="_blank" rel="noopener noreferrer">
                <i className="icofont-whatsapp mr-2"></i>
                <span>Envoyer</span>
            </a>
        );
    };

    const closeVisible = () => {
        setVisibleMessage(false);
        navigate('/resultat_transfere');
    }
    useEffect(() => {
        setMessage_one("[E-METECH]: Bonjour Docteur, un complément d'examen a été effectué sur le dossier de " + nomPatient + "(" + societe + ")");
    }, [societe, nomPatient])
    useEffect(() => {

        // liste_examens();
        lister_contrat();
        fetchMembres(site_id);
        liste_gains(site_id);
        lister_load_data_dossiersSimple(id);
        // liste_site_contrat_get_numberMedecin(site_id, membre_id)
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [id, site_id]);


    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <Dialog header="Messages" visible={visibleMessage} style={{ width: '50vw' }} onHide={() => closeVisible()}>
                <div style={{ display: "flex" }}>
                    <RadioButton inputId="ingredient1" name="pizza" value={message_one} onChange={(e) => setSelectedMessage(e.value)} checked={selectedMessage === message_one} />
                    <p style={{ marginLeft: 10 }}>
                        [E-METECH] Bonjour Docteur, un complément d'examen a été effectué sur le dossier de {nomPatient} ({societe}).
                    </p>
                </div>


                {WhatssappButton()}
            </Dialog>
            <Dialog header="Envoi en cours..." visible={visibleProgressBar} draggable={false} modal={true} style={{ width: '50vw' }} closable={false} onHide={() => setVisibleProgressBar(false)}>
                <ProgressBar value={valueProgressBar}></ProgressBar>
            </Dialog>
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper ">
                    <div className='row' style={{ marginLeft: "2px" }}>
                        {/* <div className='col col-md-12 ' >
                            <div className='alert alert-info' style={{ marginTop: 10, color: 'black', fontWeight: 'bold' }}>
                                Vous pouvez que remplacer deux fichiers au même moment
                            </div>
                        </div> */}
                        {/* <div class="col-md-1" style={{ marginTop: 15 }}>

                            <button type='submit' onClick={(e) => add_folder(e)} class="btn btn-warning" style={{ marginTop: '5px', float: 'right', marginLeft: 10 }} title="Transferer le dossier">
                                <i class=" fa fa-upload"></i>
                            </button>
                            <button class="btn btn-primary" type="button" style={{ marginTop: '5px', marginLeft: 10, display: 'none' }} >
                                <i class="fa fa-users"></i>
                            </button>

                        </div> */}
                    </div>
                    {
                        dataDiv.map((val, index) => (
                            <div className='container-fluid card' style={{ height: "auto" }}>
                                {index > 0 && <div className='row' style={{ height: "10px", backgroundColor: 'white' }}><button onClick={() => deleteDiv(index)} class="btn btn-danger" type="button" style={{ marginTop: '1px', float: 'right', fontSize: 12 }}><i class="now-ui-icons ui-1_simple-remove fa fa-times"></i></button></div>}
                                <div className='row ' style={{ height: "auto" }}>
                                    <div className='col col-md-4' style={{ padding: 15 }}>
                                        <div className='row'>

                                            <div className='col col-md-12'>

                                                <Dropdown value={val.categorie_fichier}
                                                    name='categorie_fichier'
                                                    onChange={(e) => handleChangeCatExa(e.value, index)}
                                                    options={gains}
                                                    optionLabel="categorie_fichier"
                                                    optionValue="categorie_fichier"
                                                    style={{ fontWeight: 'bold' }}
                                                    placeholder="Sélectionnez examen *"
                                                    filter className="w-full " />
                                                {val.bool_msg_categorie_fichier && <label style={{ color: 'red' }}>{val.message_categorie_fichier}</label>}



                                                {/* <Dropdown value={val.categorie_fichier}
                                                    name='categorie_fichier'
                                                    onChange={(e) => handleChangeCatExa(e.value, index)}
                                                    options={gains} optionLabel="categorie_fichier" optionValue='categorie_fichier'
                                                    style={{ fontWeight: 'bold' }}
                                                    placeholder="Sélectionnez examen *"
                                                    filter className="w-full " />
                                                {val.bool_msg_categorie_fichier && <label style={{ color: 'red' }}>{val.message_categorie_fichier}</label>} */}

                                            </div>
                                            {/* <div className='col col-md-2'>
                                                <button type='button'
                                                    style={{ marginLeft: "12px" }}
                                                    className='btn btn-primary'
                                                    onClick={() => handleVisible_examen(index)}
                                                    title='Créer un examen'
                                                >
                                                    <i className='fa fa-plus'></i>
                                                </button>

                                                <Dialog header="Ajout examen" visible={val.bool_examen} onChange={(e) => handleChange(e, index)} style={{ width: '30vw' }} onHide={() => handleClose_examen(index)}>
                                                    <div className="flex flex-column">
                                                        {val.message_error != "" && <div class="flex ">
                                                            <div className='alert alert-danger'>{val.message_error}</div>
                                                        </div>}
                                                        <div class="flex ">
                                                            <select className='form-control' value={val.modalite} name="modalite"
                                                                style={{ fontWeight: 'bold' }}
                                                                onChange={(e) => handleModalite(e, index)}>
                                                                <option style={{ fontWeight: 500 }} value={""}>Modalité</option>
                                                                <option style={{ fontWeight: 500 }} value={"SCANNER"}>SCANNER</option>
                                                                <option style={{ fontWeight: 500 }} value={"IRM"}>IRM</option>
                                                                <option style={{ fontWeight: 500 }} value={"RX"}>RADIOGRAPHIE</option>
                                                                <option style={{ fontWeight: 500 }} value={"ECHOGRAPHIE"}>ECHOGRAPHIE</option>
                                                            </select>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center  ">
                                                            <h5>+</h5>
                                                        </div>
                                                        <div className="flex ">
                                                            <Dropdown value={val.examen_one}
                                                                name='examen_one'
                                                                onChange={(e) => handleExamen_one(e, index)}
                                                                options={examens} optionLabel="libelle_exam" optionValue='libelle_exam'
                                                                placeholder="Sélectionnez examen 1 *"
                                                                filter className="w-full " />


                                                        </div>
                                                        <div className="flex align-items-center justify-content-center  ">
                                                            <h5>+</h5>
                                                        </div>
                                                        <div className="flex ">
                                                            <Dropdown
                                                                value={val.examen_two}
                                                                name='examen_two'
                                                                onChange={(e) => handleExamen_two(e, index)}
                                                                options={data_two}
                                                                optionLabel="label"
                                                                optionValue='label'
                                                                placeholder="Sélectionnez examen 2"
                                                                filter className="w-full "
                                                                onSelect={(e) => onSelectExamenTwo(e, index)}
                                                                disabled={val.examen_two_bool}

                                                            />
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center  ">
                                                            <h5>+</h5>
                                                        </div>
                                                        <div className="flex ">
                                                            <Dropdown
                                                                value={val.examen_three}
                                                                name='examen_three'
                                                                onChange={(e) => handleExamen_three(e, index)}
                                                                options={data_three}
                                                                optionLabel="label"
                                                                optionValue='label'
                                                                placeholder="Sélectionnez examen 3"
                                                                filter className="w-full "
                                                                onSelect={(e) => onSelectExamenThree(e, index)}
                                                                disabled={val.examen_three_bool}

                                                            />

                                                        </div>
                                                        <div className="flex align-items-center justify-content-center  ">
                                                            <h5>+</h5>
                                                        </div>
                                                        <div className="flex ">
                                                            <Dropdown
                                                                value={val.examen_four}
                                                                name='examen_four'
                                                                onChange={(e) => handleExamen_four(e, index)}
                                                                options={data_four}
                                                                optionLabel="label"
                                                                optionValue='label'
                                                                placeholder="Sélectionnez examen 4"
                                                                filter className="w-full "
                                                                onSelect={(e) => onSelectExamenFour(e, index)}
                                                                disabled={val.examen_four_bool}

                                                            />

                                                        </div>
                                                        <div className="flex ">
                                                            <button type='button' onClick={(e) => handleValideAddExamen(e, index)} className='btn btn-primary form-control mt-5' >Enregistrer</button>
                                                        </div>
                                                    </div>
                                                </Dialog>
                                            </div> */}

                                        </div>

                                        <div className='row'>
                                            <div className='col col-md-12'>
                                                {val.categorie_fichier_one}
                                                <input className="form-control " placeholder='Noms complets *'
                                                    value={val.nom_patient} onChange={(e) => handleChangeNomcomplet(e, index)}
                                                    name='nom_patient'
                                                    style={{ fontWeight: 500 }}
                                                />
                                                {val.bool_msg_nomcomplet && <label style={{ color: 'red' }}>{val.message_nomcomplet}</label>}
                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div className="col col-md-6" style={{ display: 'flex', flexDirection: 'row' }}>
                                                <input type='number' placeholder='Age '
                                                    value={val.age} onChange={(e) => handleChange(e, index)}
                                                    className='form-control'
                                                    name='age'
                                                    style={{ fontSize: "12px", fontWeight: 500 }}
                                                />

                                            </div>
                                            <div className=" col col-md-6">
                                                <select className='form-control' value={val.libelle_age}
                                                    onChange={(e) => handleChange(e, index)}
                                                    name='libelle_age'
                                                    style={{ fontWeight: 500 }}
                                                >
                                                    <option style={{ fontWeight: 500 }} value={'an(s)'}>an(s)</option>
                                                    <option style={{ fontWeight: 500 }} value={'Mois'}>Mois</option>
                                                    <option style={{ fontWeight: 500 }} value={'Jour(s)'}>Jour(s)</option>
                                                    <option style={{ fontWeight: 500 }} value={'Heure(s)'}>Heure(s)</option>
                                                </select>

                                            </div>
                                            {val.bool_msg_age && <label style={{ color: 'red', marginLeft: 9 }}>{val.message_age}</label>}
                                        </div>
                                        <div className='row'>
                                            <div className='col col-md-12'>
                                                <div style={{ display: "flex", position: 'relative' }}>
                                                    <div class="flex  flex-wrap">
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <RadioButton inputId="ingredient1" name="sexe" value={"M"} onChange={(e) => handleChange(e, index)} checked={val.sexe === 'M'} />
                                                            <label htmlFor="ingredient1" style={{ marginLeft: 5, marginTop: 2, fontWeight: 500 }} className="ml-2">Homme</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 20 }}>
                                                            <RadioButton inputId="ingredient2" name="sexe" value={"F"} onChange={(e) => handleChange(e, index)} checked={val.sexe === 'F'} />
                                                            <label htmlFor="ingredient2" style={{ marginLeft: 5, marginTop: 2, fontWeight: 500 }} className="ml-2">Femme</label>
                                                        </div>

                                                    </div>





                                                </div>
                                                {val.bool_msg_sexe && <label style={{ color: 'red' }}>{val.message_sexe}</label>}
                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className='col col-md-12'>
                                                <input type='text' className='form-control' placeholder='Prescripteur '
                                                    value={val.prescripteur} style={{ fontWeight: 500 }} name='prescripteur' onChange={(e) => handleChangePrescripteur(e, index)}
                                                />

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col col-md-12'>
                                                <div style={{ display: "flex", position: 'relative' }}>
                                                    <div class="flex  flex-wrap">
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <label style={{ marginTop: 10, fontWeight: 500 }}>Urgence:</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <RadioButton inputId="ingredient1" style={{ marginLeft: 5, marginTop: 10 }} name="etat" value={1} onChange={(e) => handleChange(e, index)} checked={val.etat === 1} />
                                                            <label htmlFor="ingredient1" className="ml-2" style={{ marginLeft: 5, marginTop: 10, fontWeight: 500 }}>Oui</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <RadioButton inputId="ingredient2" style={{ marginLeft: 5, marginTop: 10 }} name="etat" value={0} onChange={(e) => handleChange(e, index)} checked={val.etat === 0} />
                                                            <label htmlFor="ingredient2" className="ml-2" style={{ marginLeft: 5, marginTop: 10, fontWeight: 500 }}>Non</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <button type='button' className='btn btn-info' style={{ fontSize: 12, marginLeft: 15 }} name="bool_obs" onClick={() => handleVisible_obs(index)}>Obseravtion</button>
                                                        </div>
                                                    </div>





                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col col-md-5' style={{ padding: 15 }}>
                                        <div className='row'>
                                            <div>

                                                <Toast ref={toast}></Toast>
                                                <Tooltip target=".custom-choose-btn" content="Sélectionner un examen en format zip ." position="bottom" />
                                                <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                                                <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                                                <FileUpload
                                                    ref={fileUploadRef}
                                                    name={`uploadedFile[${index}]`}
                                                    accept=".zip"

                                                    multiple
                                                    onSelect={(e) => onSelect(e, index)} // Pass the index to the onSelect function
                                                    onError={onTemplateClear}
                                                    onClear={() => onTemplateClear(index)}
                                                    headerTemplate={(options) => headerTemplate(options, index)}
                                                    itemTemplate={(file, onRemove) => itemTemplate(file, onRemove, index)}
                                                    onUpload={(e) => onTemplateUpload(e, index)}
                                                    emptyTemplate={emptyTemplate}
                                                    chooseOptions={chooseOptions}
                                                    uploadOptions={uploadOptions}
                                                    cancelOptions={cancelOptions}
                                                    maxFileSize={42949672960}
                                                    value={val.uploadedFile}
                                                    className='uploadFiles'
                                                />
                                                {val.bool_msg_uploadedFile && <label style={{ color: 'red' }}>{val.message_uploadedFile}</label>}


                                            </div>

                                        </div>
                                        <div className='row' style={{ marginTop: '10px' }}>
                                            <MultiSelect style={{ width: "100%" }}
                                                panelStyle={{ fontWeight: 500 }}
                                                value={val.selected}
                                                onChange={(e) => handleMultiSelectChange(e.value, index)}
                                                options={membres} optionLabel="label"
                                                optionValue='value'
                                                display="chip"
                                                placeholder="Sélectionner manipulateur"
                                                filter
                                                maxSelectedLabels={3} className="w-full md:w-50rem manipu"

                                            />
                                            <label style={{ fontWeight: 600, marginTop: 15 }}>manipulateur: {val.nom_manipulateur}</label>

                                        </div>
                                    </div>
                                    <div className='col col-md-3' style={{ padding: 15 }}>
                                        <div className='row'>
                                            <div className={index === 0 ? 'col col-md-12' : 'col col-md-12'}>
                                                {val.imgSrc ? (
                                                    <img src={val.imgSrc} style={{ cursor: 'pointer', width: '100%', height: '200px' }} alt="webcam" name='imgSrc' onClick={() => handleVisible(index)}
                                                        value={val.imgSrc} onChange={(e) => handleChangeImgSrc(e, index)}
                                                        capture="environment" /* Capture from device camera */
                                                        accept="image/*" /* Accept any image format */
                                                        width="210mm" /* Set width to match A4 paper width */
                                                        height="297mm" /* Set height to match A4 paper height */

                                                    />
                                                ) : (
                                                    <Webcam

                                                        ref={webcamRef}
                                                        mirrored={mirrored}
                                                        screenshotFormat="image/jpeg"
                                                        imageSmoothing={true}
                                                        screenshotQuality={0.9}
                                                        style={{ width: '100%' }}

                                                    />

                                                )}
                                                {val.bool_msg_imgSrc && <label style={{ color: 'red' }}>{val.message_imgSrc}</label>}
                                                <div className="btn-container" style={{ marginTop: '-10px' }}>
                                                    <div className="btn-container d-flex" style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }}>
                                                        {/* <div >

                                                            {val.imgSrc ? (
                                                                <button type='button' onClick={(e) => retake(e, index)} className='btn btn-info' style={{ marginLeft: 5 }}>Retake photo</button>
                                                            ) : (
                                                                <button type='button' onClick={(e) => captureindex(index)} className='btn btn-warning' style={{ marginLeft: 5 }}>Capture photo</button>

                                                            )}

                                                        </div> */}
                                                        <Dialog header="Bulletin" visible={val.bool_large} style={{ width: '210mm', height: '100%' }} onHide={() => handleVisibleCapuClose(index)}>
                                                            {val.imgSrc ? (
                                                                <img src={val.imgSrc} alt="webcam" name='imgSrc2'
                                                                    value={val.imgSrc} onChange={(e) => handleChangeImgSrc(e, index)}
                                                                    capture="environment"
                                                                    accept="image/*"
                                                                    style={{ width: '210mm', height: '297mm', border: '1px solid black' }}

                                                                />
                                                            ) : (
                                                                <Webcam

                                                                    ref={webcamRef}
                                                                    mirrored={mirrored}
                                                                    screenshotFormat="image/jpeg"
                                                                    imageSmoothing={true}
                                                                    screenshotQuality={0.9}
                                                                    style={{ width: '100%' }}

                                                                />

                                                            )}

                                                            {val.imgSrc ? (
                                                                <button type='button' onClick={(e) => retake(e, index)} className='btn btn-info' style={{ marginLeft: 5, marginTop: 30 }}>Retake photo</button>
                                                            ) : (
                                                                <button type='button' onClick={(e) => captureindex(index)} className='btn btn-warning' style={{ marginLeft: 5, marginTop: 30 }}>Capture photo</button>

                                                            )}
                                                        </Dialog>
                                                        <button type="button"
                                                            className='btn btn-danger'
                                                            style={{ marginLeft: 10, }}
                                                            onClick={() => handleVisibleCapu(index)}
                                                        ><i className='pi pi-camera'></i></button>
                                                        <button type="button"
                                                            className='btn btn-primary'
                                                            style={{ marginLeft: 10, }}
                                                            onClick={() => openFolderPickFileImage(index)}
                                                        ><i className='pi pi-paperclip'></i></button>



                                                        <input type='file'
                                                            ref={useRefInputImage}
                                                            style={{ marginLeft: 10, display: 'none' }}
                                                            name="filehelp"
                                                            onChange={(e) => handleChangeHelpFile(e, index)}
                                                            accept="image/*"
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <Dialog header={"Observation de " + (val.sexe === "Homme" ? "Mr " : "Mme ") + val.nom_patient} visible={val.bool_obs} onChange={(e) => handleChange(e, index)} style={{ width: '45vw' }} onHide={() => handleClose_obs(index)}>
                                        <textarea
                                            className='form-control'
                                            rows={10}
                                            name='observation'
                                            value={val.observation}
                                            onChange={(e) => handleChange(e, index)}
                                        >

                                        </textarea>
                                        <button type='button' className='btn btn-info mt' onClick={() => add_Observation(val.observation, index)}>Ajouter</button>
                                    </Dialog>
                                    <Dialog header={"Photo de " + (val.sexe === "Homme" ? "Mr " : "Mme ") + val.nom_patient} visible={val.bool} onChange={(e) => handleChange(e, index)} style={{ width: '55vw' }} onHide={() => handleClose(index)}>

                                        <TransformWrapper
                                            initialScale={1}
                                            initialPositionX={200}
                                            initialPositionY={100}
                                            style={{ width: '100%' }}
                                        >

                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                <React.Fragment >

                                                    <TransformComponent>
                                                        <div className='col col-md-12'>
                                                            <img src={val.imgSrc} style={{ cursor: 'pointer', width: '51vw', position: 'relative' }} alt="webcam" name='imgSrc' onClick={() => setVisible(true)}
                                                                value={val.imgSrc}
                                                            />
                                                        </div>
                                                    </TransformComponent>
                                                    <div className="tools" style={{ marginTop: 10 }}>
                                                        <button className='btn btn-info' onClick={() => zoomIn()}>
                                                            <i className='fa fa-plus'></i>
                                                        </button>
                                                        <button style={{ marginLeft: 5 }} onClick={() => zoomOut()} className='btn btn-info'>
                                                            <i className='fa fa-minus'></i>
                                                        </button>
                                                        <button className='btn btn-danger' style={{ marginLeft: 5 }} onClick={() => resetTransform()}>
                                                            <i className='fa fa-close'></i>
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </TransformWrapper>


                                    </Dialog>

                                </div>
                                <div className='row'>
                                    {/* 
                                    <div className='col col-md-4'>
                                        <div style={{ display: "flex", position: 'relative' }}>
                                            <div class="flex  flex-wrap">

                                                <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                    <RadioButton inputId="ingredient5" name="checkFields" value={"A"} onChange={(e) => handleChange(e, index)} checked={val.checkFields === 'A'} />
                                                    <label htmlFor="ingredient5" style={{ marginLeft: 5, marginTop: 2 }} >Ajouter examen</label>
                                                </div>
                                                <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                    <RadioButton inputId="ingredient6" name="checkFields" value={"R"} onChange={(e) => handleChange(e, index)}
                                                        checked={val.checkFields === 'R'} style={{ marginLeft: 20 }} />
                                                    <label htmlFor="ingredient6" style={{ marginLeft: 170, position: 'absolute', marginTop: 2 }} >Remplacer examen</label>
                                                </div>


                                            </div>

                                        </div>

                                    </div> */}
                                    <div className='col col-md-12'>
                                        <div style={{ display: "flex", position: 'relative', justifyContent: 'center' }}>
                                            <div class="flex  flex-wrap">
                                               
                                                <div class="flex align-items-center justify-content-center ">
                                                  
                                                    {/* {val.nom_fichier_1 && <div><Checkbox inputId="FileCheckBox" name={`first_check_${index}`} value={val.chemin_fichier} onChange={(e) => onFileCheckBoxChange(e, index)} checked={val.isChecked_one} />&nbsp;<i className='fa fa-file'></i>&nbsp;{val.nom_fichier_1}</div>}
                                                    {val.nom_fichier_2 && <div style={{ marginLeft: 10 }}><Checkbox inputId="FileCheckBox2" name={`first_second_${index}`} value={val.nom_fichier_2} onChange={(e) => onFileCheckBoxChange_2(e, index)} checked={val.isChecked_two} />&nbsp;<i className='fa fa-file'></i>&nbsp;{val.nom_fichier_2}</div>}
                                                    {((val.nom_fichier_1 !== null && val.nom_fichier_2 === null)) ? <div style={{ marginLeft: 10 }}><Checkbox inputId="FileCheckBox3" name={`add_files_${index}`} onChange={(e) => onFileAdd(e, index)} checked={val.isChecked_add} />&nbsp;Ajouter un fichier</div> : ''}
                                                    {((val.nom_fichier_1 === null && val.nom_fichier_2 === null) || (val.nom_fichier_1 === "" && val.nom_fichier_2 === "")) ? <div style={{ marginLeft: 10 }}><Checkbox inputId="FileCheckBox4" name={`isChecked_add_both_${index}`} onChange={(e) => onFileAddBoth(e, index)} checked={val.isChecked_add_both} />&nbsp;Ajouter les fichiers</div> : ''}
                                                    <label style={{ marginLeft: 10 }}>{val.nom_fichier_2 && <i className='fa fa-file'></i>}&nbsp;{val.nom_fichier_2}</label> */}
                                                    {val.nom_fichier_1 && (
                                                        <div>
                                                            <Checkbox
                                                                inputId="FileCheckBox"
                                                                name={`first_check_${index}`}
                                                                value={val.chemin_fichier}
                                                                onChange={(e) => onFileCheckBoxChange(e, index)}
                                                                checked={val.isChecked_one}
                                                            />
                                                            &nbsp;<i className='fa fa-file'></i>&nbsp;{val.nom_fichier_1}
                                                        </div>
                                                    )}

                                                    {val.nom_fichier_2 && (
                                                        <div style={{ marginLeft: 10 }}>
                                                            <Checkbox
                                                                inputId="FileCheckBox2"
                                                                name={`first_second_${index}`}
                                                                value={val.nom_fichier_2}
                                                                onChange={(e) => onFileCheckBoxChange_2(e, index)}
                                                                checked={val.isChecked_two}
                                                            />
                                                            &nbsp;<i className='fa fa-file'></i>&nbsp;{val.nom_fichier_2}
                                                        </div>
                                                    )}

                                                    {(val.nom_fichier_1 && !val.nom_fichier_2) && (
                                                        <div style={{ marginLeft: 10 }}>
                                                            <Checkbox
                                                                inputId="FileCheckBox3"
                                                                name={`add_files_${index}`}
                                                                onChange={(e) => onFileAdd(e, index)}
                                                                checked={val.isChecked_add}
                                                            />
                                                            &nbsp;Ajouter un fichier
                                                        </div>
                                                    )}

                                                    {(!val.nom_fichier_1 && !val.nom_fichier_2) && (
                                                        <div style={{ marginLeft: 10 }}>
                                                            <Checkbox
                                                                inputId="FileCheckBox4"
                                                                name={`isChecked_add_both_${index}`}
                                                                onChange={(e) => onFileAddBoth(e, index)}
                                                                checked={val.isChecked_add_both}
                                                            />
                                                            &nbsp;Ajouter les fichiers
                                                        </div>
                                                    )}

                                                </div>

                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className='container-fluid'>
                        <div className='row card' style={{ height: '50px' }}>
                            <div className='col col-md-10'>

                            </div>
                            <div className='col col-md-1'>
                                <button
                                    type='button'
                                    title='Validation'
                                    onClick={(e) => confirmCreateFolder(e)}
                                    className='btn btn-primary'
                                    style={{ marginLeft: 50 }}
                                >
                                    <i class=" fa fa-upload"></i>
                                    &nbsp;&nbsp;Validation
                                </button>
                            </div>
                        </div>
                    </div>

                    <br /> <br />

                </section>

            </section>}
            <br /> <br />
            <Footer />
        </div>
    );
}

export default EditionDossiers;