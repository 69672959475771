import { useCallback, useEffect, useState } from "react";
import { createContext } from "react";

import axios from "axios";
import { URL_AXIOS } from "../URL/URL";
import useIdleTimer from "../components/centers/useIdleTimer";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = sessionStorage.getItem("membre_id");
    const site_id = sessionStorage.getItem("site_id");
    const test = parseInt(sessionStorage.getItem("test"));
    const [text, setText] = useState("");
    const [nbFoldersSend, setNbFoldersSend] = useState(0);
    const [datedebut, setDatedebut] = useState('');
    const [datefin, setDateFin] = useState('');
    const [dossiers, setDossiers] = useState([]);
    const [dossiersEn, setDossiersEn] = useState([]);
    const [disable, setDisable] = useState(false);
    const [disableAvis, setDisableAvis] = useState(false);
    const [disableRefresh, setDisableRefresh] = useState(false);
    const [items, setItems] = useState([]);
    const [nbFoldersRemove, setNbFoldersRemove] = useState(0);
    const [CompteExpertiseData, setCompteExpertiseData] = useState([]);
    const [dataAvisSend, setDataAvisSend] = useState([]);
    const [dataAvisReceive, setDataAvisReceive] = useState([]);
    const [medecinID, setMedecinID] = useState(membre_id);
    const [nbFoldersArchive, setNbFoldersArchive] = useState(0);
    const [nbFolderAvisSend, setNbFolderAvisSend] = useState(0);
    const [nbFolderAvisReceive, setNbFolderAvisReceive] = useState(0);
    const [notifi, setNotifi] = useState([]);
    const [notifiRecep, setNotifiRecep] = useState([]);
    const [notifiLimit, setNotifiLimit] = useState([]);
    const [objet, setObjet] = useState("");
    const [contenu, setContenu] = useState("");
    const [name_recep, setName_recep] = useState("");
    const [societe_recep, setSociete_recep] = useState("");
    const [fichier_notification, setFichier_notification] = useState("");
    const [created_at, setCreated_at] = useState();
    const [name_sender, setName_sender] = useState("");
    const [societe_sender, setSociete_sender] = useState("");
    const [getURLFolder, setGetURLFolder] = useState('');
    const [dataMembre, setDataMembre] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [dossiersCorbeille, setdossiersCorbeille] = useState([]);
    const [userID, setUserID] = useState(user_id);
    const [contentMrc, setContentMrc] = useState("");
    const [DossiersTransfereRetire, setDossiersTransfereRetire] = useState([]);
    const [DossiersRecu, setDossiersRecu] = useState([]);
    const [DossiersArchives, setDossiersArchives] = useState([]);
    const [messageBeforeExpire, setMessageBeforeExpire] = useState(sessionStorage.getItem('messageBeforeExpire'));
    const [jourRestant, setJourRestant] = useState(sessionStorage.getItem('jourRestant'))
    const [dataExamenStatistique, setDataExamenStatistique] = useState([]);
    const [periodeContext, setPeriodeContext] = useState("");
    const [visibleEntete, setVisibleEntete] = useState(false);
    const [boolResultat, setBoolResult] = useState(true);
    const [boolNewFolder, setBoolNewFolder] = useState(false);
    const [boolSendFolder, setBoolSendFolder] = useState(false);
    const [boolArchives, setBoolArchives] = useState(false);
    const [boolStatistique, setBoolStatistique] = useState(false);
    const [boolCorbeille, setBoolCorbeille] = useState(false);
    const [boolNotification, setBoolNotification] = useState(false);
    const [boolAgenda, setBoolAgenda] = useState(false);
    const email = sessionStorage.getItem("email");
    const motpasse = sessionStorage.getItem("motpasse");
    const [statusEnvoiFolder, setStatusEnvoiFolder] = useState(false);
    const [nbrTimeOfSession,setNbrTimeOfSession]=useState(30);
    useIdleTimer(nbrTimeOfSession * 60 * 1000);

    const LoginCheck = async (email, motpasse) => {

        try {

            if (email && motpasse) {
                const data = {
                    email: email,
                    motpasse: motpasse
                }

                const response = await axios.post(URL_AXIOS + 'loginCentre', data);
                if (response.status === 200) {

                    if (response.data.Login) {

                        if (response.data.Login.role === "C") {

                            //sessionStorage.setItem("nom", response.data.Login.name);
                            sessionStorage.setItem("email", response.data.Login.email);
                            sessionStorage.setItem("motpasse", response.data.Login.password);
                            sessionStorage.setItem("messageBeforeExpire", response.data.messageBeforeExpire);
                            sessionStorage.setItem("jourRestant", response.data.jourRestant);
                            setJourRestant(response.data.jourRestant);
                            setMessageBeforeExpire(response.data.messageBeforeExpire);
                            if (response.data.test === true) {

                                // navigate('/resultat');

                            } else {


                            }
                        } else {
                            // Swal.fire({
                            //     title: "Mot de passe ou nom d'utilisateur incorrect dfff!",
                            //     icon: 'error',
                            //     width: 600,
                            //     padding: "3em",
                            //     color: "#716add",
                            // });

                        }

                    } else {
                        // Swal.fire({
                        //     title: "Mot de passe or nom d'utilisateur incorrect",
                        //     icon: 'error',
                        //     width: 400,
                        //     padding: "3em",
                        //     color: "#716add",
                        // });
                    }
                } else {
                    // Swal.fire({
                    //     title: "Mot de passe or nom d'utilisateur incorrect"+response.data.Login,
                    //     icon: 'error',
                    //     width: 600,
                    //     padding: "3em",
                    //     color: "#716add",
                    // });
                }
            }

        } catch (error) {
            // console.log("Erreur zadazad" + error)
        }
    }





    const liste_dossiers_by_site = useCallback(async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_resultatBySite/${id}`);
            if (response.data.status == 200) {
                setDossiersRecu(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            // console.log("Erreur " + error.message);
        }
    }, [DossiersRecu])
    // const BulleCorbeille = async (id) => {
    //     try {
    //         const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_retire/${id}`);
    //         if (response.data.status === 200) {
    //             // setDossiersEn(response.data.dossier_send);
    //             //setDossiers(response.data.data_dossier_sub);
    //             setNbFoldersRemove(response.data.data_dossier_num.length)
    //         }
    //     } catch (error) {
    //         // alert("Erreur " + error.message);
    //     }
    // }

    // const BulleResultat = async (id) => {
    //     try {
    //         const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete/${id}`);
    //         if (response.data.status === 200) {
    //             // setDossiersEn(response.data.dossier_send);
    //             //setDossiers(response.data.data_dossier_sub);
    //             setNbFoldersSend(response.data.data_dossier_num.length)


    //         }
    //     } catch (error) {
    //         // alert("Erreur " + error.message);
    //     }
    // }

    // const BulleArchive = async (id) => {
    //     try {
    //         const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_archive/${id}`);
    //         if (response.data.status === 200) {
    //             setNbFoldersArchive(response.data.data_dossier_num.length)
    //         }
    //     } catch (error) {
    //         //alert("Erreur " + error.message);
    //     }
    // }






    const liste_notification = useCallback(async (id_user) => {
        try {
            const response = await axios.get(URL_AXIOS + `CountNumberOFNotification/${id_user}`);
            if (response.data.status === 200) {
                setNotifi(response.data.notificationSend);
                setNotifiLimit(response.data.notificationsLimit);
            }
        } catch (Error) {

        }
    }, [notifi, notifiLimit]);

    const liste_notificationByUsers = useCallback(async (id) => {
        try {
            if(id){
                const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${id}`);
                if (response.data.status === 200) {
                    setNotifiRecep(response.data.notifications);
     
                }
            }
          
        } catch (Error) {

        }
    }, [notifiRecep])

    const liste_dossier_corbeille = useCallback(async (id_site) => {
        try {

            const response = await axios.get(URL_AXIOS + `liste_dossiers_retire/${id_site}`);
            if (response.data.status === 200) {
                setdossiersCorbeille(response.data.dossier_send);


            }
        } catch (error) {
            // console.log("Erreur " + error.message);
        }
    }, [dossiersCorbeille])

    const liste_dossiers_transfere_retire = useCallback(async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_transfere_retire/${id_site}`);
            if (response.data.status == 200) {
                setDossiersTransfereRetire(response.data.dossier_send);

            }
        } catch (error) {
            // console.log("Erreur " + error.message);
        }
    }, [DossiersTransfereRetire])

    const liste_dossiers_transferer = useCallback(async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiersBySite/${id}`);
            if (response.data.status === 200) {
                setDossiers(response.data.dossier_send);
            }
        } catch (error) {
            // console.log("Erreur " + error.message);
        }
    }, [site_id])
    const liste_dossiersArchive_by_site = useCallback(async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_resultatArchiveBySite/${id}`);
            if (response.data.status == 200) {
                setDossiersArchives(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            // console.log("Erreur " + error.message);
        }
    }, [])

    useEffect(()=>{
     if(statusEnvoiFolder){
        setNbrTimeOfSession(2880);
     }else{
        setNbrTimeOfSession(30);
     }
    },[statusEnvoiFolder])
    useEffect(() => {
        if (site_id && email && motpasse) {
            LoginCheck(email, motpasse);
        }
    }, [messageBeforeExpire, jourRestant, email, motpasse]);

    useEffect(() => {
        if (site_id !== null && site_id !== "") {
            liste_dossier_corbeille(site_id);
        }


    }, [site_id]);

    useEffect(() => {

        if (site_id !== null && site_id !== "") {
            liste_dossiers_transfere_retire(site_id);
        }


    }, [site_id]);



    useEffect(() => {
        if (user_id !== null && user_id !== "") {
            liste_notification(user_id);
        }


    }, [user_id, notifiLimit]);

    useEffect(() => {
        if (user_id !== null && user_id !== "") {
            liste_notificationByUsers(user_id);

        }
    }, [user_id]);

    useEffect(() => {
        if (site_id !== null && site_id !== "") {

            liste_dossiers_by_site(site_id);
        }


    }, [site_id]);

    useEffect(() => {
        if (site_id !== null && site_id !== "") {
            liste_dossiers_transferer(site_id);
        }


    }, [site_id]);

    useEffect(() => {
        if (site_id !== null && site_id !== "") {
            liste_dossiersArchive_by_site(site_id);
        }
    }, [site_id])
  
    return (
        <ThemeContext.Provider value={{
            text, setText,
            datedebut, setDatedebut,
            datefin, setDateFin,
            dossiers, setDossiers,
            dossiersEn, setDossiersEn,
            items, setItems,
            nbFoldersSend, setNbFoldersSend,
            nbFoldersRemove, setNbFoldersRemove,
            disable, setDisable,
            disableAvis, setDisableAvis,
            CompteExpertiseData, setCompteExpertiseData,
            dataAvisSend, setDataAvisSend,
            dataAvisReceive, setDataAvisReceive,
            medecinID, setMedecinID,
            nbFoldersArchive, setNbFoldersArchive,
            nbFolderAvisSend, setNbFolderAvisSend,
            nbFolderAvisReceive, setNbFolderAvisReceive,
            notifi, setNotifi,
            notifiLimit, setNotifiLimit,
            objet, setObjet,
            contenu, setContenu,
            name_recep, setName_recep,
            societe_recep, setSociete_recep,
            fichier_notification, setFichier_notification,
            created_at, setCreated_at,
            name_sender, setName_sender,
            societe_sender, setSociete_sender,
            getURLFolder, setGetURLFolder,
            dataMembre, setDataMembre,
            notifications, setNotifications,
            notifiRecep, setNotifiRecep,
            userID, setUserID,
            disableRefresh, setDisableRefresh,
            contentMrc, setContentMrc,
            dossiersCorbeille, setdossiersCorbeille,
            messageBeforeExpire,
            jourRestant,
            DossiersTransfereRetire, setDossiersTransfereRetire,
            DossiersRecu, setDossiersRecu,
            DossiersArchives, setDossiersArchives,
            dataExamenStatistique, setDataExamenStatistique,
            periodeContext, setPeriodeContext,
            visibleEntete, setVisibleEntete,
            test,
            boolResultat, setBoolResult,
            boolNewFolder, setBoolNewFolder,
            boolSendFolder, setBoolSendFolder,
            boolArchives, setBoolArchives,
            boolStatistique, setBoolStatistique,
            boolCorbeille, setBoolCorbeille,
            boolNotification, setBoolNotification,
            boolAgenda, setBoolAgenda,
            statusEnvoiFolder, setStatusEnvoiFolder

        }}>
            {children}
        </ThemeContext.Provider>
    )
}