import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { AutoComplete } from 'antd';
import axios from 'axios';
import { URL_AXIOS, URL_FOLDER_API } from '../../URL/URL';
import Footer from '../Footer';
import ListeDossiersComponent from './ListDossiers';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import { RadioButton } from "primereact/radiobutton";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ContextMenu } from 'primereact/contextmenu';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ContextHeader';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import Loading from './Loading';
import WhatssapSmsOne from './WhatssapSmsOne';
import WhatssapSmsTwo from './WhatssapSmsTwo';
function DossiersTransfere(props) {
    const navigate = useNavigate();
    const { dossiers, setDossiers, setdossiersCorbeille } = useContext(ThemeContext);
    const [visible, setVisible] = useState(false);
    const [sites, setSites] = useState([]);
    //const [dossiers, setDossiers] = useState([]);
    const [site, setSite] = useState('');
    const [allcheck, setAllcheck] = useState(true);
    const [query, setQuery] = useState("");
    const [selectedMembres, setSelectedMembres] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [medecinradio, setMedecinradio] = useState(0);
    const [medecinradioSetValue, setMedecinradioSetValue] = useState(0);
    const recordsPerPage = 10;
    const dt = useRef(null);
    const cm = useRef(null);
    const toast = useRef(null);
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = dossiers.slice(firstIndex, lastIndex);
    const npage = Math.ceil(dossiers.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [isChecked, setIsChecked] = useState([]);
    const [queryPatient, setQueryPatient] = useState("");
    const [queryExamen, setQueryExamen] = useState("");
    const [dataPatient, setDataPatient] = useState([]);
    const [dataExamen, setDataExamen] = useState([]);
    const [membres, setMembres] = useState([]);
    const [dateDebut, setDateDebut] = useState(new Date());
    const [dateFin, setDateFin] = useState(new Date());
    const [bool, setBool] = useState(true);
    const [boolD, setBoolD] = useState(false);
    const [statusM, setStatusM] = useState(false);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const site_id = parseInt(sessionStorage.getItem("site_id"));
    const [boolBtn, setBoolBtn] = useState(false);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [visibleMessage, setVisibleMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [numberDoc, setNumberDoc] = useState("");
    const [societe, setSociete] = useState('');
    const [selectedMessage, setSelectedMessage] = useState("");
    const [membre_get_id, setMembre_get_id] = useState("");
    const [numberFolder, setNumberFolder] = useState("");
    const [message_one, setMessage_one] = useState("[E-METECH]: Bonjour Docteur, les examens de " + societe + " sont disponibles sur la plateforme.");
    const [message_two, setMessage_two] = useState("[E-METECH]: Bonjour Docteur, les examens de " + societe + "  sont disponibles dans la plateforme (Urgent).");
    const [boolFiles, setBoolFiles] = useState(false);
    const [isDownloadEnabled, setIsDownloadEnabled] = useState([]);
    const [isDownloadEnabled_2, setIsDownloadEnabled_2] = useState([]);
    const [dataFilesStatus, setDataFilesStatus] = useState([]);
    const [dataFilesStatusSecond, setDataFilesStatusSecond] = useState([]);
    const items = [
        { label: 'Affectation', icon: 'pi pi-fw pi-users', command: () => confirm1() }
    ];


    const copyToClipboard = (text) => {
        try {
            const fileNameWithoutExtension = text.split('.').slice(0, -1).join('.');
            navigator.clipboard.writeText(fileNameWithoutExtension).then(() => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Le nom du fichier a été copié avec succès !",
                    showConfirmButton: false,
                    timer: 2500
                });
            }).catch((err) => {
                console.error("Failed to copy text: ", err);
            });
        } catch (error) {

        }

    };
    const accept = () => {
        //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        affectationMedecin();
        setVisible(false);
        setMedecinradio('');


    }
    const liste_site_contrat_get_numberMedecin = async (id, contratid) => {
        try {

            const response = await axios.get(URL_AXIOS + `get_sites_membres_by_site_id_and_medecinByMember/${id}/${contratid}`);

            if (response.data.status == 200) {
                setSociete(response.data.vue_sites_membres.societe);
                setNumberDoc(response.data.vue_sites_membres.telephone_membres);
                setMessage_one("[E-METECH]\n Bonjour Docteur, les examens de " + societe + " sont disponibles sur la plateforme.");
                setMessage_two("[E-METECH] \nBonjour Docteur, les examens de " + societe + "  sont disponibles sur la plateforme (Urgent).");
            } else {

            }
        } catch (Error) {

        }
    }
    const WhatssappButton = () => {

        const phoneNumber = numberDoc;
        let defaultMessage = 'Salut! Comment pouvons-nous vous aider?';
        if (selectedMessage !== "") {
            defaultMessage = message_one;
        } else {

        }
        const encodedMessage = encodeURIComponent(defaultMessage);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        return (
            <a href={whatsappURL} onClick={() => setVisibleMessage(false)} className='btn btn-info form-control' target="_blank" rel="noopener noreferrer">
                <i className="icofont-whatsapp mr-2"></i>
                <span>Envoyer</span>
            </a>
        );
    };
    const confirm1 = () => {
        if (medecinradio !== 0) {

            Swal.fire({
                title: "Voulez-vous attribuer ces dossier(s) à ce médécin ?",
                icon: "warning",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Oui",
                denyButtonText: `Non`
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    accept();
                    Swal.fire("Le dossier a été attribué avec success", "", "success")
                        .then((result) => {
                            if (result.isConfirmed) {
                                setVisibleMessage(true);
                            }
                        });


                } else if (result.isDenied) {
                    setVisible(false);
                }
            });
            setVisible(false);
        } else {


        }

    };
    const confirm2 = () => {
        if (isChecked.length > 0) {

            Swal.fire({
                title: "Question ?",
                text: "Voulez-vous supprimer ce dossier ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {
                    retirer_dossier();
                    liste_dossiers_corbeille(site_id);
                    setVisible(false);
                    setStatusM(false);
                    Swal.fire({
                        text: "Le dossier a été supprimé avec succès !",
                        icon: "success"
                    });
                } else if (result.isDenied) {
                    setVisible(false);
                }
            });

        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Veuillez cocher un ou plusieurs dossiers",
                showConfirmButton: false,
                timer: 2500
            });
        }

    };



    const liste_dossiers = async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiersBySite/${id_site}`);
            if (response.data.status == 200) {
                setDossiers(response.data.dossier_send);
                setDataFilesStatus(response.data.dataCheckStatusOfFiles);
                setDataFilesStatusSecond(response.data.dataCheckStatusOfFilesSecond);
                setLoading(false)

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const checkingOfFiles = async (pathname) => {
        try {
            if (pathname) {
                // Append the pathname as a query parameter to the URL
                const response = await axios.get(URL_AXIOS + 'checkingOfFiles', {
                    params: { pathname: pathname }
                });

                if (response.data.status === 200) {
                    return response.data.bool;

                } else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error:", error.message);
            return false; // Return a default value if there's an error
        }
    };

    const checkingOfFilesA = (pathname) => {
        try {
            if (pathname) {
                const data = {
                    pathname: pathname
                }
                const response = axios.post(URL_AXIOS + 'checkingOfFiles', data);
                if (response.data.status == 200) {
                    alert(response.data.bool)
                    return response.data.bool;
                } else {
                    return false;
                }
            } else {
                return false;
            }

        } catch (error) {
            // console.error("Error:", error.message);
            return false; // Return a default value if there's an error
        }
    }
    const liste_dossiers_corbeille = async (id_site) => {
        try {

            const response = await axios.get(URL_AXIOS + `liste_dossiers_retire/${id_site}`);
            if (response.data.status === 200) {
                setdossiersCorbeille(response.data.dossier_send);
            }
        } catch (error) {
            console.log("Erreur " + error.message);
        }
    }
    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + `ListeMembreBySite/${site_id}`);
            if (response.data.status === 200) {
                setMembres(response.data.data_membres_sites);

            }
        } catch (Error) {

        }
    };



    const handleAffectation = () => {
        if (isChecked.length > 0) {
            setVisible(true);
            setNumberFolder(isChecked[0])
        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Veuillez cocher un ou plusieurs dossiers",
                showConfirmButton: false,
                timer: 2500
            });

        }
    }
    const handleRadio = (e) => {
        const value = e.target.value;
        setMedecinradio(value);
        setMedecinradioSetValue(value);
        setMembre_get_id(value)
        //confirm1();
    }

    const affectationMedecin = async () => {
        try {

            for (const val of isChecked) {
                const response = await axios.post(URL_AXIOS + `affecationMedecin/${val}`, {
                    membre_id: medecinradio
                });
                if (response.data.status == 200) {
                    liste_dossiers(site_id);
                }

            }

        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const retirer_dossier = async () => {
        try {

            for (const val of isChecked) {
                const response = await axios.post(URL_AXIOS + `retirer_dossier/${val}`);
                if (response.data.status == 200) {
                    liste_dossiers(site_id);
                    liste_dossiers_corbeille(site_id)

                }

            }
            setIsChecked([]);

        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const handleEdition = () => {
        if (isChecked.length > 0) {
            if (isChecked.length > 1) {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Veuillez cocher un seul dossier à modifier svp.",
                    showConfirmButton: false,
                    timer: 2500
                });
                setIsChecked([])
            } else {
                // const data = [isChecked];
                // navigate(`/editionDossiers/`, {
                //     state: { data: isChecked }
                // })
                navigate(`/editionDossiers/${isChecked[0]}`)
            }

        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Veuillez cocher les dossiers à modifier",
                showConfirmButton: false,
                timer: 2500
            });

        }

    }

    const handleCheckedInput = (e) => {
        const value = e.target.value; // Get the value of the checkbox
        const checked = e.target.checked;
        let updatedArray;
        if (checked) {
            updatedArray = [...isChecked, value];
        } else {
            updatedArray = isChecked.filter((item) => item !== value);
        }
        setIsChecked(updatedArray);

        if (updatedArray.length === 0) {
            setBoolBtn(false);
        } else {
            setBoolBtn(true);
        }

    };

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: 10 }}>


                <Checkbox value={data.id} name={`check_${data.id}`} onChange={(e) => handleCheckedInput(e)} checked={isChecked.includes(data.id)} />
                <i className='fa fa-hand-o-up'
                    onClick={() => navigate(`/member_detail_folder/${data.id}`)}
                    style={{
                        color: 'red',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14
                    }}
                    title='Détails'
                >
                </i>
                <i className='fa fa-download'
                    onClick={(e) => handleDownload(e, (data.chemin_fichier), (data.chemin_fichier_2))}
                    style={{
                        color: 'green',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14,
                        pointerEvents: dataFilesStatus[data.id] ? 'auto' : 'none'
                    }}
                    title='Télécharger examen'

                >
                </i>
                <i
                    className='fa fa-copy'
                    onClick={() => copyToClipboard(data.nom_fichier)}
                    style={{
                        pointerEvents:!dataFilesStatus[data.id] ? 'auto' : 'none',
                        cursor: 'pointer',
                        marginLeft: 10,
                        fontSize: 14
                    }}
                    title="Copier le nom d'examen"
                >
                </i>
            </div>
        )
    }
    // const handleDownload = (e, value, value2) => {
    //     e.preventDefault();
    //     const link = document.createElement('a');

    //     if (value !== null) {

    //         link.download = 'Example-PDF-File';
    //         link.href = URL_FOLDER_API + value;
    //         link.click();
    //     }
    //     if (value2 !== null) {
    //         // Delay the creation and click event of the second anchor
    //         setTimeout(() => {
    //             const link2 = document.createElement('a');
    //             link2.download = 'Example-PDF-File2';
    //             link2.href = URL_FOLDER_API + value2;
    //             link2.click();
    //         }, 500); // Adjust the delay time as needed
    //     }
    // };

    const handleDownload = async (e, value, value2) => {
        e.preventDefault();

        const downloadFile = (fileName, url) => {
            return new Promise((resolve) => {
                const link = document.createElement('a');
                link.download = fileName;
                link.href = url;
                document.body.appendChild(link); // Append link to the body
                link.click();
                document.body.removeChild(link); // Remove link from the body
                resolve();
            });
        };

        try {
            if (value !== null) {
                await downloadFile('Example-PDF-File', URL_FOLDER_API + value);
            }
            if (value2 !== null) {
                // Increase delay to ensure the browser has time to handle the first download
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await downloadFile('Example-PDF-File2', URL_FOLDER_API + value2);
            }
        } catch (error) {
            console.error('Error during download:', error);
        }
    };


    const ActionBodyF = (data) => {
        
        return (

            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>

                <span style={{ backgroundColor: dataFilesStatus[data.id] ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>

                </span>
                <span style={{ backgroundColor: dataFilesStatusSecond[data.id] ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute', marginLeft: '15px' }}>

                </span>
            </div>
        )
    }

    const ActionBodyR = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <span style={{ backgroundColor: data.etat_medecin === "1" ? 'green' : 'red', height: 10, width: 10, borderRadius: '50%', position: 'absolute' }}>

                </span>
            </div>
        )
    }
    const HeaderF = () => {
        return (
            <p style={{ marginLeft: 10 }}>F</p>
        )
    }
    const closeVisible = () => {
        setVisibleMessage(false);

    }

    useEffect(() => {
        liste_site_contrat_get_numberMedecin(site_id, parseInt(membre_get_id));
    }, [membre_get_id, societe]);
    useEffect(() => {
        liste_dossiers(site_id);
        lister_contrat();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);

    // useEffect(() => {
    //     const checkFiles = async () => {
    //         const downloadStatus1 = [];
    //         const downloadStatus2 = [];

    //         for (let i = 0; i < dossiers.length; i++) {
    //             const file1Exists = await checkingOfFiles(dossiers[i].chemin_fichier);
    //             const file2Exists = await checkingOfFiles(dossiers[i].chemin_fichier_2);

    //             downloadStatus1[dossiers[i].id] = file1Exists;
    //             downloadStatus2[dossiers[i].id] = file2Exists;
    //         }
    //         setIsDownloadEnabled(downloadStatus1);
    //         setIsDownloadEnabled_2(downloadStatus2);
    //     };

    //     checkFiles();
    // }, [dossiers]);
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <Toast ref={toast} />
            <ConfirmDialog />
            <ContextMenu model={items} ref={cm} breakpoint="767px" />
            <Dialog header="Messages" visible={visibleMessage} style={{ width: '50vw' }} onHide={() => closeVisible()}>
                <div style={{ display: "flex", }}>
                    <RadioButton inputId="ingredient1" style={{ marginTop: 20 }} name="pizza" value={message_one} onChange={(e) => setSelectedMessage(e.value)} checked={selectedMessage === message_one} />
                    {/* <p style={{ marginLeft: 10 }}>
                       [E-METECH] Bonjour Docteur, les examens de {societe} sont disponibles sur la plateforme.
                    </p> */}
                    <WhatssapSmsOne societe={societe} />
                </div>
                <Divider />
                <div style={{ display: "flex", }}>
                    <RadioButton inputId="ingredient2" style={{ marginTop: 20 }} name="pizza" value={message_two} onChange={(e) => setSelectedMessage(e.value)} checked={selectedMessage === message_two} />
                    {/* <p style={{ marginLeft: 10 }}>
                        <span style={{ color: 'red', fontWeight: "bold" }}>[E-METECH] (Urgent)</span> Bonjour Docteur, les examens de {societe} sont disponibles sur la plateforme .

                    </p> */}
                    <WhatssapSmsTwo societe={societe} />
                </div>

                {WhatssappButton()}
            </Dialog>
            <Sidebar visible={visible} onContextMenu={(e) => cm.current.show(e)} position="right" onHide={() => setVisible(false)} >

                <center><h5>Liste des médecins</h5></center>
                <hr style={{ width: '100%', height: 2, backgroundColor: 'red' }} />
                <div className="flex justify-content-center">
                    <div className="flex flex-column ">
                        {membres.map((sitemembre) => {
                            return (
                                <ul style={{ padding: 2 }}>
                                    <li>
                                        <div key={sitemembre.membre_id} className="flex align-items-center">
                                            <RadioButton inputId={`sitemembre_${sitemembre.membre_id}`} name={sitemembre.membre_id} value={sitemembre.membre_id} onChange={handleRadio} checked={medecinradio === sitemembre.membre_id} />
                                            <label htmlFor={`sitemembre_${sitemembre.membre_id}`} style={{ fontWeight: 'bolder', marginLeft: 10 }} className="ml-2">{sitemembre.nom + " " + sitemembre.prenom}</label>

                                        </div>
                                    </li>
                                </ul>
                            )


                        })}

                    </div>

                </div>
                <center><button type='button' onClick={confirm1} className='btn btn-primary'

                >Affectation</button></center>
            </Sidebar>

            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper ">
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col col-md-9'>

                            </div>
                            <div className='col col-md-3'>

                                <div style={{ float: 'right', marginTop: 20 }}>
                                    {/* <button type='button'  onClick={() => navigate('/liste_archive_dossier_transfere')} className="btn btn-default" title='Liste des archives dossiers transférés '>
                                        <i class="fa fa-eye"></i>
                    </button>*/}
                                    <button type='button' className='btn btn-primary' title='Attribution médecin'
                                        onClick={handleAffectation}
                                        style={{ marginLeft: 5 }}
                                        disabled={boolBtn === false ? true : false}
                                    >
                                        <i class=" fa fa-level-up"></i>
                                    </button>
                                    <button type='button' className='btn btn-warning' title='Modification dossiers'
                                        onClick={handleEdition} style={{ marginLeft: 5 }}
                                        disabled={boolBtn === false ? true : false}
                                    >
                                        <i class=" fa fa-edit"></i>
                                    </button>

                                    <button type='button' title='Retirez un dossier'
                                        onClick={confirm2}
                                        disabled={boolBtn === false ? true : false}
                                        className='btn btn-danger' style={{ marginLeft: 5 }}>
                                        <i class=" fa fa-trash-o"></i>
                                    </button>
                                </div>

                            </div>


                        </div>
                        <Divider />

                        <div className='row'>

                            <DataTable value={dossiers} paginator emptyMessage="Aucun dossier disponible!" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className='custom-datatable'>
                                <Column header={() => { return <div style={{ marginLeft: 20 }}>#</div> }} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} field='id' style={{ width: '5%', height: '40px' }}></Column>
                                <Column field='nom_pat' header="PATIENTS" style={{ width: '25%', height: '40px' }} headerStyle={{ textAlign: 'center' }}></Column>
                                <Column field={'categorie_fichier'} header="EXAMENS" style={{ width: '25%', height: '40px' }}  ></Column>
                                <Column field={(data) => { return data.nom_medecin + ' ' + data.prenom_medecin }} headerStyle={{ textAlign: 'center !important' }} header="RADIOLOGUE" style={{ width: '24%', height: '40px' }}></Column>
                                <Column field='created_at' header="ENVOYE LE" style={{ width: '10%', height: '40px' }}></Column>
                                <Column header={"F"} body={ActionBodyF} style={{ width: '4%', height: '40px', textAlign: 'center' }} headerStyle={{ textAlign: 'center !important' }}></Column>
                                <Column header="R" body={ActionBodyR} style={{ width: '2%', height: '40px' }} headerStyle={{ textAlign: 'center' }}></Column>
                                <Column header="" body={ActionBody} style={{ width: '15%', height: '40px' }} headerStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>

                            <br />
                            <br />
                        </div>
                    </div>
                </section>
            </section>}

            <Footer />
        </div>
    );
}

export default DossiersTransfere;