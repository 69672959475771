import React, { useContext, useEffect } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { Card } from 'primereact/card';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Avatar } from 'primereact/avatar';
import { Panel } from 'primereact/panel';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
function LectureNotification() {
    const {
        objet, setObjet,
        contenu, setContenu,
        name_recep, setName_recep,
        societe_recep, setSociete_recep,
        fichier_notification, setFichier_notification,
        created_at, setCreated_at,
        name_sender, setName_sender,
        societe_sender, setSociete_sender,
        notifiRecep, setNotifiRecep,
        userID, setUserID
    } = useContext(ThemeContext);
    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const [selectedFile, setSelectedFile] = useState(null);
    const liste_notification = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${userID}`);
            if (response.data.status === 200) {
                setNotifiRecep(response.data.notifications);
            }
        } catch (Error) {

        }
    }
    const lireNotification = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `updateStatus_notification/${id}`);
            if (response.data.status == 200) {
                liste_notification();
                setObjet(response.data.notification.objet)
                setContenu(response.data.notification.contenu)
                setName_recep(response.data.notification.name_sender)
                setName_sender(response.data.notification.name_sender)
                setCreated_at(response.data.notification.created_at)
                setFichier_notification(response.data.notification.fichier_notification)
                setSociete_recep(response.data.notification.societe_recep);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const Title = (val) => {
        return (
            <div className='row'>
                <div className='col col-md-6'>
                    <div style={{ display: 'flex' }}>
                        <Avatar icon="pi pi-user" size="xlarge" shape="circle"
                            style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                        />
                        <h5 style={{ marginLeft: 10 }}>{val.name_sender}</h5>
                    </div>

                </div>
                <div className='col col-md-6'>
                    <h5 style={{ marginLeft: 10, float: 'right' }}>{val.societe_sender}</h5>
                </div>
            </div>
        )
    }

    const FooterPage = (date, image, status) => {
        return (
            <div style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', color: '#2196F3' }}>{date}</p>
                <a href='#'>{image && <i className='fa fa-paperclip' style={{ fontSize: 20, marginLeft: 20 }}></i>}</a>
                <p style={{ marginLeft: 20 }}>{status == "0" ? "Non lu" : 'lu'}</p>
            </div>
        )
    }
    const TitleLecture = (name, societe) => {
        return (
            <div className='row'>
                <div className='col col-md-6'>
                    <div style={{ display: 'flex' }}>
                        <Avatar icon="pi pi-user" size="xlarge" shape="circle"
                            style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                        />
                        <h5 style={{ marginLeft: 10 }}>{name}</h5>
                    </div>

                </div>
                <div className='col col-md-6'>
                    <h5 style={{ marginLeft: 10, float: 'right' }}>{societe}</h5>
                </div>
            </div>
        )
    }

    const handleDownload = (e, val) => {
        e.preventDefault();
        const getURL = val.folderUrl + '/' + val.fichier_notification;
        const downloadLink = document.createElement('a');
        downloadLink.href = getURL;
        downloadLink.download = getURL;
        downloadLink.target = "_blank";
        downloadLink.click();
    };

    const handleDownload_Second = (e, val) => {
        e.preventDefault();
        // const getURL =val.folderUrl + '/' + val.fichier_notification;
        const downloadLink = document.createElement('a');
        downloadLink.href = val;
        downloadLink.download = val;
        downloadLink.target = "_blank";
        downloadLink.click();
    };
    useEffect(() => {
        //liste_notification();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [])
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div className='row ' style={{ marginTop: 10 }}>
                        <div className='col col-md-6'>
                            <h5>Tous les messages reçus</h5>
                            <ScrollPanel style={{ width: '100%', height: '600px' }} className="custombar1">
                                {notifiRecep.map((noti) => (
                                    //    Title(noti)
                                    <Card onClick={() => lireNotification(noti.id_notification)} style={noti.status_notification == 0 ? { marginTop: 10, cursor: 'pointer', backgroundColor: "gray", color: "white !important" } : { marginTop: 10, cursor: 'pointer', backgroundColor: "white" }} >


                                        <div style={{ display: 'flex', flexDirection: "column" }}>
                                            <div style={{ display: 'flex', flexDirection: "row",justifyContent:"space-between" }}>
                                                <p style={{ fontWeight: "bold",color:noti.status_notification == 0 ? 'white':'' }}>{noti.name_sender}</p>
                                                <p style={{ fontWeight: 'bold', color:noti.status_notification == 0 ? "white" :'#2196F3' }}>{noti.created_at}</p>
                                            </div>

                                            <p style={{ fontWeight: 'bold', color:noti.status_notification == 0 ? 'white' :'#2196F3' }}>{noti.objet}</p>
                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>

                                                <a href='#'>{noti.fichier_notification && <i className='fa fa-paperclip'
                                                    onClick={(e) => handleDownload(e, noti)}
                                                    style={{ cursor: 'pointer' }}
                                                ></i>}</a>

                                            </div>
                                        </div>
                                    </Card>


                                ))}
                                {notifiRecep.length > 0 ? " " : "Aucune notifications"}
                            </ScrollPanel>
                        </div>
                        <div className='col col-md-6'>
                            <h5>Lecture</h5>
                            <ScrollPanel style={{ width: '100%', height: '600px' }} className="custombar1">
                                {objet && <Card style={{ marginTop: 10 }} className='bg-warning'

                                >
                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <p style={{ fontWeight: "bold" }}>{name_sender}</p>
                                        <p style={{ fontWeight: 'bold', color: '#2196F3' }}>{objet}</p>
                                        <p style={{ fontWeight: 'bold', color: '#2196F3', width: "100%", wordWrap: 'break-word' }}>{contenu}</p>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                                            <p style={{ fontWeight: 'bold', color: '#2196F3' }}>{created_at}</p>
                                            <a href='#'>{fichier_notification && <i className='fa fa-paperclip'
                                                onClick={(e) => handleDownload_Second(e, selectedFile)}
                                                style={{ cursor: 'pointer' }}
                                            ></i>}</a>
                                            {/* <p style={{ marginLeft: 20 }}>{noti.status == "0" ? "Non lu" : 'lu'}</p> */}
                                        </div>
                                    </div>
                                </Card>}
                            </ScrollPanel>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default LectureNotification;