import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from "primereact/checkbox";
import { Divider } from 'primereact/divider';
import { ThemeContext } from '../context/ContextHeader';
import { URL_AXIOS } from '../URL/URL';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { BiLogOutCircle } from "react-icons/bi";
import NotificationAbonnement from './centers/NotificationAbonnement';

function Header() {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('top');
    const [bool, setBool] = useState(true);
    const [ingredients, setIngredients] = useState([]);
    const [membres, setMembres] = useState([]);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const site_id = parseInt(sessionStorage.getItem("site_id"));
    const toast = useRef(null);
    // const show = () => {
    //     toast.current.show({ severity: 'info', summary: 'Info', detail: 'Message Content' });
    // };
    //const [getURLFolder, setGetURLFolder] = useState('');
    //const [dataMembre, setDataMembre] = useState([]);
    const [activeBtnSearch, setActiveBtnSearch] = useState(false);


    const {
        items, setItems,
        disable, setDisable,
        disableAvis, setDisableAvis,
        dataAvisSend, setDataAvisSend,
        dataAvisReceive, setDataAvisReceive,
        medecinID, setMedecinID,
        datedebut, setDatedebut,
        dossiersEn, setDossiersEn,
        dossiers, setDossiers,
        datefin, setDateFin,
        nbFoldersSend, setNbFoldersSend,
        nbFoldersArchive, setNbFoldersArchive,
        notifi, setNotifi,
        notifiLimit, setNotifiLimit,
        objet, setObjet,
        contenu, setContenu,
        name_recep, setName_recep,
        societe_recep, setSociete_recep,
        fichier_notification, setFichier_notification,
        created_at, setCreated_at,
        name_sender, setName_sender,
        societe_sender, setSociete_sender,
        dataMembre, setDataMembre,
        getURLFolder, setGetURLFolder,
        notifiRecep, setNotifiRecep,
        nbFolderAvisReceive,
        userID, setUserID,
        notifications, setNotifications,
        disableRefresh, setDisableRefresh,
        dossiersCorbeille, setdossiersCorbeille,
        messageBeforeExpire, jourRestant,
        DossiersTransfereRetire, setDossiersTransfereRetire,
        DossiersRecu, setDossiersRecu,
        DossiersArchives, setDossiersArchives,
        
    } = useContext(ThemeContext);

    //const {items}=useContext(Them)
    const location = useLocation();
    const LogOut = () => {
        sessionStorage.removeItem("nom");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("motpasse");
        navigate('/')

    }
    const liste_dossiers_transfere_retire = useCallback(async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_dossiers_transfere_retire');
            if (response.data.status == 200) {
                setDossiersTransfereRetire(response.data.dossier_send);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }, []);
    const liste_notificationByUsers = useCallback(async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${user_id}`);
            if (response.data.status === 200) {
                setNotifiRecep(response?.data.notifications);
                setDataMembre(response?.data);
                setGetURLFolder(response?.data.folderUrl)
            }
        } catch (Error) {

        }
    }, []);

    const liste_notificationLoad = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${id}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl);


            }
        } catch (Error) {

        }
    }
    const liste_membre = useCallback(async () => {
        try {
            const response = await axios.get(URL_AXIOS + `listMembreById/${medecinID}`);
            if (response.data.status === 200) {
                setMembres(response.data.membres);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }, []);
    const AlertSave = () => {
        Swal.fire({
            title: 'Informations',
            text: 'Les données ont été enregistrées avec succès !',
            icon: 'success',

        })
    }
    const AddAvis = async () => {
        try {
            // Create an array to accumulate data
            const requestData = [];

            for (const value of items) {
                for (const ing of ingredients) {
                    const data = {
                        dossier_id: value,
                        membre_id_send: medecinID,
                        membre_id_receive: ing
                    };

                    // Push data into the array
                    requestData.push(data);
                }
            }

            // Make a single request with all the data
            const response = await axios.post(URL_AXIOS + 'addAvis', {
                dataAvis: requestData
            });

            if (response.data.status === 200) {
                // Handle success message or data after making the request
                setVisible(false);

                AlertSave();
            } else {
                alert("ERREUR 500 " + response.data.data);
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    };
    const ListeAvisSend = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_envoye/${val}`);
                if (response.data.status == 200) {
                    setDataAvisSend(response.data.data_avis_send);

                } else {

                }
            }

        } catch (error) {

        }
    }


    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
    }
    const liste_dossiers = useCallback(async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiersBySite/${site_id}`);
            if (response.data.status == 200) {
                setDossiers(response.data.dossier_send);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }, []);
    const liste_dossiers_corbeille = useCallback(async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_retire/${id}`);
            if (response.data.status === 200) {
                setdossiersCorbeille(response.data.dossier_send);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }, []);
    const liste_dossiersArchive_by_site = useCallback(async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_resultatArchiveBySite/${site_id}`);
            if (response.data.status == 200) {
                setDossiersArchives(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }, []);

    const liste_dossiers_by_site = useCallback(async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_resultatBySite/${site_id}`);
            if (response.data.status == 200) {
                setDossiersRecu(response.data.vue_dossier_interpreter);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }, []);
    const [query, setQuery] = useState("");

    const handleQueryText = async (e) => {
        try {
            const query_name = e.target.value;
            setQuery(query_name);

            if (location.pathname == "/resultat") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `liste_resultatBySiteSearchByInput/${site_id}/${query_name}`);
                    if (response.data.status === 200) {
                        setDossiersRecu(response.data.vue_dossier_interpreter);


                    }
                } else {
                    liste_dossiers_by_site();
                }
            } else if (location.pathname == "/resultat_transfere") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers
                    const response = await axios.post(URL_AXIOS + `recherche_dossier_transfererBySite/${query_name}/${site_id}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDossiers(response.data.dossier_transfere);
                    }
                } else {
                    liste_dossiers();
                }
            } else if (location.pathname == "/corbeille") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_by_input/${query_name}/${site_id}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setdossiersCorbeille(response.data.vue_dossier_send_pl_input);

                    }
                } else {
                    liste_dossiers_corbeille();
                }
            } else if (location.pathname == "/notifications") {
                if (query_name.length > 0) {
                    const response = await axios.post(URL_AXIOS + `recherche_notification_by_objet_or_siteBySite/${user_id}/${query_name}`);
                    if (response.data.status === 200) {
                        console.log(response.data.notifications)
                        setNotifications(response?.data.notifications);
                        // setDataMembre(response.data);
                        // setGetURLFolder(response.data.folderUrl)

                    } else {

                    }
                } else {
                    liste_notificationLoad(user_id);
                }
            } else if (location.pathname == "/lireNotification") {
                if (query_name.length > 0) {
                    const response = await axios.post(URL_AXIOS + `RechercheNotificationsLecture/${userID}/${query_name}`);
                    if (response.data.status === 200) {
                        setNotifiRecep(response.data.notifications);

                    } else {
                        console.log("Erreur")
                    }
                } else {
                    liste_notificationByUsers();
                }
            } else if (location.pathname == "/liste_archive_dossier_transfere") {

                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_by_input/${query_name}`);
                    if (response.data.status === 200) {
                        //setDossiersEn(response.data.vue_dossier_send_pl_input);
                        setDossiersTransfereRetire(response.data.vue_dossier_send_pl_input);

                    }
                } else {
                    liste_dossiers_transfere_retire();
                }
            } else if (location.pathname == "/archives") {
                if (query_name.length > 0) {
                    // Call the helper function to filter dossiers

                    const response = await axios.post(URL_AXIOS + `liste_resultatArchiveSearchByInput/${site_id}/${query_name}`);
                    if (response.data.status === 200) {
                        setDossiersArchives(response.data.vue_dossier_interpreter);


                    }
                } else {
                    liste_dossiersArchive_by_site();
                }
            }



        } catch (error) {
        }
    };


    const lireNotification = async (id) => {
        try {
            const response = await axios.post(URL_AXIOS + `updateStatus_notification/${id}`);
            if (response.data.status == 200) {

                navigate('/lireNotification')
                setObjet(response.data.notification.objet)
                setContenu(response.data.notification.contenu)
                setName_recep(response.data.notification.name_recep)
                setName_sender(response.data.notification.name_sender)
                setCreated_at(response.data.notification.created_at)
                setFichier_notification(response.data.notification.fichier_notification)
                setSociete_recep(response.data.notification.societe_recep)
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const annuler_research = () => {
        setDateFin('');
        setDatedebut('');
        setBool(true);

        if (location.pathname == "/archives") {
            liste_dossiersArchive_by_site();
        } else if (location.pathname == "/resultat_transfere") {
            liste_dossiers();
        } else if (location.pathname == "/resultat") {
            liste_dossiers_by_site();
        } else if (location.pathname == "/corbeille") {
            liste_dossiers_corbeille(site_id)
        } else if (location.pathname == "/notifications") {
            liste_notification();
        } else if (location.pathname == "/liste_archive_dossier_transfere") {
            liste_dossiers_transfere_retire();
        }



    }
    const liste_notification_recu = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `Liste_noti_recepByUser/${user_id}`);
            if (response.data.status === 200) {
                setNotifi(response.data.notification);
                setNotifiLimit(response.data.notificationsLimit);

            }
        } catch (Error) {

        }
    }
    const liste_notification = useCallback(async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${user_id}`);
            if (response.data.status === 200) {
                setNotifications(response.data.notifications);
                setDataMembre(response.data);
                setGetURLFolder(response.data.folderUrl)



            }
        } catch (Error) {

        }
    }, []);

    const rechercher_dossier = async () => {

        try {
            if (location.pathname == "/resultat") {


                if (datedebut !== "" && datefin !== "") {
                   
                    const response = await axios.post(URL_AXIOS + `liste_resultatBySiteSearchByPeriod/${site_id}/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setDossiersRecu(response.data.vue_dossier_interpreter);
                    
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    //alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/resultat_transfere") {


                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_transfererBySite/${datedebut}/${datefin}/${site_id}`);
                    if (response.data.status === 200) {
                        setDossiers(response.data.vue_dossier_send_pl);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/archives") {


                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `liste_resultatArchiveBySiteSearchByPeriod/${site_id}/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setDossiersArchives(response.data.vue_dossier_interpreter);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/corbeille") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl_corbeille/${datedebut}/${datefin}/${site_id}`);
                    if (response.data.status === 200) {
                        setdossiersCorbeille(response.data.vue_dossier_send_pl);
                        setBool(false);
                    }
                } else {
                    setBool(true);
                    alert("Veuillez remplir les deux dates svp")
                }
            } else if (location.pathname == "/liste_archive_dossier_transfere") {
                if (datedebut !== "" && datefin !== "") {

                    const response = await axios.post(URL_AXIOS + `recherche_vue_dossier_send_pl/${datedebut}/${datefin}`);
                    if (response.data.status === 200) {
                        setDossiersTransfereRetire(response.data.vue_dossier_send_pl);
                        setBool(false);
                    }
                } else {
                    setBool(true);

                }
            } else if (location.pathname == "/notifications") {
                if (datedebut !== "" && datefin !== "") {
                    const response = await axios.post(URL_AXIOS + `recherche_notificationByUsers/${datedebut}/${datefin}/${user_id}`);
                    if (response.data.status == 200) {
                        setNotifications(response.data.notifications);
                        setBool(false);
                    } else {

                    }
                } else {
                    setBool(true);
                    liste_notification();
                    alert("Veuillez remplir les deux dates svp")
                }
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const showInfo = (message) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: message, life: 3000 });
    }


    useEffect(() => {
     
         
        //liste_membre();
        //liste_dossiersArchive_by_site();
        //liste_dossiers_by_site();
        // liste_dossiers_corbeille();
        // liste_dossiers_transfere_retire();
        //liste_notification();
        // liste_dossiers();
        // liste_notificationByUsers();
        //liste_notification_recu();
        //liste_notification();
        if (location.pathname === "/createFolder") {
            setActiveBtnSearch(true)
        }
       
    }, [messageBeforeExpire, jourRestant]);




    return (
        <div class="flex flex-column">
          
            {messageBeforeExpire && <div class="flex align-items-center justify-content-center h-2rem  font-bold" style={{ position:'fixed',top: 0, width: '100%',zIndex:1000,backgroundColor:'red'}} id='abonDiv'>
            <p style={{marginTop:5,position:'absolute',color:'white',textTransform:'uppercase'}}>{messageBeforeExpire}</p>
            </div>}
            <div class="flex " style={messageBeforeExpire ? { marginTop: 20 } : {}}>
            <header class="header black-bg" >
           <Toast ref={toast} />

           <Dialog visible={visible} position={position} style={{ width: '30vw', height: '100vh' }} onHide={() => setVisible(false)} draggable={false} resizable={false}>
               <center><h3>Demander l'avis d'un autre medécin</h3></center>


               <h3 style={{ color: 'red', fontSize: "15px" }}>Disponibilités</h3>
               <Divider />
               {membres.map((val) => (
                   <div className='row'>
                       <div className='col col-md-10'>
                           <h6 style={{ fontSize: 15, }}>{val.nom + " " + val.prenom}</h6>
                       </div>
                       <div className='col col-md-2'>
                           <Checkbox inputId={`dossier_${val.id}`} style={{ marginTop: 5, marginLeft: 20 }} name={val.id} value={val.id} onChange={onIngredientsChange} checked={ingredients.includes(val.id)} />

                       </div>



                       <Divider />
                   </div>


               ))}


               <button type='button' onClick={() => AddAvis()} className='btn btn-success form-control'>Envoyer</button>
           </Dialog>
           <a href="#" class="logo"><b><span style={{ color: 'red' }}>TERA</span><span>TEX</span></b></a>
           <div class="nav notify-row" id="top_menu" style={{ marginLeft: '35px' }}>

               <ul class="nav top-menu">
              
                   <li className={jourRestant>0 ? "dropdown":"disabledLi dropdown"} title='Résultats'>

                       <Link to={'/resultat'}>
                           <i class="fa fa-desktop"></i>
                           <span class="badge bg-danger">{DossiersRecu.length} </span>
                       </Link>

                   </li>
                   <li className={jourRestant>0 ? "dropdown":"disabledLi dropdown"} title='Dossiers Transférés'>
                       <Link to={'/resultat_transfere'}>
                           <i class="fa fa-upload"></i>
                           <span class="badge bg-primary">{dossiers.length}</span>
                       </Link>

                   </li>
                   <li className={jourRestant>0 ? "dropdown":"disabledLi dropdown"} title='Archives'>
                       <Link to={'/archives'}>
                           <i class="fa fa-archive"></i>
                           <span class="badge bg-theme">{DossiersArchives.length}</span>
                       </Link>

                   </li>
                   <li className={jourRestant>0 ? "dropdown":"disabledLi dropdown"} title='Corbeille'>
                       <Link to={'/corbeille'}>
                           <i class="fa fa-bitbucket"></i>
                           <span class="badge" style={{ backgroundColor: 'red' }}>{dossiersCorbeille.length}</span>
                       </Link>

                   </li>
                   <li id="header_notification_bar" className={jourRestant>0 ? "dropdown":"disabledLi dropdown"} title='Notifications reçus'>
                       <a data-toggle="dropdown" class="dropdown-toggle" href="index.html#">
                           <i class="fa fa-bell-o"></i>
                           <span class="badge bg-warning">{notifiLimit.length}</span>
                       </a>
                       <ul class="dropdown-menu extended notification">
                           <div class="notify-arrow notify-arrow-yellow"></div>
                           <li>
                               <p class="yellow">Vous avez {notifiLimit.length} notifications</p>
                           </li>
                           {
                               notifiLimit.map((noti) => (
                                   <li >
                                       <Link onClick={() => lireNotification(noti.id_notification)}>
                                           <span class="label label-success"><i class="fa fa-envelop"></i></span>
                                           &nbsp;{noti.name_sender}<br />
                                           <span class="small italic" style={{ marginLeft: 20, marginTop: 10 }}>{noti.objet}</span>
                                       </Link>
                                   </li>
                               ))
                           }




                           <li>
                               <Link to={'/lireNotification'} style={{ color: "black" }}>Voir toutes les notifications</Link>
                           </li>
                       </ul>
                   </li>
               </ul>

           </div>
           <div class="top-menu">
               <div style={{
                   marginTop: 13,
                   position: 'absolute',
                   marginLeft: "30%",
                   display: "flex"
               }}>
                   <h6 style={{ color: 'white' }}>Date début *:</h6>
                   <input type="date" className='form-control'
                       style={{ width: "15%", marginLeft: 5 }}
                       onChange={(e) => setDatedebut(e.target.value)}
                       value={datedebut}
                       disabled={activeBtnSearch === false ? false : true}
                   />
                   <h6 style={{ marginLeft: 5, color: 'white' }}>Date Fin *:</h6>
                   <input type="date"
                       className='form-control'
                       style={{ width: "15%", marginLeft: 5 }}
                       value={datefin}
                       onChange={(e) => setDateFin(e.target.value)}
                       disabled={activeBtnSearch === false ? false : true}
                   />
                   <button type='button' disabled={activeBtnSearch === false ? false : true} onClick={() => bool ? rechercher_dossier() : annuler_research()} style={{ marginLeft: 5 }} className={bool ? 'btn btn-success' : 'btn btn-danger'}>
                       <i style={{ color: 'white' }} className={bool ? 'fa fa-filter' : 'fa fa-remove'}></i>

                   </button>
                   <input type='text' className='form-control '
                       style={{
                           width: 300,
                           marginLeft: 5
                       }}
                       onChange={handleQueryText}
                       disabled={activeBtnSearch === false ? false : true}
                       placeholder='Recherche...'
                   />
                   <button type='button' disabled={activeBtnSearch === false ? false : true} style={{ marginLeft: 5 }} className='btn btn-primary'>
                       <i style={{ color: 'white' }} className='fa fa-search'></i>
                   </button>


                   {/*    <button type='button' style={{ marginLeft: 5 }} disabled={disable} onClick={confirm1} className={(location.pathname == "/corbeille" || location.pathname == "/archives") ? 'btn btn-primary' : 'btn btn-danger'} title='Suppression dossiers'>
                   <i style={{ color: 'white' }} className={(location.pathname == "/corbeille" || location.pathname == "/archives") ? 'fa fa-refresh' : 'fa fa-trash'}></i>
               </button>
               <button type='button' onClick={() => setVisible(true)} disabled={disableAvis} style={{ marginLeft: 5 }} title="Demandez un avis sur les dossiers sélectionnés(Contre-expertise)" className='btn btn-success'>
                   <i style={{ color: 'white' }} className='fa fa-desktop'></i>
               </button>*/}

               </div>
               <button type='button' className="btn btn-danger" onClick={() => LogOut()} style={{ height: 35, marginLeft: 5, float: 'right', marginTop: 12 }}>
                   <BiLogOutCircle style={{ fontSize: 20 }} />
               </button>
           </div>
       </header >

            </div>

        </div>
    );
}

export default Header;