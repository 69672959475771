import React, { useState, useEffect, useContext } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from "primereact/editor";
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import Footer from '../Footer';
import { ThemeContext } from '../../context/ContextHeader';
import { MultiSelect } from 'react-multi-select-component';
import Swal from 'sweetalert2/dist/sweetalert2.js'

function EditionNotification() {

    const { datedebut, dossiersEn, setDossiersEn, dossiers, setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis
    } = useContext(ThemeContext);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = sessionStorage.getItem("membre_id");
    const site_id = sessionStorage.getItem("site_id");
    const { id } = useParams();
    const [objet, setObjet] = useState("");
    const [text, setText] = useState("");
    const [selected, setSelected] = useState([]);
    const [membres, setMembres] = useState([]);
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [nomFichier, setNomFichier] = useState("");
    const [getTextValue,setGetTextValue]=useState("");


    const [statusFichier, setStatusFichier] = useState(false);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setStatusFichier(true);
    };
    const updateNotificat = async () => {
        try {
            if (objet !== "" && text !== "" && selected.length > 0) {
                const data_notifi = {
                    objet: objet,
                    contenu: text.replace(/<[^>]*>/g, ''),
                    user_id_sender: user_id,
                    user_id_reception: selected,
                    file: selectedFile,
                    statut: statusFichier ? 'Exist ' : 'NotExist'
                }
                const res = await axios.post(URL_AXIOS + `update_notifications/${id}`, data_notifi, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for FormData
                    },
                });
                if (res.data.status === 200) {

                    navigate('/notifications')
                } else {
                    Swal.fire({
                        title: "Erreur !",
                        text: "La modification de la notification a échoué !",
                        icon: "error"
                    })
                }

            } else {
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez renseigner tous les champs obligatoires !",
                    icon: "error"
                })
            }
        } catch (error) {

        }

    }

    // const liste_membres_by_id = async (membreid) => {

    //     try {

    //         await axios.get(URL_AXIOS + `get_membre_by_id_second/${membreid}`)
    //             .then(res => {
    //                 const mappedMembr_data = res.data.members.map((item) => ({
    //                     label: item.name_recep,
    //                     value: item.id_user_recep
    //                 }));
    //                 setSelected(mappedMembr_data);
    //             });
    //     } catch (Error) {
    //         console.log('Error ' + Error)
    //     }

    // }

    const liste_membres_by_id = async (membreid) => {
        try {
            const response = await axios.get(`${URL_AXIOS}get_membre_by_id_second/${membreid}`);
            if (response.data.members) {
                const mappedMembr_data = response.data.members.map((item) => ({
                    label: item.name_recep,
                    value: item.id_user_recep
                }));
                console.log('Mapped Selected Members:', response.data.members); // Debugging line
                setSelected(mappedMembr_data);
            } else {
                setSelected([]);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `get_notifications_by_id/${id}`);
            const data = response.data.notifications;
            if (response.data.status === 200) {
                const folderURL = response.data.folderUrl + '/' + data.fichier_notification;
                setText(data.contenu);
                setObjet(data.objet);
                setSelectedFile(folderURL);
                setNomFichier(data.fichier_notification)

            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const handleMultiSelectChange = (values) => {
        setSelected(values);
    };

    const fetchMembres = async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_user_site_centre/${id_site}`);
            if (response.data.status === 200) {
                const mappedMembres = response.data.users_sites.map((item) => ({
                    label: item.name,
                    value: item.id
                }));
                console.log('Mapped Membres:', response.data.users_sites);
                setMembres(mappedMembres);


                //alert("Data : "+response.data.users_sites)
            }
        } catch (error) {
            //alert("Data : "+error)
            console.error("Error fetching membres:", error);
        }
    };

    const handleDownload = (e, val) => {
        e.preventDefault();
        //const getURL = getURLFolder;
        const downloadLink = document.createElement('a');
        downloadLink.href = val;
        downloadLink.download = val;
        downloadLink.target = "_blank";
        downloadLink.click();
    };

    const handleTextEditor = (e) => {
      const valuehtml=e.htmlValue;
      const valuepure=e.target.value;
      setText(valuehtml);
      setGetTextValue(valuepure)
    }
    useEffect(() => {
        fetchData();
        if (site_id) {
            fetchMembres(site_id);
        }
        if (id) {
            liste_membres_by_id(id);
        }
        // fetchMembres(parseInt(site_id));
        // liste_membres_by_id(id)
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }

    }, [id, site_id]);

    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section class="wrapper">
                    <div className='row '>
                        <div className="form-panel">
                            <form role="form" className="form-horizontal style-form">
                                <div className="form-group" style={{ padding: 10 }}>
                                    <div className='col col-md-4'>
                                        <input
                                            type='text'
                                            value={objet}
                                            onChange={(e) => setObjet(e.target.value)}
                                            className='form-control'
                                            placeholder='Objet *'
                                        />
                                    </div>
                                    <div className='col col-md-4'>
                                        <MultiSelect
                                            options={membres}
                                            value={selected}

                                            onChange={handleMultiSelectChange}
                                            optionLabel='label'
                                            optionValue='value'
                                            labelledBy="Sélectionner les utilisateurs "
                                            className="custom-multi-select"

                                        />

                                    </div>
                                    <div className='col col-lg-3 col-md-3'>
                                        <div className="">
                                            <input type="file" accept="application/pdf" onChange={handleFileChange} className='form-control' />
                                        </div>
                                    </div>
                                    <div className="col col-lg-1 col-md-1">

                                        {nomFichier && <i className='fa fa-paperclip'
                                            onClick={(e) => handleDownload(e, selectedFile)}
                                            style={{ cursor: 'pointer', fontSize: "30px" }}
                                        ></i>}
                                    </div>
                                </div>
                                <div className='form-group' style={{ padding: 10 }}>
                                    <div className='col col-lg-12'>
                                        <div className="">
                                            <Editor
                                                value={text}
                                                onTextChange={(e) => setText(e.htmlValue)}
                                                style={{ height: '500px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col col-lg-12">
                                        <button type='button' onClick={updateNotificat} className='btn btn-warning'>Mettre à jour</button>
                                        <button type='button' onClick={() => navigate('/notifications')} className='btn btn-danger ' style={{ marginLeft: 10 }}>Annuler</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />
        </div>
    );
}

export default EditionNotification;