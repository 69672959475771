import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';

function Entete(props) {
    return (
        <div>
            <Header />
            <AsideBar />
            <section id="main-content">
                <section class="wrapper ">
                    <div className='row'>

                    </div>
                </section>
            </section>
        </div>
    );
}

export default Entete;