import React from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { useState } from 'react';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function EditionContrat(props) {
    const navigator = useNavigate();
    const { id } = useParams();
    const [membres, setMembres] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileTwo, setSelectedFileTwo] = useState(null);
    const [site_id, setSite_id] = useState(0);
    const [membre_id, setMembre_id] = useState(0);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const member_id = sessionStorage.getItem("membre_id");
    const navigate = useNavigate();
    const handleFileChangeOne = (e) => {
        const file = e.target.files[0];
        if (file !== "") {
            setSelectedFile(file);
        }

    };
    const handleFileChangeTwo = (e) => {
        const file = e.target.files[0];
        setSelectedFileTwo(file);
    };

    const addContrat = async () => {
        try {
            alert("alert")
            const formData = new FormData();
            formData.append('site_id', site_id);
            formData.append('membre_id', membre_id);
            formData.append('id_user', user_id);
            formData.append('file_one', selectedFile);
            formData.append('file_two', selectedFileTwo);

            const res = await axios.post(URL_AXIOS + "addMembresSites", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (res.data.status === 200) {
                alert("Saved");
            }
        } catch (error) {
            alert("Error" + error)
        }
    }
    const liste_membres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'membres');
            if (response.data.status === 200) {
                setMembres(response.data.membres);

            }
        } catch (Error) {

        }
    }
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const liste_site_contrat = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `get_sites_membres_by_id/${id}`);
            setMembre_id(response.data.membre_id);
            setSite_id(response.data.site_id);
            setSelectedFile(response.data.chemin_fichier_one_name);
            setSelectedFileTwo(response.data.chemin_fichier_two_name);
            alert("" + response.data.chemin_fichier_two_name);

        } catch (Error) {
            alert("Thanks 012");
        }

    }
    useEffect(() => {
        liste_membres();
        liste_site();
        liste_site_contrat();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected}/>
            <section id="main-content">
                <section class="wrapper ">
                    <div className='container-fluid card mt'>
                        <div className='row mt'>
                            <div className='col col-md-6'>
                                <select className='form-control' value={membre_id} onChange={(e) => setMembre_id(e.target.value)}>
                                    <option>Veuillez sélectionner le membre</option>
                                    {membres.map((m) => (
                                        <option value={m.id}>{m.nom}</option>
                                    ))}

                                </select>
                            </div>
                            <div className='col col-md-6'>
                                <select className='form-control' value={site_id} onChange={(e) => setSite_id(e.target.value)}>
                                    <option>Veuillez sélectionner le site</option>
                                    {sites.map((s) => (
                                        <option value={s.id}>{s.societe}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='row mt'>
                            <div className='col col-md-6'>
                                <input type='file' className='form-control' onChange={handleFileChangeOne} />
                            </div>
                            <div className='col col-md-6'>
                                <input type='file' className='form-control' onChange={handleFileChangeTwo} />
                            </div>
                        </div>
                        <div className='row mt'>
                            <div className='col col-md-12'>
                                <button type='button' className='btn btn-success'
                                    onClick={() => addContrat()}
                                >Ajouter</button>
                                <button className='btn btn-danger' onClick={() => navigator('/contrats')} style={{ marginLeft: 10 }}>Annuler</button>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default EditionContrat;