import React from 'react'

function WhatssapSmsOne(props) {
    return (
        <div style={{ display: "flex", flexDirection: "column",marginLeft:10 }}>
            <h5>[E-METECH]</h5>
            <p style={{fontWeight:"bold"}}> Bonjour Docteur, les examens de {props.societe} sont disponibles sur la plateforme.</p>
        </div>
    )
}

export default WhatssapSmsOne
