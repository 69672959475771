import React from 'react';

function Reabonnement(props) {
    return (
        <div class="flex flex-column" style={{ height: "100vh", backgroundImage: "url(./../../img/login-bg2.jpg)", backgroundSize: 'cover', backgroundPosition: 'center' }}>

            <div class="align-items-center justify-content-center  font-bold ">
                <h1 className='text-center' style={{ color: "white" }}>RÉABONNEMENT</h1>
                <p className='text-center' style={{ color: "white" }}>Pour vous réabonner,veuillez nous contacter</p>
            </div>

            <div class="flex align-items-center justify-content-center  font-bold ">

                <div class="flex flex-column md:flex-row">
                    <div class="flex align-items-center justify-content-center w-20rem h-5rem  font-bold  border-round m-2" style={{ backgroundColor: '#004880' }}>
                        <i className='fa fa-phone'
                            style={{
                                color: 'red',
                                fontSize: '35px',
                                textAlign: 'center',
                                marginTop: 2,
                                width: '50px',
                                height: '50px',
                                backgroundColor: '#fff',
                                padding: 10,
                                borderRadius: '100%'

                            }}></i>
                        <span style={{ fontSize: 15, marginLeft: '10px', color: '#fff', marginTop: 2 }}>+242 06 901 73 06.</span>

                    </div>
                    <div class="flex align-items-center justify-content-center w-20rem h-5rem font-bold border-round m-2" style={{ backgroundColor: '#004880' }}>
                        <i className='fa fa-envelope-o'
                            style={{
                                color: 'red',
                                fontSize: '32px',
                                textAlign: 'center',
                                marginTop: 2,
                                width: '50px',
                                height: '50px',
                                backgroundColor: '#fff',
                                padding: 10,
                                borderRadius: '100%',


                            }}></i>
                        <span style={{ fontSize: 15, marginLeft: '10px', color: '#fff', marginTop: 2 }}>support@e-metech.com</span>
                    </div>
                    <div class="flex align-items-center justify-content-center w-20rem h-5rem  font-bold border-round m-2" style={{ backgroundColor: '#004880' }}>
                        <i className='fa fa-whatsapp'
                            style={{
                                color: 'red',
                                fontSize: '35px',
                                textAlign: 'center',
                                marginTop: 2,
                                width: '50px',
                                height: '50px',
                                backgroundColor: '#fff',
                                padding: 10,
                                borderRadius: '100%',


                            }}></i>
                        <span style={{ fontSize: 15, marginLeft: '10px', color: '#fff', marginTop: 2 }}>+242 05 610 48 78</span>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Reabonnement;