import React, { useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import { Calendar } from 'antd';
import { useContext } from 'react';
import Footer from '../Footer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { URL_AXIOS } from '../../URL/URL';
import Swal from 'sweetalert2/dist/sweetalert2';
import Loading from './Loading';
function Agenda() {
    const navigate = useNavigate();
    const [agenda, setAgenda] = useState([]);
    const [sites, setSites] = useState([]);
    const [visible, setVisible] = useState(false);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = parseInt(sessionStorage.getItem("user_id"));
    const site_id = parseInt(sessionStorage.getItem("site_id"));
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedMembre, setSelectedMembre] = useState(null);
    const [siteMembre, setSiteMembre] = useState([]);
    const [siteMembreGet, setSiteMembreGet] = useState([]);
    const [datedebut, setDatedebut] = useState('');
    const [datefin, setDateFin] = useState('');
    const [boolStatus, setBoolStatus] = useState(false);
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(true);

    // const liste_site = async () => {
    //     try {
    //         const response = await axios.get(URL_AXIOS + 'sites');
    //         if (response.data.status === 200) {
    //             setSites(response.data.sites);
    //             //setLogin(response.data.sites.length)
    //             //setFilteredData(response.data.sites);
    //         }
    //     } catch (Error) {

    //     }

    // }

    const liste_agenda = async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_site_membre_agendaBySite/${id_site}`);
            if (response.data.status === 200) {
                setAgenda(response.data.list_site_membre_agenda);
                //setLogin(response.data.sites.length)
                //setFilteredData(response.data.sites);
                setLoading(false)
            }
        } catch (Error) {

        }

    }
    // const lister_contrat = async () => {
    //     try {

    //         const response = await axios.get(URL_AXIOS + 'liste_membres_sites');
    //         if (response.data.status === 200) {
    //             setSiteMembre(response.data.data_membres_sites);

    //         }
    //     } catch (Error) {

    //     }
    // };

    // const AddAgendas = async () => {
    //     try {

    //         if (datedebut < datefin) {
    //             const data = {
    //                 datedebut: datedebut,
    //                 datefin: datefin,
    //                 id_contrat: selectedMembre,
    //                 user_id: user_id
    //             }
    //             const response = await axios.post(URL_AXIOS + 'addAgenda', data);
    //             if (response.data.status === 200) {
    //                 liste_agenda();
    //                 setVisible(false);
    //             }
    //         } else {

    //             Swal.fire("Date de début doit strictement être inférieur à la date de fin!", "", "danger");
    //         }

    //     } catch (Error) {

    //     }

    // }

    // const UpdateAgendas = async (id_agenda) => {
    //     try {
    //         const data = {
    //             datedebut: datedebut,
    //             datefin: datefin,
    //             id_contrat: selectedMembre,
    //             user_id: user_id
    //         }
    //         const response = await axios.post(URL_AXIOS + `UpdateAgenda/${id_agenda}`, data);
    //         if (response.data.status === 200) {
    //             liste_agenda();
    //             setVisible(false);
    //         }
    //     } catch (Error) {

    //     }

    // }

    // const DeleteAgendas = async (id_agenda) => {
    //     try {

    //         const response = await axios.post(URL_AXIOS + `DeleteAgenda/${id_agenda}`);
    //         if (response.data.status === 200) {
    //             liste_agenda();
    //         }
    //     } catch (Error) {

    //     }

    // }
    // const onSelectMembre = async () => {

    //     try {

    //         const filteredData = siteMembre.filter(item => {
    //             return (item.societe === selectedSite)
    //         });
    //         setSiteMembreGet(filteredData);



    //     } catch (Error) {

    //     }

    // }
    // const AddAgenda = async() => {
    //     try {
    //         const response = await axios.get(URL_AXIOS + 'getLastDate');
    //         if (response.data.status === 200) {
    //            setDatedebut(response.data.list_site_membre_agenda)
    //            alert(response.data.list_site_membre_agenda)
    //         }

    //         setBoolStatus(false);
    //         setVisible(true);
    //         setSelectedSite(null);
    //         setSelectedMembre(null);
            
    //         setDateFin("");
    //     } catch (error) {
            
    //     }
      
    // }
    // const EditAgenda = async (data) => {
    //     try {

    //         setBoolStatus(true);
    //         setVisible(true);
    //         setDatedebut(data.datedebut);
    //         setDateFin(data.datefin);
    //         setSelectedSite(data.societe);
    //         setSelectedMembre(data.id_contrat);
    //         setId(data.id);

    //     } catch (error) {

    //     }

    // }

    // const confirm_delete = (id) => {

    //     Swal.fire({
    //         title: "Voulez-vous supprimer cette information ?",
    //         showCancelButton: true,
    //         confirmButtonText: "OUI",
    //         cancelButtonText: "NON",
    //         icon: 'question'
    //     }).then((result) => {
    //         if (result.isConfirmed) {

    //             DeleteAgendas(id);
    //             liste_agenda();
    //             Swal.fire("Réussi!", "", "success");
    //         } else if (result.isCanceled) {
    //             Swal.fire("Echec!", "", "danger");
    //         }
    //     });

    // };
    // const confirm_update = (id) => {

    //     Swal.fire({
    //         title: "Voulez-vous modifier cette information ?",
    //         showCancelButton: true,
    //         confirmButtonText: "OUI",
    //         cancelButtonText: "NON",
    //         icon: 'question'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             UpdateAgendas(id);
    //             liste_agenda();

    //             Swal.fire("Réussi!", "", "success");
    //         } else if (result.isCanceled) {
    //             Swal.fire("Echec!", "", "danger");
    //         }
    //     });

    // };
    // const confirm_add = () => {
    //     setVisible(false);
    //     Swal.fire({
    //         title: "Voulez-vous ajouter cette information ?",
    //         showCancelButton: true,
    //         confirmButtonText: "OUI",
    //         cancelButtonText: "NON",
    //         icon: 'question'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             if (datedebut < datefin) {
    //                 AddAgendas();
    //                 liste_agenda();

    //                 Swal.fire("Réussi!", "", "success");
    //             } else {
    //                 Swal.fire("Date de début doit strictement être inférieur à la date de fin!", "", "danger");
    //                 setTimeout(() => {
    //                     setVisible(true)
    //                 }, 1000); 
                  
                  
    //             }

    //         } else if (result.isCanceled) {
    //             setVisible(true)
    //         }
    //     });

    // };
    // const bodyAction = (data) => {
    //     return (
    //         <div style={{ display: 'flex', }}>
    //             <button type='button' onClick={() => EditAgenda(data)} className='btn btn-warning'>
    //                 <i className='fa fa-edit'></i>
    //             </button>
    //             <button type='button' onClick={() => confirm_delete(data.id)} style={{ marginLeft: 10 }} className='btn btn-danger'>
    //                 <i className='fa fa-trash-o'></i>
    //             </button>
    //         </div>
    //     )
    // }
    const bodyActionStatus = (data) => {
        return (
            <div style={{ display: 'flex', }}>
               {data.statutag==0 ? "En cours ":data.statutag==1 ? "En attente":"Expiré"}
            </div>
        )
    }
    useEffect(() => {

        // liste_site();
        liste_agenda(site_id);
        // lister_contrat();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [site_id])
    return (
        <section id="container">
           
            <Header />
            <AsideBar username={usernameconnected} />
       {loading ? <Loading /> :     <section id="main-content">
                <section class="wrapper">
                    <div className='container-fluid'>
                        <div className="row mt">
                            <div className='col col-md-12'>
                                {/* <button type='button' className='btn btn-info'
                                    onClick={AddAgenda}
                                >Ajouter</button> */}
                            </div>
                        </div>
                        <div className='row'>
                            <DataTable value={agenda} paginator rows={10} emptyMessage="Aucun agenda disponible!" rowClassName={(data) => data.statutag == 0 ? "bgColorRowAgenda" : data.statutag == 1 ? "bgColorRowAbonnProgramm" : "bgColorRowAbonnExp"} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} className='custom-datatable'>

                                <Column header={<div style={{ marginLeft: 25 }}>#</div>} field='id' headerStyle={{ marginLeft: 100 }} bodyStyle={{ textAlign: 'center' }} style={{ width: '5%', height: '40px' }}></Column>
                                <Column field='societe' header="CENTRE" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field={'nom'} header="NOM" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field={'prenom'} header="PRENOM" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field='datedebut' header="DATE DEBUT" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field='datefin' header="DATE FIN" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px' }}></Column>
                                <Column  header="STATUS" body={bodyActionStatus} style={{ width: 'auto', height: '40px' }}></Column>
                                {/* <Column header="ACTIONS" body={bodyAction} style={{ width: 'auto', height: '40px' }}></Column> */}
                            </DataTable>
                        </div>
                    </div>

                </section>
            </section>}
            <Footer />
        </section>
    );
}

export default Agenda;