import axios from 'axios';
import { Divider } from 'primereact/divider'
import React, { useEffect, useState } from 'react'
import { URL_AXIOS } from '../../URL/URL';
import { Dropdown } from 'primereact/dropdown';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import Swal from 'sweetalert2/dist/sweetalert2';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Navigate, useNavigate } from 'react-router-dom';

function UserLoginCreate() {
    const navigate = useNavigate();
    const [sites, setSites] = useState([]);
    const [site_id, setSite_id] = useState(0);
    const [role, setRole] = useState('');
    const [email, setEmail] = useState("");
    const [nomcomplet, setNomComplet] = useState("");
    const [motpasse, setMotpasse] = useState("");
    const [confirmation, setConfirmation] = useState("");
    const [typePwd, setTypePwd] = useState("password");
    const [iconValue, setIconValue] = useState("pi pi-eye");
    const [typePwdc, setTypePwdc] = useState("password");
    const [iconValuec, setIconValuec] = useState("pi pi-eye");
    const [ingredients, setIngredients] = useState([]);
    const [boolC, setBoolC] = useState(true);
    const [libelleRole,setLibelleRole]=useState("");

    const [smgPaste, setSmgPaste] = useState("");
    const [messageNomComplet, setMessageNomComplet] = useState("");
    const [messageSociete, setMessageSociete] = useState("");
    const [messageRole, setMessageRole] = useState("");
    const [messageMdp, setMessageMdp] = useState('');
    const [messageEmail, setMessageEmail] = useState("");
    const [messageConf, setMessageConf] = useState('');

    const handleNomComplet = (e) => {
        const value = e.target.value;
        if (value) {
            setNomComplet(value);
            setMessageNomComplet("");
        } else {
            setNomComplet("");
            setMessageNomComplet("Veuillez renseigner le nom complet.");
        }
    }
    const handleSociete = (e) => {
        const value = e.value;
        if (value) {
            setSite_id(value);
            setMessageSociete("");
        } else {
            setSite_id("");
            setMessageSociete("Veuillez renseigner la société.");
        }
    }
    const handleEmail = (e) => {
        const value = e.target.value;
        if (value) {
            setEmail(value);
            setMessageEmail("");
        } else {
            setEmail("");
            setMessageEmail("Veuillez renseigner l'email.");
        }
    }

    const handlePassword = (e) => {
        const value = e.target.value;
        if (value) {
            setMotpasse(value);
            setMessageMdp("");
        } else {
            setMotpasse("");
            setMessageMdp("Veuillez renseigner le mot de passe.");
        }
    }

    const handlePasswordConf = (e) => {
        const value = e.target.value;
        if (value) {
            setConfirmation(value);
            setMessageConf("");
        } else {
            setConfirmation("");
            setMessageMdp("Veuillez renseigner le mot de passe de confirmation.");
        }
    }


    const handleRole = (e) => {
        const value = e.value;

        if (value) {
            setRole(value);
            setMessageRole("");
        } else {
            setRole("");
            setMessageRole("Veuillez renseigner le rôle.");
        }
    }
    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked) {
            _ingredients.push(e.value);
            setBoolC(false);
        }
        else {
            setBoolC(true);
            _ingredients.splice(_ingredients.indexOf(e.value), 1);
        }


        setIngredients(_ingredients);
    }
    const seePassword = () => {
        if (typePwd === "password") {
            setTypePwd("text");
            setIconValue("pi pi-eye-slash")
        } else {
            setTypePwd("password");
            setIconValue("pi pi-eye")
        }

    }

    const seePasswordConfirme = () => {
        if (typePwdc === "password") {
            setTypePwdc("text");
            setIconValuec("pi pi-eye-slash")
        } else {
            setTypePwdc("password");
            setIconValuec("pi pi-eye")
        }

    }
    const onPaste = (e) => {
        e.preventDefault();
        setConfirmation('');
        setSmgPaste("Vous ne pouvez pas coller dans ce champ,Veuillez saisir")
    }
    const handleConfirme = (e) => {
        const value = e.target.value;
        if (value !== "") {
            setConfirmation(value);
            setSmgPaste('')
        } else {
            setConfirmation("");
            setMessageMdp("Veuillez renseigner le mot de passe de confirmation.");
        }
    }

    const Roles = [
        // { name: 'Administrateur', code: 'A' },
        // { name: 'Médecin', code: 'M' },
        { name: 'Agent du centre', code: 'C' },
    ];
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'centres');
            if (response.data.status === 200) {
                setSites(response.data.sites);
            }
        } catch (Error) {

        }

    }
    const validationFields = () => {
        if (nomcomplet !== "") {
            setMessageNomComplet("");
        } else {
            setMessageNomComplet("Veuillez renseigner le nom complet.");
        }

        if (site_id !== 0) {
            setMessageSociete("");
        } else {
            setMessageSociete("Veuillez renseigner la société.");
        }
     

        if (email !== "") {

            const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
            if (regex.test(email)) {
                setMessageEmail("");
            } else {
                setMessageEmail("le mail n'est pas valide");
            }
        } else {
            setMessageEmail("Veuillez renseigner l'email.");
        }

        if (motpasse !== "") {
            setMessageMdp("");
        } else {
            setMessageMdp("Veuillez renseigner le mot de passe.");
        }

        if (confirmation !== "") {
            setMessageConf("");
        } else {
            setMessageConf("Veuillez renseigner le mot de passe de confirmation.");
        }
    }
    const clearFields = () => {
        setNomComplet('');
        setSite_id("");
        setRole("");
        setEmail('');
        setMotpasse('');
        setConfirmation("");
    }
    const addUser = async () => {
        try {
            validationFields();
            const data_user = {
                name: nomcomplet,
                email: email,
                password: motpasse,
                role: Roles[0].code,
                site_id: site_id
            }
            if (nomcomplet !== "" && site_id !== ""  && email !== "" && motpasse !== "" && confirmation !== "") {
                if (confirmation === motpasse) {
                    const response = await axios.post(URL_AXIOS + "addUser", data_user);
                    if (response.data.status === 200) {
                        Swal.fire({
                            title: "Réussi !",
                            text: "Le compte a été crée avec succès !",
                            icon: 'success'
                        });
                        clearFields();
                        navigate("/")
                    } else {
                        Swal.fire({
                            title: "Erreur !",
                            text: "Le compte n'a pas été crée !",
                            icon: 'error'
                        });
                    }
                } else {
                    setConfirmation("");
                    setMessageConf("Les deux mots de passe ne correspondent pas!")
                }
            } else {

            }
        } catch (error) {

        }

    }
    useEffect(() => {
        liste_site();
    })
    return (
        <div className='' style={{ backgroundImage: 'url(../../../img/body-bg.png)', backgroundSize: 'cover',backgroundRepeat:"no-repeat", minHeight:"100vh", width:"100%", position: 'relative',padding:0,margin:0 }}>
            <div className='row' style={{padding:0,margin:0,paddingBottom:20 }}>
                <div className='col col-md-3'>

                </div>
                <div className='col col-md-6 card' style={{ padding: 25, height: "auto", borderRadius: "20px",top:20 }}>
                    <h3>COMPTE <span style={{ color: "#65b6f4" }}>TERATEX</span></h3>
                    <Divider />
                    <div className='row' style={{ marginTop: 20 }}>
                        <input type='text' required value={nomcomplet} onChange={(e) => handleNomComplet(e)} className='form-control' placeholder='Noms Complets *' style={{}} />
                        {messageNomComplet && <label className="ml-2" style={{ color: "red" }}>{messageNomComplet}</label>}
                    </div>
                    <div className='row' style={{ marginTop: 20 }}>
                        <Dropdown
                            value={site_id}
                            name='examen_two'
                            onChange={(e) => handleSociete(e)}
                            options={sites}
                            optionLabel="societe"
                            optionValue='id'
                            placeholder="Société *"
                            filter
                            className="w-full "
                            emptyMessage="Aucune société trouvée"


                        />
                        {messageSociete && <label className="ml-2" style={{ color: "red" }}>{messageSociete}</label>}
                    </div>
                    <div className='row' style={{ marginTop: 20 }}>
                        <input type='text' className='form-control' value={Roles[0].name} style={{pointerEvents:"none"}}/>
                        {/* <Dropdown
                            value={role}
                            name='role'
                            onChange={(e) => handleRole(e)}
                            options={Roles}
                            optionLabel="name"
                            optionValue='code'
                            placeholder="Rôle *"
                            className="w-full "
                            emptyMessage="Aucun rôle"


                        />
                        {messageRole && <label className="ml-2" style={{ color: "red" }}>{messageRole}</label>}
                    */}
                    </div>

                    <div className='row' style={{ marginTop: 20 }}>
                        <input type='email' value={email} onChange={(e) => handleEmail(e)} placeholder='Email *' className='form-control' />
                        {messageEmail && <label className="ml-2" style={{ color: "red" }}>{messageEmail}</label>}
                    </div>
                    <div className='row' style={{ marginTop: 20 }}>
                        <IconField iconPosition="right">
                            <InputIcon className={iconValue} onClick={() => seePassword()}> </InputIcon>
                            <InputText v-model="value1"
                                type={typePwd}
                                value={motpasse}
                                onChange={(e) => handlePassword(e)}
                                placeholder="Mot de passe *" className='form-control' />
                        </IconField>
                        {/* {messageMdp && <label className="ml-2" style={{ color: "red" }}>{messageMdp}</label>} */}
                        {(motpasse && !confirmation) && <PasswordStrengthBar password={motpasse} shortScoreWord={"très court"} scoreWords={["faible", "faible", "moyen", "bon", "fort"]} style={{ marginTop: 10 }} />}
                        {messageMdp && <label className="ml-2" style={{ color: "red" }}>{messageMdp}</label>}

                   
                    </div>
                    <div className='row' style={{ marginTop: 20 }}>
                        <IconField iconPosition="right">
                            <InputIcon className={iconValuec} onClick={() => seePasswordConfirme()}> </InputIcon>
                            <InputText v-model="value1"
                                type={typePwdc}
                                value={confirmation}
                                onChange={(e) => handleConfirme(e)}
                                placeholder="Confirmation *" className='form-control'
                                onPaste={(e) => onPaste(e)}
                            />
                        </IconField>
                        {/* {messageConf && <label className="ml-2" style={{ color: "red" }}>{messageConf}</label>} */}
                        {confirmation && <PasswordStrengthBar password={confirmation}  shortScoreWord={"très court"} scoreWords={["faible", "faible", "moyen", "bon", "fort"]} style={{ marginTop: 10 }} />}
                        {messageConf && <label className="ml-2" style={{ color: "red" }}>{messageConf}</label>}

                    </div>
                    <div className='row' style={{ marginTop: 20 }}>
                        <div className="flex align-items-center justify-content-center">
                            <Checkbox inputId="ingredient1" name="pizza" value="1" onChange={onIngredientsChange} checked={ingredients.includes('1')} />
                            <label htmlFor="ingredient1" className="ml-2">J'accepte les <span style={{ color: "#65b6f4", fontWeight: 600 }}>Termes et Conditions</span> d'utilisation de cette plateforme.</label>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: 20 }}>
                        <div className="col col-md-6">
                            <button type='button' onClick={() => addUser()} style={{ pointerEvents: boolC ? 'none' : 'auto', opacity: boolC ? "0.5" : "0.9" }} className='btn btn-info form-control'>Créer mon compte</button>
                        </div>
                        <div className="col col-md-6">
                            {/* <button type='button' onClick={() => navigate('/')} className='btn btn-default form-control' style={{ backgroundColor: "#282628", color: "white" }}>Connexion</button> */}
                            <a className='btn btn-danger form-control' style={{ color: "white" }} href="https://e-metech.com/"><i className='fa fa-home'></i> Accueil</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserLoginCreate
